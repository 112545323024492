import React from "react";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useTournamentsShow } from "../apiHooks";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { Spin } from "antd";
import { TournamentView } from "../components/TournamentView";

export function TournamentShow() {
  const { id } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useTournamentsShow({
    language: locale,
    id: id,
  });

  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <section className="tournaments-list">
        <Spin spinning={isLoading}>
          <h2>{data?.tournament.title}</h2>
          <br />
          {data && (
            <div key={data.tournament.id}>
              <TournamentView
                tournament={data.tournament}
                prizes={data.prizes}
                results={data.results}
              />
            </div>
          )}
        </Spin>
      </section>
    </LandingTemplate>
  );
}
