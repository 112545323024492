import React from "react";
import { Row, Col } from "antd";
import { ReactComponent as IconUserLogo } from "../../../images/icons/sidebar/user-logo.svg";
import InfoItem from "../../Common/components/InfoItem";
import { _t } from "../../Common/components/InjectIntlContext";

type UserDataProps = {
  login: string;
  email: string;
  first_name: string;
  last_name: string;
};

const UserData: React.FC<UserDataProps> = ({
  login,
  email,
  first_name,
  last_name,
}) => {
  return (
    <Row gutter={24} align="middle" justify="start">
      <Col span={4}>
        <IconUserLogo style={{ height: "117px", width: "133px" }} />
      </Col>
      <Col span={20}>
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <InfoItem value={login} label={_t("Login")} />
            <InfoItem value={email} label={_t("Email")} />
          </Col>
          <Col span={10}>
            <InfoItem value={first_name} label={_t("first_name")} />
            <InfoItem value={last_name} label={_t("last_name")} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserData;
