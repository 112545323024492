import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import icon from "../../../../assets/images/outer/commission_3.svg";

const SubAffiliate = () => {
  const points = (_t: any) => [
    _t("subaffiliate_1"),
    // _t("subaffiliate_2"),
    // _t("subaffiliate_3"),
    // _t("subaffiliate_4"),
  ];
  const translatedPoints = points(_t);

  const title = _t("subaffiliate_title");
  return (
    <CommissionPage
      title={title}
      icon={icon}
      translatedPoints={translatedPoints}
    />
  );
};

export default SubAffiliate;
