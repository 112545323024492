import { dateKey } from "./constants";

export const actionTypes = {
  UPDATE_FILTERS: "costs/update-filters",
};

export const updateCostsFilters = (filters) => {
  if (filters.date) {
    localStorage.setItem(dateKey, JSON.stringify(filters.date)); // todo
  }

  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      ...filters,
    },
  };
};
