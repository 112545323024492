import React from "react";
import Form from "antd/lib/form";
import { _t } from "../../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Button from "../../../Common/components/Button";

type OrderFormProps = {
  isLoading: boolean;
  onFinish: any;
};

const OrderForm: React.FC<OrderFormProps> = ({ isLoading, onFinish }) => {
  return (
    <Form name="order_form" onFinish={onFinish} layout="vertical">
      <Form.Item label={_t("contacts")} name="contacts">
        <Input placeholder={_t("contacts")} />
      </Form.Item>
      <Form.Item
        label={_t("details")}
        name="details"
        rules={[
          {
            required: true,
            message: _t("Please input details"),
          },
        ]}
      >
        <Input.TextArea rows={4} placeholder={_t("details")} />
      </Form.Item>

      <Form.Item className="text-center">
        <Button
          type="primary"
          title={_t("Apply")}
          htmlType="submit"
          disabled={isLoading}
        />
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
