import React from "react";

const BenefitCardItem = ({ info }) => {
  const { icon, title } = info;
  return (
    <div className="benefit-card">
      <div className="benefit-card__icon">
        <img src={icon} alt="" />
      </div>
      <p className="benefit-card__title">{title}</p>
    </div>
  );
};

export default BenefitCardItem;
