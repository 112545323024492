import React, { useEffect, useState } from "react";
import { Switch, Popover, Typography } from "antd";
import { _t } from "./InjectIntlContext";

const fromInternalValue = (val) => {
  return Object.keys(val).filter((key) => val[key]);
};
const toInternalValue = (value: any[]) => {
  if (!value) return {};

  return value.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
};

const Option = ({ value, label, checked, onChange }) => (
  <div key={value} className="checkbox-select-option">
    <Switch
      title={label}
      checked={checked}
      onChange={(checked) => onChange(value, checked)}
    />
    <Typography.Text className="checkbox-select-option-label">
      {label}
    </Typography.Text>
  </div>
);

interface CheckBoxSelectProps {
  options: Array<{ label: string; value: string }>;
  value?: string[];
  showToggleAll?: boolean;
  onChange?: (value: string[]) => any;
  onDropdownVisibleChange?: (open: boolean) => any;
  children: React.ReactElement;
}

export function CheckBoxSelect({
  options,
  value,
  onChange,
  children,
  onDropdownVisibleChange,
  showToggleAll = true,
}: CheckBoxSelectProps) {
  const [state, setState] = useState(toInternalValue(value));
  const _onChange = (name, checked) => {
    setState((s) => ({ ...s, [name]: checked }));
  };
  const isChecked = (name) => state[name];
  const isAllChecked = () => options.some((o) => state[o.value]);
  // todo
  const toggleAll = () =>
    setState(
      isAllChecked() ? {} : toInternalValue(options.map((o) => o.value))
    );
  const content = (
    <div>
      {showToggleAll && (
        <Option
          key={"all"}
          label={_t("show_hide_all")}
          value={""}
          checked={isAllChecked()}
          onChange={toggleAll}
        />
      )}
      {options.map((option) => (
        <Option
          key={option.value}
          label={option.label}
          value={option.value}
          checked={isChecked(option.value)}
          onChange={_onChange}
        />
      ))}
    </div>
  );

  useEffect(() => {
    setState(toInternalValue(value));
  }, [value]);

  return (
    <div className="checkbox-select">
      <Popover
        overlayClassName="checkbox-select-dropdown"
        content={content}
        trigger="click"
        onVisibleChange={(visible) => {
          if (!visible) {
            onChange?.(fromInternalValue(state));
          }
          onDropdownVisibleChange?.(visible);
        }}
      >
        {children}
      </Popover>
    </div>
  );
}
