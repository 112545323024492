import React from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row } from "antd";
import { DatePicker } from "../../../../../../../modules/Common/components/DatePicker";
import {
  DateRangePicker,
  ProgramsSelect,
  ProgramTypesSelect,
} from "../../../../../../../common/components";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import {
  createOptions,
  setFormInputValue,
} from "../../../../../../../common/helpers";
import FormItem from "antd/es/form/FormItem";
import { Select } from "antd/es";
import { useGetCountriesList } from "../../api";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const [form] = useForm();
  const { data, isLoading } = useGetCountriesList();

  const countriesList = data?.list
    .map((item: any) => ({
      value: item.iso2,
      label: item.name || item.iso2,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  return (
    <Filters
      form={form}
      onApply={setFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={10}>
            <ProgramsSelect />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <ProgramTypesSelect />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <FormItem name="country" label={`${_t("country")}`}>
              <Select
                mode="multiple"
                placeholder={_t("All")}
                options={countriesList}
                allowClear
                filterOption={(input, option: any) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} md={4} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
