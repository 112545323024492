import React, { useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import {
  useGetTranslationExportExcel,
  useGetTranslationList,
} from "../../hooks";
import { Button, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import TranslationItem from "../../components/TranslationItem";
import { FiltersWidget } from "../../components/FiltersWidget";
import { usePostMergeTranslations } from "../../hooks/usePostMergeTranslation";
import Btn from "../../../../../../modules/Common/components/Button";
import { ExcelButton } from "../../../../../../modules/Dashboard/components/Filters/ExcelButton";

const TranslationList = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    empty: false,
  });
  const handleSubmit = (values) => {
    setFilters(values);
  };
  const [
    mergeTranslation,
    { isLoading: isMerging },
  ] = usePostMergeTranslations();

  const [
    getFile,
    { isLoading: isFileLoading },
  ] = useGetTranslationExportExcel();

  const { data, isLoading } = useGetTranslationList({ page, ...filters });
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate
        title={_t("translations")}
        actions={
          <>
            <ExcelButton clickHandler={getFile} isLoading={isFileLoading} />
            <Button
              size="large"
              shape="round"
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginLeft: "10px" }}
              onClick={() =>
                history.push(APP_ROUTES.content.translations.import_excel)
              }
            >
              {_t("import_translations")}
            </Button>
            <Btn
              type="default"
              title={_t("merge")}
              disabled={isMerging}
              style={{ marginLeft: "10px" }}
              onClick={() => mergeTranslation()}
              loading={isMerging}
            />
          </>
        }
      >
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() =>
            history.push(APP_ROUTES.content.translations.create_key)
          }
        >
          {_t("create_new_translation")}
        </Button>
        <FiltersWidget onSubmit={handleSubmit} initialValues={filters} />
        <Spin spinning={isLoading}>
          {data && (
            <>
              {data.translations?.data?.map((item) => (
                <TranslationItem
                  id={item.key_id}
                  key={item.key_id}
                  defaultMessage={item.default_message}
                  translation={item.translation}
                />
              ))}
              <Pagination
                current={data.translations.current_page}
                defaultCurrent={1}
                total={data.translations.total}
                onChange={setPage}
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={data.translations.per_page || 20}
                className="translation-list-pagination"
              />
            </>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationList;
