import React, { useState } from "react";
import { useGetAdminMails } from "./hooks";
import { AdminChatsNav, AdminChatsSetting, ChatListWrap } from "./components";
import { Button } from "antd";
import { SettingsDropdown } from "../../../common/components";
import { PlusOutlined } from "@ant-design/icons";
import { getItem } from "../../../system/helpers/localstorageHelper";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const ChatsListAdmin = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [chatFilters, setChatFilters] = useState(
    JSON.parse(getItem("adminChatsFilters")) ?? {}
  );
  const [selectedList, setSelectedList] = useState(
    getItem("adminChatsTab") ?? "inbox"
  );

  return (
    <ChatListWrap
      useHook={useGetAdminMails}
      chatFilters={chatFilters}
      selectedList={selectedList}
      page={page}
      setPage={setPage}
      isAdminList
    >
      <SettingsDropdown
        dropContent={
          <AdminChatsSetting
            updateTrigger={selectedList}
            setChatFilters={setChatFilters}
          />
        }
        className="admin-chats-settings"
      />
      <AdminChatsNav
        setSelectedList={setSelectedList}
        selectedList={selectedList}
        setChatFilters={setChatFilters}
        resetPage={setPage}
      />
      <div className="admin-create-new-msg">
        <Button
          icon={<PlusOutlined />}
          size="large"
          shape="round"
          type="primary"
          onClick={() => history.push(APP_ROUTES.chat.admin.create)}
        >
          {_t("create_new_msg")}
        </Button>
      </div>
    </ChatListWrap>
  );
};

export default ChatsListAdmin;
