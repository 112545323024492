import React from "react";
import { Link, generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

const PartnerProfileLink = (values) => {
  const path = generatePath(APP_ROUTES.manage.user_profile, {
    partnerId: values.record?.partner_id || values?.record.id || 1,
  });
  return <Link to={path}>{values.text}</Link>;
};

export default PartnerProfileLink;
