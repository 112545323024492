import React from 'react';
import Input from 'antd/lib/input';

const SearchInput = ({onSearch, placeholder, onPressEnter, style, allowClear, type, disabled}) => {
    return (
        <Input.Search
            placeholder={placeholder}
            onPressEnter={onPressEnter}
            style={style}
            onSearch={onSearch}
            allowClear={allowClear}
            disabled={disabled}
            type={type}
            data-testid={'search-input'}
        />
    )
}

SearchInput.defaultProps = {
    placeholder: 'search',
    size: 'large',
    allowClear: true,
    enterButton: false,
    dataId: 'search-input'
};


export default SearchInput;

