import { sendOrder } from "./api";
import { useMutation } from "react-query";
import { notify } from "../../system/utils/notification";
import { _t } from "../Common/components/InjectIntlContext";

export const useSendContacts = () => {
  const messageSentTranslated = _t("message_sent");
  return useMutation(sendOrder, {
    onSuccess: (data, variables) => {
      if (data.status === "ok") {
        notify("success", messageSentTranslated);
      } else {
        Object.keys(data.message).map((errorKey) => {
          notify("error", data.message[errorKey]);
        });
      }
    },
    onError: (error) => {
      notify("error", "error");
    },
  });
};
