import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
} from "antd";
import { NotificationContentCardType } from "./types";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import NotificationStyleSelect from "../NotificationStyleSelect/NotificationStyleSelect";
import { DatePicker } from "../../../../../../modules/Common/components/DatePicker";
import moment from "moment";
import { getLocale } from "../../../../../../system/helpers/localstorageHelper";
import { log } from "util";
import { CalendarOutlined } from "@ant-design/icons";

const NotificationContentCard: React.FC<NotificationContentCardType> = ({
  initialValues,
  isEdit,
  handleSubmit,
}) => {
  const [isShowAlways, setIsShowAlways] = useState(
    !!initialValues?.show_always
  );
  const locale = getLocale();
  const [form] = Form.useForm();
  const [isSharedInputValues, setIsSharedInputValues] = useState(false);
  const history = useHistory();
  const { languages } = useSelector((state) => state.locale);

  const handleShareValues = (changedValues, allValues) => {
    if (!isSharedInputValues) return;
    const firstKey = Object.keys(changedValues)[0];
    if (firstKey.startsWith("titles")) {
      Object.values(languages).map((item: any) => {
        form.setFieldsValue({
          [`titles[${item.code}]`]: changedValues[firstKey],
        });
      });
    }
    if (firstKey.startsWith("texts")) {
      Object.values(languages).map((item: any) => {
        form.setFieldsValue({
          [`texts[${item.code}]`]: changedValues[firstKey],
        });
      });
    }
  };
  const pageTitle = isEdit
    ? initialValues?.titles[locale].translation
    : _t("notification_card_create_title");

  const handleShowAlways = () => {
    setIsShowAlways(!isShowAlways);
  };
  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{pageTitle}</Typography.Title>
      </div>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        onValuesChange={handleShareValues}
      >
        {isEdit && <Form.Item name="id" initialValue={initialValues?.id} />}
        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {_t("notification_card_content_title")}
        </Typography.Title>
        <Checkbox
          onClick={() => setIsSharedInputValues(!isSharedInputValues)}
          style={{ marginBottom: "20px" }}
        >
          {_t("same_text_for_fields")}
        </Checkbox>
        <Row gutter={14}>
          {languages &&
            Object.values(languages).map((item: any) => (
              <Col lg={8} span={24} key={item.id}>
                <Form.Item
                  name={`titles[${item.code}]`}
                  label={item.name}
                  initialValue={initialValues?.titles[item.code]?.translation}
                  rules={[
                    {
                      required: true,
                      message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input placeholder={_t("notification_placeholder_title")} />
                </Form.Item>
                <Form.Item
                  name={`texts[${item.code}]`}
                  initialValue={initialValues?.texts[item.code]?.translation}
                  rules={[
                    {
                      required: true,
                      message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={_t("notification_placeholder_text")}
                  />
                </Form.Item>
              </Col>
            ))}
        </Row>
        <Divider />
        <Row>
          <Col lg={8} span={24}>
            <Typography.Title level={5} style={{ marginBottom: "15px" }}>
              {_t("notification_card_other_settings_title")}
            </Typography.Title>
            <NotificationStyleSelect
              initialValue={initialValues?.global_notification_style_id}
            />
            <Form.Item
              name="active"
              label={_t("is_notification_active")}
              initialValue={!!initialValues?.active}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="show_always"
              label={_t("always_show_notification")}
              initialValue={!!initialValues?.show_always}
              valuePropName="checked"
            >
              <Switch onChange={handleShowAlways} />
            </Form.Item>
            {/*<Form.Item label={_t("notification_date_range")}>*/}
            {/*  <DatePicker*/}
            {/*    value={isEdit && notificationSelectedPeriod}*/}
            {/*    onChange={(value) => {*/}
            {/*      form.setFieldsValue({*/}
            {/*        day_from: moment(value.from).date(),*/}
            {/*        day_to: moment(value.to).date(),*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Form.Item>*/}

            {!isShowAlways && (
              <div className="date-range">
                <Form.Item
                  name="day_from"
                  initialValue={isEdit && initialValues?.day_from}
                >
                  <Input placeholder={_t("day_from")} />
                </Form.Item>
                <CalendarOutlined />
                <Form.Item
                  name="day_to"
                  initialValue={isEdit && initialValues?.day_to}
                >
                  <Input placeholder={_t("day_to")} />
                </Form.Item>
              </div>
            )}
          </Col>
        </Row>
        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default NotificationContentCard;
