import { useMutation, useQueryCache } from "react-query";
import { API_KEY_GET_CHAT_ADMIN } from "../../helpers";
import { postAdminChatMessage } from "../../api";

export const usePostAdminChatMessage = (id) => {
  const queryCache = useQueryCache();
  return useMutation(postAdminChatMessage, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries([API_KEY_GET_CHAT_ADMIN, id]);
    },
  });
};
