import { getTournaments, getTournamentsShow } from "./api";
import { useQuery } from "react-query";
import { GetTournamentsRequest, GetTournamentsShowRequest } from "./constants";

const Tournaments = "tournaments";
const TournamentsShow = "tournaments-show";

export const useTournaments = (params: GetTournamentsRequest) => {
  return useQuery([Tournaments, params], (k, params) => getTournaments(params));
};

export const useTournamentsShow = (params: GetTournamentsShowRequest) => {
  return useQuery([TournamentsShow, params], (k, params) =>
    getTournamentsShow(params)
  );
};
