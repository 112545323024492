import React, { useEffect, useState } from "react";
import { RegistrationForm } from "../components/RegistrationForm";
import { useRegister } from "../apiHooks";
import { REGISTRATION_SUCCESS_MESSAGE } from "../constants";
import {WelcomeForm} from "../components/WelcomeForm";
import AuthTemplate from "../../Layouts/AuthTemplate";
import {_t} from "../../Common/components/InjectIntlContext";

export function Registration() {
  const [register, { isLoading, isSuccess, data }] = useRegister();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      if (data.status === REGISTRATION_SUCCESS_MESSAGE) {
        setRegistered(true);
      }
    }
  }, [isSuccess]);

  return (
    <AuthTemplate title={_t("sign_up")}>
      {registered ? (
        <WelcomeForm data={data} />
      ) : (
        <RegistrationForm onSubmit={register} isLoading={isLoading} />
      )}
    </AuthTemplate>
  );
}
