import React from "react";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Card } from "antd";
import { SettingsForm } from "../SettingsForm";
import { ReportTable } from "../../components";
import { useSettings } from "../../hooks/table";

const ReportTableWrapper = ({
  data,
  isLoading,
  allColumns,
  showGroupBy = false,
  showSettings = true,
  visibleColumnsKey,
  setFilters,
  stateSelector,
  dispatchCallback,
  filteredColumns,
  subtotalsColumns,
  columnsOptions,
  hiddenColumns,
  additionalFilterOptions,
}) => {
  let { onChange, visibleColumns, settings } = useSettings({
    allColumns,
    visibleColumnsKey,
    stateSelector,
    dispatchCallback,
    hiddenColumns,
  });
  const columnOptionsTranslated = columnsOptions.map((item) => {
    return {
      value: item.value,
      label: _t(item.label),
    };
  });
  return (
    <Card>
      {showSettings && (
        <SettingsForm
          onSubmit={onChange}
          initialValues={settings}
          columnsOptions={columnOptionsTranslated}
          showGroupBy={showGroupBy}
        />
      )}

      <div className="system-table">
        <ReportTable
          data={data?.models?.data}
          total={data?.models?.total}
          isLoading={isLoading}
          columns={visibleColumns}
          pageSize={settings.page_size}
          tableSize={"small"}
          setFilters={setFilters}
          stateSelector={stateSelector}
          dispatchCallback={dispatchCallback}
          filteredColumns={filteredColumns}
          additionalFilterOptions={additionalFilterOptions}
          subtotalsColumns={subtotalsColumns}
        />
      </div>
    </Card>
  );
};

export default ReportTableWrapper;
