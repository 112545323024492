import React from "react";
import { RetweetOutlined } from "@ant-design/icons";
import { usePostAdminResendMessage } from "../../../hooks";
import { Spin } from "antd";

const ResendMsgButton = ({ id }) => {
  const [addToBookmark, { isLoading }] = usePostAdminResendMessage();

  return (
    <button
      className="chat-preview__control-btn chat-preview__resend"
      onClick={() => addToBookmark(id)}
      disabled={isLoading}
    >
      <Spin spinning={isLoading}>
        <RetweetOutlined />
      </Spin>
    </button>
  );
};

export default ResendMsgButton;
