import { useMutation, useQueryCache } from "react-query";
import { postAdminChatAddBookmark } from "../../api";
import { API_KEY_GET_ADMIN_CHATS } from "../../helpers";
export const usePostAdminChatAddBookmark = () => {
  const queryCache = useQueryCache();
  return useMutation(postAdminChatAddBookmark, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_ADMIN_CHATS);
    },
  });
};
