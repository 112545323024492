import {getFaqs, GetFaqRequest} from "./api";
import {useQuery} from "react-query";

const Faqs = "faq";

export const useFaqsList =
  (params: GetFaqRequest) => {
    return useQuery(
      [Faqs, params],
      (k, params) => getFaqs(params)
    );
  };

