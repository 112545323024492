import React from "react";
import { Typography } from "antd";
import { getLocale } from "../../../../system/helpers/localstorageHelper";

const GlobalNotification = ({ titles, texts, styleType }) => {
  const locale = getLocale();
  return (
    <div className={`global-notification global-notification--${styleType}`}>
      <Typography.Title level={5}>
        {titles[locale]?.translation}
      </Typography.Title>
      <div
        className="global-notification__text"
        dangerouslySetInnerHTML={{
          __html: texts[locale]?.translation,
        }}
      ></div>
    </div>
  );
};

export default GlobalNotification;
