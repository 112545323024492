import React, { useMemo, useState } from "react";
import { usePaginator, useSubtotals } from "../../hooks/table";
import { createColumnsFilters } from "../../utils";
import AntFilter from "../../../../../modules/Common/components/AntFilter";
import { SearchOutlined } from "@ant-design/icons";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Spin, Table } from "antd";
import { RenderSubtotals } from "../../components";
import { NoData } from "../../../../../modules/Common/components/NoData";

const ReportTable = ({
  pageSize,
  columns,
  data,
  isLoading,
  total,
  tableSize = null,
  setFilters,
  stateSelector,
  dispatchCallback,
  filteredColumns,
  subtotalsColumns,
  additionalFilterOptions,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = usePaginator(stateSelector, dispatchCallback);

  const onChangeReport = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.field) {
      const filterConfig = createColumnsFilters(
        filteredColumns,
        additionalFilterOptions
      );
      setFilters({
        sort:
          (filterConfig[sorter.field]
            ? filterConfig[sorter.field].searchField
            : sorter.field) +
          ":" +
          (sorter.order === "descend" ? "desc" : "asc"),
      });
    } else {
      setFilters({ sort: "" });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex, searchProp) => {
    confirm();
    setSearchFilter(dataIndex);
    setFilters({
      [searchProp]: selectedKeys.join(","),
    });
  };

  const handleReset = (clearFilters, searchProp) => {
    clearFilters();
    setFilters({
      [searchProp]: "",
    });
  };

  const getColSearchProps = (dataIndex, params) => ({
    filterDropdown: (filterProps) => (
      <AntFilter
        {...filterProps}
        dataIndex={dataIndex}
        setFilters={setFilters}
        searchProp={params.searchField}
        params={params.options({
          ...filterProps,
          handleSearch,
          dataIndex,
          searchProp: params.searchField,
        })}
        handleSearch={handleSearch}
        handleReset={handleReset}
        setSearchFilter={setSearchFilter}
        Component={params.component}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => (searchFilter === dataIndex ? text.toString() : text),
    sorter: params.sorter,
  });

  const translatedColumns = useMemo(() => {
    const filterConfig = createColumnsFilters(
      filteredColumns,
      additionalFilterOptions
    );
    return columns.map((c) => {
      const addfilterConfig = filterConfig[c.dataIndex]
        ? {
            ...getColSearchProps(c.dataIndex, filterConfig[c.dataIndex]),
          }
        : {};
      return {
        ...addfilterConfig,
        ...c,
        title: _t(c.title),
      };
    });
  }, [columns]);
  const subTotals = useSubtotals(data, subtotalsColumns, translatedColumns);
  return (
    <Table
      loading={isLoading}
      columns={translatedColumns}
      dataSource={data}
      rowKey={(r, i) => i}
      pagination={{
        total: total,
        pageSize: pageSize,
        hideOnSinglePage: true,
        onChange: setPage,
        current: page,
        showSizeChanger: false,
        position: ["bottomCenter"],
      }}
      locale={{ emptyText: <NoData /> }}
      summary={() => {
        return (
          <>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin spinning={isLoading} />
              </div>
            ) : (
              <>
                {data && (
                  <RenderSubtotals
                    subTotals={subTotals}
                    translatedColumns={translatedColumns}
                    convertSymbol={data[0]?.currency_to_symbol}
                  />
                )}
              </>
            )}
          </>
        );
      }}
      className={"report-table"}
      size={tableSize}
      onChange={onChangeReport}
    />
  );
};

export default ReportTable;
