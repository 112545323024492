import { useMutation, useQueryCache } from "react-query";
import { postNewChat } from "../api";
import { notify } from "../../../../common/helpers";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import {
  API_KEY_GET_CHAT,
  API_KEY_GET_INSTANT_MESSAGES_LIST,
} from "../helpers";

export const usePostNewChat = () => {
  const queryCache = useQueryCache();
  const successMsg = _t("new_msg_send");

  return useMutation(postNewChat, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);
      queryCache.invalidateQueries(API_KEY_GET_INSTANT_MESSAGES_LIST);
    },
  });
};
