import axiosClient from "../../system/helpers/axiosConfig";

export interface Testimonial {
  id: number;
  title: string;
  url: string;
  content: string;
}

export interface TestimonialsResponse {
  testimonials: Testimonial[];
}

export interface GetTestimonialsRequest {
  language: string;
}

export const getTestimonials = (params: GetTestimonialsRequest) => {
  return axiosClient
    .get<TestimonialsResponse>(`/api/testimonials`, { params })
    .then((r) => r.data);
};
