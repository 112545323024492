import React from "react";
import { useParams } from "react-router-dom";
import { ManageableItemShape } from "../../components";
import { Spin } from "antd";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import {
  usePostManageableListItemSend,
  useGetManageableListItem,
  usePostDeleteManageableListItem,
} from "../../hooks";

const ManageableItemEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetManageableListItem(id);
  const [updateListItem] = usePostManageableListItemSend(id);
  const [deleteListItem] = usePostDeleteManageableListItem();

  const handleEditSubmit = (values) => {
    values.active = values.status;
    delete values.status;
    updateListItem(values);
  };

  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading}>
          {data && (
            <ManageableItemShape
              title={data?.data.title}
              initialValues={{
                id: data?.data.id,
                title: data?.data.title,
                status: data?.data.active,
              }}
              handleDelete={() => deleteListItem(id)}
              isEdit
              submit={handleEditSubmit}
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableItemEdit;
