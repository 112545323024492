import { actionTypes } from "./actions";

const initialState = {
  filters: {
    project_id: null,
    promo_id: null,
    type: null,
    page: 1,
  },
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
