import { useMutation, useQueryCache } from "react-query";
import { postChatMessage } from "../api";
import { API_KEY_GET_CHAT } from "../helpers";

export const usePostChatMessage = (id) => {
  const queryCache = useQueryCache();

  return useMutation(postChatMessage, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries([API_KEY_GET_CHAT, id]);
    },
  });
};
