import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../modules/Layouts/PageTemplate";
import { Card, Pagination, Spin, Typography } from "antd";
import { ChatsList } from "./index";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

interface ChatListWrapProps {
  useHook: any;
  isAdminList?: boolean;
  page: number;
  setPage: (value) => void;
  chatFilters?: any;
  selectedList?: any;
  children?: React.ReactNode;
}
const ChatListWrap: React.FC<ChatListWrapProps> = ({
  useHook,
  isAdminList,
  page,
  setPage,
  chatFilters,
  selectedList,
  children,
}) => {
  const params = isAdminList
    ? [
        selectedList,
        {
          page,
          ...chatFilters,
        },
      ]
    : [{ page }];
  const { data, isLoading } = useHook(...params);
  const hasNewMessages = data?.data?.unread_conversations?.length > 0;
  const hasConversations = data?.data?.conversations?.data?.length > 0;
  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };
  const paginationTotal = data?.data?.conversations?.total || 0;
  return (
    <MainLayout>
      <PageTemplate title={_t("chats_title")}>
        <Card>
          {children}
          <Spin spinning={isLoading}>
            {!hasNewMessages && !hasConversations && !isLoading && (
              <Typography.Title level={5}>
                {_t("no_new_msg_title")}
              </Typography.Title>
            )}
            {hasNewMessages && (
              <ChatsList
                title={_t("new_msg_title")}
                chats={data?.data?.unread_conversations}
                isNewMessagesList
                isAdminList={isAdminList}
              />
            )}
            {hasConversations && (
              <ChatsList
                title={_t("conversations_title")}
                chats={data?.data?.conversations?.data}
                isAdminList={isAdminList}
              />
            )}
          </Spin>
        </Card>

        {paginationTotal > 50 && (
          <Pagination
            onChange={handlePaginationChange}
            defaultCurrent={page}
            total={paginationTotal}
            defaultPageSize={50}
            showSizeChanger={false}
            className="chats-pagination"
          />
        )}
      </PageTemplate>
    </MainLayout>
  );
};

export default ChatListWrap;
