import React from "react";
import { useGetDataWithoutParams } from "../../hooks/useGetDataWithoutParams";
import { API_ROUTES } from "../../../system/constants/apiRoutes";
import { Form, TreeSelect } from "antd";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const ProgramsSelect = () => {
  const { data, isLoading, isSuccess } = useGetDataWithoutParams(
    API_ROUTES.reports.filters.programs.key,
    API_ROUTES.reports.filters.programs.path
  );
  const programsAvailableOptions = isSuccess
    ? Object.keys(data.programs).map((project) => {
        return {
          label: project,
          value: project,
          children: Object.keys(data.programs[project]).map((program) => ({
            label: data.programs[project][program].full_name,
            value: data.programs[project][program].program_id,
          })),
        };
      })
    : undefined;
  return (
    <Form.Item
      label={_t("programs")}
      name="programs_filter"
      rules={[
        {
          required: false,
          message: _t("select_program"),
        },
      ]}
    >
      <TreeSelect
        treeData={programsAvailableOptions}
        placeholder={_t("select_program")}
        treeCheckable={true}
        showCheckedStrategy={"SHOW_CHILD"}
        maxTagCount={1}
        loading={isLoading}
        allowClear
      />
    </Form.Item>
  );
};

export default ProgramsSelect;
