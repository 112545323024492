import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getItem, isAuthorized } from "./localstorageHelper";
import { APP_ROUTES } from "../constants/constantsUrl";
import Login from "../../modules/Auth/Pages/Login";

const PrivateRoute = ({ component: Component, ...props }) => {
  const userRole = getItem("role");
  const { permissions } = props;
  const hasAccess = permissions?.includes(userRole);

  return (
    <Route
      {...props}
      render={(props) => {
        if (permissions) {
          if (hasAccess && isAuthorized()) {
            return <Component {...props} />;
          } else if (!isAuthorized()) {
            return <Redirect to={APP_ROUTES.login.main} />;
          } else {
            return <Login accessCheck/>;
          }
        } else {
          return (
            <>
              {isAuthorized() ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              )}
            </>
          );
        }
      }}
    />
  );
};


export default PrivateRoute;
