import React from "react";
import BtnBackImg from "../../../../images/landings/commissions_tab/back-btn-icon.svg";
import {_t} from "../../../Common/components/InjectIntlContext";
import {useHistory} from "react-router-dom";

export declare type ProjectValues = {
  affiliate: string,
  revshare: string,
  cpa: string,
  suppliers: string,
  providers: string,
  audience: string,
  currencies: string,
  languages: string,
  download_version: string,
  mobile_version: string,
}

export declare type BrandProps = {
  title: string,
  mockup_logo: any,
  mockup_desktop_logo: any,
  alt_mockup_logo: string,
  logo: any,
  logo_desktop: any,
  alt_logo: string,
  path: string,
  description: any,
  project_values: ProjectValues,
}

const BrandTemplate = (props: BrandProps) => {
  const history = useHistory();

  return (
    <section className="brands">
      <button className="btn btn__hollow back-btn" onClick={history.goBack}>
        <img src={BtnBackImg} alt=""/>
        <span>{_t('back')}</span>
      </button>
      <div className="slider slider__brands">
        <div className="brands__item" style={{width: '100%', display: 'inline-block'}}>
          <h2>{props.title}</h2>
          <div className="item__graphics-cont">
            <div className="mockup-cont">
              <img src={props.mockup_logo}
                   srcSet={props.mockup_logo + " 260w, " + props.mockup_desktop_logo + " 567w"}
                   sizes="(min-width: 1440px) 567px, 260px" alt={props.alt_mockup_logo}/>
            </div>
            <div className="logo-cont">
              <img src={props.logo}
                   srcSet={props.logo + " 126w, " + props.logo_desktop + " 250w"}
                   sizes="(min-width: 1440px) 250px, 126px"
                   alt={props.alt_logo}/>
            </div>
            <a href={props.path} target="_blank" rel="noreferrer">
              <button className="btn btn__default visit-site" tabIndex={0}>{_t('visit site')}</button>
            </a>
          </div>
          <div className="expandable-paragraph hide">
            {props.description.map((text, index) => (
              <div key={index}>
                <p>{text}</p>
                <br/>
              </div>
            ))}
          </div>
          <button className="btn btn__hollow expand-text__btn" tabIndex={0}>{_t('read more')}</button>
          <div className="proj-info__cont">
            <h3>{_t('Project information')}</h3>
            <div className="proj-info__info">
              <div className="proj-info__section">
                <div>
                  <p className="proj-info__subtitle">{_t('affiliate_programs')}:</p>
                  <p>{props.project_values.affiliate}</p>
                </div>
                <div>
                  <p className="proj-info__subtitle">{_t('RevShare')}:</p>
                  <p>{props.project_values.revshare}</p>
                </div>
                <div>
                  <p className="proj-info__subtitle">{_t('CPA')}</p>
                  <p>{props.project_values.cpa}</p>
                </div>
              </div>
              <div className="proj-info__section">
                <div>
                  <p className="proj-info__subtitle">{_t('software_supplier')}:</p>
                  <p>{props.project_values.suppliers}</p>
                </div>
              </div>
              <div className="proj-info__section">
                <div>
                  <p className="proj-info__subtitle">{_t('payment_provider')}:</p>
                  <p>{props.project_values.providers}</p>
                </div>
              </div>
              <div className="proj-info__section">
                <div>
                  <p className="proj-info__subtitle">{_t('target_audience')}:</p>
                  <p>{props.project_values.audience}</p>
                </div>
              </div>
              <div className="proj-info__section">
                <div><p className="proj-info__subtitle">{_t('Currency')}:</p>
                  <p>{props.project_values.currencies}</p></div>
                <div><p className="proj-info__subtitle">{_t('Languages')}:</p>
                  <p>{props.project_values.languages}</p></div>
                <div><p className="proj-info__subtitle">{_t('download_versions')}:</p>
                  <p>{props.project_values.download_version}</p></div>
                <div><p className="proj-info__subtitle">{_t('mobile_version')}:</p>
                  <p>{props.project_values.mobile_version}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandTemplate;
