import React from "react";
import { Space } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Link, generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

interface AffiliateBrandItemControlsProps {
  id: number;
  name: string;
}
const AffiliateBrandItemControls: React.FC<AffiliateBrandItemControlsProps> = ({
  id,
  name,
}) => {
  const landingPageCreate = generatePath(
    APP_ROUTES.affiliates_programs.landing_create,
    {
      project_name: name,
      project_id: id,
    }
  );
  const brandEditPath = generatePath(
    APP_ROUTES.affiliates_programs.brand_edit,
    {
      project_name: name,
      project_id: id,
    }
  );
  const programCreatePath = generatePath(
    APP_ROUTES.affiliates_programs.program_create,
    {
      project_name: name,
      project_id: id,
    }
  );

  return (
    <Space className="affiliate-brand-info__controls">
      <Link to={brandEditPath} className="ant-btn ant-btn-primary">
        <EditOutlined />
        {_t("edit_project")}
      </Link>
      <Link to={landingPageCreate} className="ant-btn ant-btn-primary">
        <PlusOutlined />
        {_t("add_landing")}
      </Link>
      <Link to={programCreatePath} className="ant-btn ant-btn-primary">
        <PlusOutlined />
        {_t("add_program")}
      </Link>
    </Space>
  );
};

export default AffiliateBrandItemControls;
