import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Spin} from "antd";
import * as actionTypes from "../constants";
import {_t} from "../../Common/components/InjectIntlContext";
import {getUserBasic} from "../../User/actions";
import {loginByIdHelper} from "../../../system/helpers/localstorageHelper";

export function LoginByUserId({userData, activateHook}) {
  const dispatch = useDispatch();

  const [loginAs, {isLoading, error}] = activateHook({
    onSuccess: (res) => {
      loginByIdHelper();
      dispatch({type: actionTypes.AUTH_SUCCESS, payload: res.data});
      dispatch(getUserBasic({user_id: res.data.user_id}));
    }
  });

  useEffect(() => {
    if (userData) {
      loginAs(userData);
    }
  }, [userData]);

  return (
    <div className="h-100per fx-center fxDirCol">
      {!error && userData && (
        <>
          <Spin spinning={isLoading}></Spin>
          {_t("Authentication by ID in progress, please wait...")}
        </>
      )}
      {error && <>{_t("Something went wrong")}</>}
      {!userData && <>{_t("Url is not valid, please contact your admin")}</>}
    </div>
  );
}
