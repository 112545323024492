import axiosClient from "../../../../system/helpers/axiosConfig";
import { RequestAffiliateLandingPages } from "../utils";

export const getLandingPagesList = (params: RequestAffiliateLandingPages) => {
  return axiosClient
    .get("/api/admin/landing-pages/list", {
      params,
    })
    .then((r) => r.data);
};
