import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Bahsegel from "../../components/brand/Bahsegel";

const BahsegelPage = (props) => {

    return (
        <LandingTemplate wrapClass={"brands__wrap--more"}>
            <Bahsegel />
        </LandingTemplate>
    );
};

export default BahsegelPage;
