import React from "react";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { ProjectsNav, AffiliateBrandItem } from "./components";
import { useGetBrandsList } from "../hooks";
import { Spin } from "antd";

const AffiliateBrands = () => {
  const { data, isLoading } = useGetBrandsList();

  return (
    <MainLayout>
      <PageTemplate title="Brands">
        {/*<ProjectsNav data={data} isLoading={isLoading} />*/}

        <Spin spinning={isLoading}>
          {data?.projects.map((item) => (
            <AffiliateBrandItem info={item} key={item.id} />
          ))}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateBrands;
