import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../modules/Layouts/PageTemplate";
import { Spin } from "antd";
import { ChatContainer } from "./index";

interface ChatContainerWrapProps {
  useHook: any;
  isAdmin?: boolean;
}
const ChatContainerWrap: React.FC<ChatContainerWrapProps> = ({
  useHook,
  isAdmin,
}) => {
  const { id } = useParams();
  const { data, isLoading } = useHook(id);
  return (
    <MainLayout>
      <PageTemplate title="Chat">
        <Spin spinning={isLoading}>
          {data && <ChatContainer chatInfo={data?.data} isAdmin={isAdmin} />}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default ChatContainerWrap;
