import { useQuery, useQueryCache } from "react-query";
import { getChat } from "../api";
import {
  API_KEY_GET_CHAT,
  API_KEY_GET_INSTANT_MESSAGES_LIST,
} from "../helpers";

export const useGetChat = (id: number) => {
  const queryCache = useQueryCache();

  return useQuery([API_KEY_GET_CHAT, id], () => getChat(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(API_KEY_GET_INSTANT_MESSAGES_LIST);
    },
  });
};
