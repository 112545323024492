import React from "react";
import nav_card_icon_1 from "../../../assets/images/outer/nav_card_icon_1.svg";
import nav_card_icon_2 from "../../../assets/images/outer/nav_card_icon_2.svg";
import nav_card_icon_3 from "../../../assets/images/outer/nav_card_icon_3.svg";
import nav_card_icon_4 from "../../../assets/images/outer/nav_card_icon_4.svg";

import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import NavCard from "./NavCard";

const navCardsConfig = (_t: any) => [
  {
    title: _t("tr_bet"),
    text: _t("get_started"),
    icon: nav_card_icon_1,
    innerLink: APP_ROUTES.our_products,
  },
  {
    title: _t("news"),
    text: _t("get_started"),
    icon: nav_card_icon_2,
    innerLink: APP_ROUTES.news.list,
  },
  {
    title: _t("Commissions"),
    text: _t("get_started"),
    icon: nav_card_icon_3,
    innerLink: APP_ROUTES.commissions.commission,
  },
  {
    title: _t("contacts"),
    text: _t("get_started"),
    icon: nav_card_icon_4,
    innerLink: APP_ROUTES.contacts,
  },
];

export type NavCardProps = {};

const NavCards: React.FC<NavCardProps> = () => {
  const navCards = navCardsConfig(_t).map((item, idx) => (
    <li key={item.title + idx} className="nav-cards__list-item">
      <NavCard info={item} />
    </li>
  ));

  return (
    <div className="nav-cards">
      <div className="container">
        <ul className="nav-cards__list">{navCards}</ul>
      </div>
    </div>
  );
};

export default NavCards;
