import axiosClient from "../../system/helpers/axiosConfig";

export interface Payment {
  id: number;
  type: number;
  typeName?: string;
  amount: string;
  created_at: string;
  project_id: number;
  name: string;
  comment: string;
}

export interface Payout {
  id: number;
  created_at: string;
  amount: string;
  comment: string;
}

export interface Accrual {
  period: string;
  partner_income: string;
  first_deposits: string;
}

interface BonusesAndFinesListResponse {
  data: {
    payments: Payment[];
    paymentTypes: {
      [key: string]: string;
    };
  };
}

interface PayoutsListResponse {
  data: Payout[];
}

interface AccrualsListResponse {
  data: Accrual[];
}

interface CashTypesResponse {
  data: {
    [id: string]: string;
  };
}

interface PaymentDetail {
  cash_type: number;
  wallet: string;
}

interface UserWalletResponse {
  data: {
    id: number;
    wallet: string;
    cash_type: number;
    cash_type_label: string;
    wallet_note: string;
  };
}

export const getBonusesAndFinesList = (params: { limit?: number }) => {
  return axiosClient
    .get<BonusesAndFinesListResponse>(`/api/payments/bonuses-and-fines`, {
      params,
    })
    .then((r) => {
      return {
        paymentTypes: r.data.data.paymentTypes,
        payments: r.data.data.payments.map((p, i) => {
          return { ...p, typeName: r.data.data.paymentTypes[p.type] };
        }),
      };
    });
};
export const getPayoutsList = (params: { limit?: number }) => {
  return axiosClient
    .get<PayoutsListResponse>(`/api/payments/payouts`, {
      params,
    })
    .then((r) => r.data.data);
};

export const getAccrualsList = () => {
  return axiosClient
    .get<AccrualsListResponse>(`/api/payments/accruals`)
    .then((r) => r.data.data);
};

export const getCashTypes = () => {
  return axiosClient
    .get<CashTypesResponse>(`/api/payments/cashTypes`)
    .then((r) => r.data.data);
};

export const updatePaymentDetails = (data: PaymentDetail) => {
  return axiosClient.post(`/api/payments/updatePaymentDetails`, data);
};

export const getUserWallet = () => {
  return axiosClient
    .get<UserWalletResponse>(`/api/payments/wallet`)
    .then((r) => r.data.data);
};

export const getPaymentsSuggestions = (params) => {
  return axiosClient
    .post(`/api/payments/validatePaymentDetails`, params)
    .then((r) => r.data.data);
};
