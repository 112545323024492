import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Select } from "antd";
import { PartnersSearch } from "../../../../../common/components";
import {
  getItem,
  removeItem,
  setItem,
} from "../../../../../system/helpers/localstorageHelper";
import { setFormInputValue } from "../../../../../common/helpers";
import { selectDataProps } from "../../helpers";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const AdminChatsSettingForm = ({ updateTrigger, setChatFilters }) => {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState(updateTrigger);
  const { Option } = Select;
  const [selectData, setSelectData] = useState<selectDataProps>();
  const [searchValue, setSearchValue] = useState(null);
  const handleMarkedCheck = (e) => {
    setFormInputValue("marked", e.target.checked ? 1 : 0, form);
  };
  const initialFiltersValues = JSON.parse(getItem("adminChatsFilters")) ?? {
    sort: "",
    partner_id: "",
    marked: 0,
  };
  const clearSearch = () => {
    setSearchValue(null);
    setSelectData({ data: [] });
    setFormInputValue("partner_id", "", form);
  };

  const sendFilters = (values) => {
    if (searchValue) {
      values.partner_id = searchValue;
    } else {
      values.partner_id = "";
    }
    for (let i in values) {
      if (values[i] === null || values[i] === undefined) values[i] = "";
    }
    setItem("adminChatsFilters", JSON.stringify(values));
    setChatFilters(values);
  };

  const clearForm = () => {
    removeItem("adminChatsFilters");
    clearSearch();
    form.resetFields();
    setFormInputValue("sort", "", form);
    setFormInputValue("marked", 0, form);
    setFormInputValue("partner_id", "", form);
    setChatFilters(form.getFieldsValue());
  };
  useEffect(() => {
    if (currentTab === updateTrigger) return;
    clearForm();
    setCurrentTab(updateTrigger);
  }, [updateTrigger]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={sendFilters}
      initialValues={initialFiltersValues}
    >
      <Form.Item label={_t("sort_by")} name="sort">
        <Select>
          <Option value="">{_t("default")}</Option>
          <Option value="priority">{_t("priority")}</Option>
        </Select>
      </Form.Item>
      <Form.Item label={_t("search_partner_title")} name="partner_id">
        <PartnersSearch
          selectData={selectData}
          setSelectData={setSelectData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          Option={Option}
          clearSearch={clearSearch}
        />
      </Form.Item>
      <Form.Item name="marked">
        <Checkbox
          onChange={handleMarkedCheck}
          value={initialFiltersValues.marked}
          defaultChecked={initialFiltersValues.marked === 1}
        >
          {_t("marked_only")}
        </Checkbox>
      </Form.Item>
      <Button type="primary" size="large" shape="round" htmlType="submit">
        {_t("Save")}
      </Button>
      <Button
        htmlType="button"
        size="large"
        shape="round"
        onClick={clearForm}
        style={{ marginTop: "10px" }}
      >
        {_t("reset")}
      </Button>
    </Form>
  );
};

export default AdminChatsSettingForm;
