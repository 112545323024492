import React from "react";
import { Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import classNames from "classnames";
import { AffiliateBrandItemProps } from "../../utils";
import { AffiliateBrandItemControls } from "./index";

const AffiliateBrandItemInfo: React.FC<AffiliateBrandItemProps> = ({
  data,
}) => {
  const { name, status, image, id } = data;
  const setStatus = () => {
    switch (status) {
      case "1":
        return _t("active");
      case "2":
        return _t("special");
      default:
        return _t("disabled");
    }
  };

  return (
    <div className="affiliate-brand-info">
      <div className="affiliate-brand-info__main">
        <Typography.Title level={3} title={name}>
          {name}
        </Typography.Title>
        <p
          className={classNames(
            "affiliate-brand-status",
            { "affiliate-brand-status--active": status === "1" },
            { "affiliate-brand-status--special": status === "2" }
          )}
        >
          {_t("status")} :
          <span className="affiliate-brand-status__mark">{setStatus()}</span>
        </p>
      </div>
      <div className="affiliate-brand-info__logo">
        <img src={image} alt="" />
      </div>
      <AffiliateBrandItemControls id={id} name={name} />
    </div>
  );
};

export default AffiliateBrandItemInfo;
