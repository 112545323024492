import React from "react";
import MainLayout from "../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const NewChatWrap = ({ useHook, Content }) => {
  const [sendNewMessage] = useHook();
  return (
    <MainLayout>
      <PageTemplate title={_t("create_new_msg")}>
        <Content sendMail={sendNewMessage} />
      </PageTemplate>
    </MainLayout>
  );
};

export default NewChatWrap;
