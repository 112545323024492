import React from "react";
import classNames from "classnames";

type RadioBtnProps = {
  name: string | React.ReactElement;
  number?: string | number;
  selected?: string;
  id?: string | number;
  onClick?: (id) => any;
  value: {
    id: string | number;
    name?: string;
  };
};

const RadioBtn: React.FC<RadioBtnProps> = ({
  onClick,
  name,
  number,
  selected,
  id,
  value,
}) => {
  return (
    <div
      className={classNames("radio-btn", selected && "radio-btn__selected")}
      onClick={() => onClick(value)}
    >
      <span className={"radio-btn__name"}>{name}</span>
      {number && <span className={"radio-btn__number"}>{number}</span>}
    </div>
  );
};

export default RadioBtn;
