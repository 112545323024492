const validationRules = {
  login: [
    {
      required: true,
      message: 'Please input your Login!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your Password!',
    },
  ],
};

export { validationRules };