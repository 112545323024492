import axiosClient from "./axiosConfig";
import moment from "moment";
import { getItem, logoutHelperalogoutAdminHelper } from "./localstorageHelper";
import { store } from "../store";
import { notify } from "../utils/notification";
import { logoutHelper, logoutAdminHelper } from "./localstorageHelper";

const routesWithoutAuth = [
  "api/login",
  "api/login-by-hash",
  "api/user/register",
  "api/user/activate",
  "api/user/restore",
  "api/user/reset-password",
  "api/faq",
  "api/faqs",
  "api/news",
  "api/news-content",
  "api/tournaments",
  "api/tournaments/show",
  "api/feedback/send",
  "api/translation",
  "api/testimonials",
  "api/translations/merge",
  "api/translations/languages",
  "api/translations/list/en",
  "api/translations/list/tr",
  "api/translations/list/pt",
  "api/translations/list/ja",
];
const isRouteWithoutAuth = (url) =>
  routesWithoutAuth.some((route) => url.endsWith(route));

const logoutUser = (error = {}) => {
  store.dispatch({ type: "LOGOUT" });
  logoutHelper();

  return Promise.reject(error);
};

export const tokenTimeout = (func) => {
  const tokenLifeTime = Number(localStorage.getItem("tokenLifeTime") / 2);
  return setTimeout(func, tokenLifeTime);
};

export const addTokenToRequest = () => {
  axiosClient.interceptors.request.use(function (config) {
    const token = getItem("token");

    if (!validTokenLifeTime() && !isRouteWithoutAuth(config.url)) {
      return logoutUser();
    }

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  });
};

export const addResponseErrorHandler = () => {
  axiosClient.interceptors.response.use(
    (r) => r,
    function (error = { response: {}, config: {} }) {
      if (process.env.NODE_ENV === "test") {
        return Promise.reject(error);
      }

      const { response, config } = error;

      if (
        error.response.data &&
        error.response.data.error &&
        typeof error.response.data.error === "string" &&
        !(config.notifyMessage && config.notifyMessage.error)
      ) {
        notify("error", error.response.data.error);
      }

      if (!isRouteWithoutAuth(config.url) && response.status === 401) {
        return logoutUser(error);
      }
      if (!isRouteWithoutAuth(config.url) || response.status === 403) {
        logoutAdminHelper();
      }

      return Promise.reject(error);
    }
  );
};

export const setUpAuthHandlers = () => {
  addTokenToRequest();
  addResponseErrorHandler();
};

export const validTokenLifeTime = () => {
  const expiredAt = getItem("expiredAt");
  const dateNow = moment().format("X");

  return dateNow < expiredAt;
};
