import React, { useMemo } from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form } from "antd";
import { useFilters } from "../../hooks";
import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";
import { SelectInput } from "../../../Common/components";
import { GroupSelect } from "../../../Dashboard/pages/Promos";
import { addAllOption } from "../../../Dashboard/utils";

const ArchiveFilters = ({
  projectsData,
  typesData,
  promoOptions,
  loadingTypes,
  loadingProjects,
  loadingPromos,
}) => {
  const modifyData = (data = {}): any => {
    return Object.keys(data).map((item, idx) => ({
      value: item,
      label: Object.values(data)[idx],
    }));
  };
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();
  const projectOptions = useMemo(() => {
    return addAllOption(modifyData(projectsData));
  }, [projectsData]);
  const typeOptions = useMemo(() => {
    return addAllOption(modifyData(typesData));
  }, [typesData]);
  const onApply = (values) => {
    const data = {
      page: 0,
      ...values,
    };
    updateFilters(data);
  };

  return (
    <Filters
      form={form}
      onApply={onApply}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="project_id">
            <SelectInput
              allowClear={false}
              style={{ minWidth: 200, maxWidth: 300 }}
              placeholder={_t("project")}
              onDropdownVisibleChange={(open) => !open && form.submit()}
              options={projectOptions}
              loading={loadingProjects}
            />
          </Form.Item>
          <Form.Item name="type">
            <SelectInput
              allowClear={false}
              style={{ minWidth: 200, maxWidth: 300 }}
              placeholder={_t("promo_type")}
              onDropdownVisibleChange={(open) => !open && form.submit()}
              options={typeOptions}
              loading={loadingTypes}
            />
          </Form.Item>
          <Form.Item name="promo_id">
            <GroupSelect
              defaultValue={null}
              style={{ minWidth: 200, maxWidth: 300 }}
              loading={loadingPromos}
              allowClear={false}
              onDropdownVisibleChange={(open) => !open && form.submit()}
              options={promoOptions}
              showSearch
              optionFilterProp={"search"}
              placeholder={_t("find_promo")}
            />
          </Form.Item>
        </Space>
      }
    />
  );
};

export default ArchiveFilters;
