import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetInstantMessagesList } from "./hooks";
import { ChatListWrap } from "./components";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const ChatsListUser = () => {
  const [page, setPage] = useState(1);
  const history = useHistory();

  return (
    <ChatListWrap
      useHook={useGetInstantMessagesList}
      page={page}
      setPage={setPage}
    >
      <div className="admin-create-new-msg">
        <Button
          icon={<PlusOutlined />}
          size="large"
          shape="round"
          type="primary"
          onClick={() => history.push(APP_ROUTES.chat.create)}
        >
          {_t("create_new_msg")}
        </Button>
      </div>
    </ChatListWrap>
  );
};

export default ChatsListUser;
