import React from 'react';
import {Link} from "react-router-dom";
import {_t} from "../../Common/components/InjectIntlContext";

const NavCard = ({info}) => {
  const {title, icon, outerLink, innerLink} = info;
  return (
    <div className="nav-card">
      <div className="nav-card__info">
        <p className="nav-card__title">{title}</p>
        {outerLink && <a href={outerLink} target="_blank" rel="noreferrer"  className="btn">{_t("get_started")}</a> }
        {innerLink && <Link to={innerLink} className="btn">{_t("get_started")}</Link>}
      </div>
      <div className="nav-card__icon">
        <img src={icon} alt=""/>
      </div>
    </div>
  );
};

export default NavCard;