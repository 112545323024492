export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const REFRESH_TOKEN_START = "REFRESH_TOKEN_START";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export const STATS_START = "STATS_START";
export const STATS_SUCCESS = "STATS_SUCCESS";
export const STATS_FAIL = "STATS_FAIL";

export const REGISTRATION_SUCCESS_MESSAGE = "success";
