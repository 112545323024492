import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { useTranslation } from "../apiHooks";
import { Spin } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const PostbackOptionsPage = () => {
  const { data, isLoading } = useTranslation({
    language: getLocale(),
    name: "faq-postback",
  });
  return (
    <section className="postback-options">
      <h2>{_t("postback_options")}</h2>
      <Spin spinning={isLoading}>
        <div
          dangerouslySetInnerHTML={{ __html: data?.text }}
          className={"text__content"}
        ></div>
      </Spin>
    </section>
  );
};

export default PostbackOptionsPage;
