import moment from "moment";
import { actionTypes } from "./actions";
import { dateKey } from "./constants";

const getInitialDate = () => {
  let date;

  try {
    const savedDate = JSON.parse(localStorage.getItem(dateKey));
    date = {
      from: moment(savedDate.from),
      to: moment(savedDate.to),
    };
  } catch (e) {
    console.log(e);
    date = {
      from: moment().subtract(1, "month"),
      to: moment(),
    };
  }

  return date;
};

const initialState = {
  filters: {
    date: getInitialDate(),
    game: "",
    page_size: "100",
    refcode: "",
    url: "",
    page: 1,
    currentPage: "deposit",
  },
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
