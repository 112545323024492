import React from "react";
import { Link, generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

const GamerProfileLink = (values) => {
  const path = generatePath(APP_ROUTES.dashboard.gamer_profile, {
    projectId: values.record.project_id,
    gamerId: values.text,
  });
  return <Link to={path}>{values.text}</Link>;
};

export default GamerProfileLink;
