import React from "react";
import {Input} from "antd";
import {ProfileSubmitForm} from "./ProfileSubmitForm";

const {TextArea} = Input;

export const CollapsableTextArea = ({user, name, value, onSubmit, selected, isLoading, setEditable}) => {
  const initialValues = {
    id: user.id,
    field_name: name,
    value: value,
  };
  return (
    <ProfileSubmitForm textValue={value} initialValues={initialValues} isLoading={isLoading} name={name}
                       onSubmit={onSubmit} selected={selected} setEditable={setEditable}>
      <TextArea autoSize={{minRows: 2, maxRows: 7}}/>
    </ProfileSubmitForm>
  );
};
