import React from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { DashboardFilters } from "../components/Filters/Filters";
import { Space, Divider } from "antd";
import { TableTotals } from "../components/Totals/TableTotals";
import { CollapsebleChart } from "../components/Charts/LineChart";
import { useFilters } from "../hooks";
import { useGeneralReportList, useGeneralReportListFile } from "../apiHooks";
import { allGeneralPageColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import { ExcelButton } from "../components/Filters/ExcelButton";

const useGeneralData = () => {
  const scope = "by-days";
  const [{ date, page_size, period, columns, page }] = useFilters();
  const chartFilters = { from: date.from, to: date.to, period, columns };
  const reqFilters = {
    from: date.from,
    to: date.to,
    scope,
  };
  const { isLoading, data } = useGeneralReportList({
    ...reqFilters,
    page_size,
    page,
  } as any);

  const totals = data?.totals;
  return { isLoading, data, totals, chartFilters, reqFilters };
};

export const General = () => {
  const {
    data,
    isLoading,
    totals,
    chartFilters,
    reqFilters,
  } = useGeneralData();

  const [getFile, { isLoading: isFileLoading }] = useGeneralReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <Space
        size={0}
        direction="vertical"
        style={{ width: "100%", display: "flex" }}
      >
        <DashboardFilters
          fields={
            <ExcelButton
              clickHandler={clickHandler}
              isLoading={isFileLoading}
            />
          }
        />        <Divider style={{ margin: 0 }} />
        <CollapsebleChart filters={chartFilters} />
      </Space>

      <TableTotals totals={totals} isLoading={isLoading} />
      <ReportTableWidget
        data={data}
        isLoading={isLoading}
        allColumns={allGeneralPageColumns}
      />
    </PageTemplate>
  );
};
