export const groupOptions = (_t: any) => [
  { value: "day", label: _t("Days") },
  { value: "total", label: _t("total") },
];

export const perPageOptions = (_t: any) => [
  { value: "100", label: "100" },
  { value: "500", label: "500" },
  { value: "1000", label: "1000" },
];
