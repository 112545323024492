import React from "react";
import classNames from "classnames";
import {
  CalendarOutlined,
  EditOutlined,
  DeleteOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Button, Card, Divider, Spin, Switch, Typography } from "antd";
import StatusLabel from "../../../../../../../../common/components/StatusLabel/StatusLabel";
import { _t } from "../../../../../../../../modules/Common/components/InjectIntlContext";
import GlobalNotification from "../../../../../../components/GlobalNotification/GlobalNotification";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../../../system/constants/constantsUrl";
import { usePostNotificationStatus } from "../../../../hooks";

const ManageableNotificationListItem = ({ item }) => {
  const {
    id,
    active,
    style_name,
    titles,
    texts,
    day_from,
    day_to,
    show_always,
    deleted,
  } = item;
  const [
    postNotificationStatus,
    { isLoading: isLoadingNotification },
  ] = usePostNotificationStatus();

  const notificationDate = show_always
    ? _t("display_always")
    : `${_t("show_notification_at_days")} | ${day_from} - ${day_to}`;
  const [
    updateStatus,
    { isLoading: isStatusLoading },
  ] = usePostNotificationStatus();

  const notificationPath = generatePath(APP_ROUTES.manage.notifications.edit, {
    id,
  });
  const history = useHistory();
  return (
    <Card
      className={classNames("notification-list-item", {
        "notification-list-item--deleted": deleted,
      })}
    >
      <Spin spinning={isLoadingNotification}>
        <div className="notification-list-item__top">
          {!deleted && (
            <>
              <Switch
                defaultChecked={!!active}
                disabled={isStatusLoading}
                checked={!!active}
                onChange={() =>
                  updateStatus({
                    id,
                    type: !!active ? "deactivate" : "activate",
                  })
                }
              />
              <Spin spinning={isStatusLoading}>
                <StatusLabel
                  status={active}
                  text={active ? _t("active") : _t("disabled")}
                />
              </Spin>
              <div className="notification-list-item__time">
                <CalendarOutlined /> {_t("notification_show_period")}:
                <Typography.Title level={5}>
                  {" "}
                  {notificationDate}
                </Typography.Title>
              </div>
            </>
          )}

          {!deleted && (
            <Button
              size="large"
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              onClick={() => history.push(notificationPath)}
              className="notification-list-item__edit"
            >
              {_t("edit")}
            </Button>
          )}
          {deleted ? (
            <Button
              size="large"
              shape="round"
              icon={<RedoOutlined />}
              onClick={() => postNotificationStatus({ id, type: "restore" })}
              disabled={isLoadingNotification}
            >
              {_t("restore")}
            </Button>
          ) : (
            <Button
              size="large"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={() => postNotificationStatus({ id, type: "delete" })}
              disabled={isLoadingNotification}
            >
              {_t("delete")}
            </Button>
          )}
        </div>
        <Divider />
        <GlobalNotification
          titles={titles}
          texts={texts}
          styleType={style_name}
        />
      </Spin>
    </Card>
  );
};

export default ManageableNotificationListItem;
