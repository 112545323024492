import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { useGetLandingPageInfo } from "../hooks";
import { Spin } from "antd";
import { AffiliateLandingPageForm } from "./components";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { usePostLandingPageUpdate } from "../hooks";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const AffiliateLandingEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetLandingPageInfo({ id });
  const [updateLanding] = usePostLandingPageUpdate({
    id,
  });

  const locale = getLocale();
  const pageTitle = `${_t("edit")} ${
    data ? data?.landing_page.translations[locale] : ""
  }`;
  return (
    <MainLayout>
      <PageTemplate title={pageTitle}>
        <Spin spinning={isLoading}>
          {data && (
            <AffiliateLandingPageForm
              info={data?.landing_page}
              updateLanding={updateLanding}
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateLandingEdit;
