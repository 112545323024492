import axiosClient from "../../../../../system/helpers/axiosConfig";
import { responseFile } from "../../../../../system/helpers/requestHelper";

export const getTranslationExportExcel = (params) => {
  return axiosClient
    .get(`api/translations/export/excel`, { responseType: "blob", params })
    .then((r) => {
      const lang = r.config.params["lang"] ?? "en";
      let fileName = "translations_" + lang + ".xlsx";
      return responseFile(r, fileName);
    });
};
