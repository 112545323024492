import axiosClient from "../../../../system/helpers/axiosConfig";

export const getLandingPageLanguages = () => {
  return axiosClient
    .get(`/api/admin/landing-pages/languages`)
    .then((r) => r.data);
};

export const getLandingPageTitleLanguages = () => {
  return axiosClient.get(`/api/admin/programs/languages`).then((r) => r.data);
};
