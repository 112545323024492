import axiosClient from "../../system/helpers/axiosConfig";


export interface Subaffiliate {
  login: string;
  partner_income: string;
  reg_date: string;
  subaffiliate_id: number;
  subaffiliate_income: string;
}

interface TotalsResponse {
  data: {
    subaffiliates: Subaffiliate[];
    total_income: number;
  };
}


export interface ByDaysStats {
  date: string;
  partner_income: string;
  subaffiliate_income: string;
}

interface ByDaysResponse {
  data: {
    stats: ByDaysStats[];
    total_partner_income: number;
    total_subaffiliate_income: number;
  };
}

export interface ByDaysRequest {
  from?: string;
  to?: string;
}

export interface ByInvitesStats {
  promo_id: number;
  promo: {
    code: string;
    id: number;
  };
}

interface ByInvitesResponse {
  data: {
    stats: ByInvitesStats[];
    total_partner_income: number;
    total_subaffiliate_income: number;
  };
}

export interface ByInvitesRequest {
  from?: string;
  to?: string;
}

export interface BySubaffiliatesStats {
  subaffiliate_id: number;
  login: string;
  partner_income: number;
  subaffiliate_income: number;
}

interface BySubaffiliatesResponse {
  data: {
    stats: BySubaffiliatesStats[];
    total_partner_income: number;
    total_subaffiliate_income: number;
  };
}

export interface BySubaffiliatesRequest {
  from?: string;
  to?: string;
}

export const getTotals = (params: { user_id?: number }) => {
  return axiosClient
    .get<TotalsResponse>(`/api/subaffiliates/totals`, {
      params,
    })
    .then((r) => r.data);
};

export const getByDays = (params: ByDaysRequest) => {
  return axiosClient
    .get<ByDaysResponse>(`/api/subaffiliates/by-days`, {
      params,
    })
    .then((r) => r.data);
};

export const getByInvites = (params: ByInvitesRequest) => {
  return axiosClient
    .get<ByInvitesResponse>(`/api/subaffiliates/by-invites`, {
      params,
    })
    .then((r) => r.data);
};

export const getBySubAffiliates = (params: BySubaffiliatesRequest) => {
  return axiosClient
    .get<BySubaffiliatesResponse>(`/api/subaffiliates/by-subaffiliates`, {
      params,
    })
    .then((r) => r.data);
};
