import axiosClient from "../../system/helpers/axiosConfig";

interface PostBackResponce {
  data: PostBackResponceData;
}

interface PostBackResponceData {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string | null;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
  data: PostBackResponceTableData[];
}

interface PostBackResponceTableData {
  postback_date: string;
  postback_status: number;
  amount: number;
  refcode: string;
  gamer_id: string;
  info: string;
  project_name: string;
  url: string;
}

export interface getPostbackRequest {
  date_from: string;
  date_to: string;
  game?: string;
  page_size?: number;
  refcode?: string;
  url?: string;
}

export const getPostback = (
  params: getPostbackRequest,
  currentPage: string
) => {
  return axiosClient
    .get<PostBackResponce>(`/api/postback-debug/${currentPage}`, {
      params,
    })
    .then((r) => r.data.data);
};
