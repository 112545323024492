import React from "react";
import {NavLink} from "react-router-dom";
import {_t} from "../../Common/components/InjectIntlContext";

type BrandProps = {
  mockup_logo: any,
  mockup_desktop_logo: any,
  alt_mockup_logo: string,
  logo: any,
  logo_desktop: any,
  alt_logo: string,
  path: string,
  description: string,
}

const BrandItem = ({
                     mockup_logo, mockup_desktop_logo, alt_mockup_logo,
                     logo, logo_desktop, alt_logo, path, description
                   }: BrandProps) => {
  return (
    <div className="brands__item">
      <div className="mockup-cont">
        <img src={mockup_logo}
             srcSet={mockup_desktop_logo + ", " + mockup_logo + " 260w"}
             sizes="(min-width: 1440px) 567px, 260px" alt={alt_mockup_logo}/>
      </div>
      <div className="brands__inner-wrap">
        <div className="logo-cont">
          <img src={logo}
               srcSet={logo + " 142w, " + logo_desktop + " 250w"}
               sizes="(min-width: 1440px) 250px, 142px" alt={alt_logo}/>
        </div>
        <p>{description}</p>
        <button className="btn btn__hollow">
          <NavLink to={path}>{_t('read more')}</NavLink>
        </button>
      </div>
    </div>

  );
};

export default BrandItem;
