import React from "react";
import BahsegelMockupLogoImg from "../../../../images/landings/brands_tab/Mockup_bahsegel.png";
import BahsegelMockupDesktopLogoImg from "../../../../images/landings/brands_tab/Mockup_bahsegel--desktop.png";
import BahsegelLogoImg from "../../../../images/landings/brands_tab/bahsegel_logo.png";
import BahsegelLogoDesktopImg from "../../../../images/landings/brands_tab/bahsegel_logo--desktop.png";
import {_t} from "../../../Common/components/InjectIntlContext";
import BrandTemplate, {BrandProps, ProjectValues} from "./BrandTemplate";

const project = (_t: any): BrandProps => (
  {
    title: 'Bahsegel',
    mockup_logo: BahsegelMockupLogoImg,
    mockup_desktop_logo: BahsegelMockupDesktopLogoImg,
    alt_mockup_logo: "bahsegel mockup",
    logo: BahsegelLogoImg,
    logo_desktop: BahsegelLogoDesktopImg,
    alt_logo: "bahsegel logo",
    path: "https://bahsegel.com",
    description: [
      _t('bahsegel_desc_1'),
      _t('bahsegel_desc_2'),
      _t('bahsegel_desc_3'),
      _t('bahsegel_desc_4'),
      _t('bahsegel_desc_5'),
      _t('bahsegel_desc_6'),
      _t('bahsegel_desc_7'),
    ],
    project_values: {
      affiliate: _t("bahsegel_affiliate"),
      revshare: _t("bahsegel_revshare"),
      cpa: _t("bahsegel_cpa"),
      suppliers: _t("bahsegel_suppliers"),
      providers: _t("bahsegel_providers"),
      audience: _t("bahsegel_audience"),
      currencies: _t("bahsegel_currencies"),
      languages: _t("bahsegel_languages"),
      download_version: _t("bahsegel_download_version"),
      mobile_version: _t("bahsegel_mobile_version"),
    }
  }
);

const Bahsegel = () => {
  const projectTranslated = project(_t);
  return (
    <div>
      <BrandTemplate {...projectTranslated} />
    </div>
  );
};

export default Bahsegel;
