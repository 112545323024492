import React from "react";
import { Card } from "antd";
import {
  AffiliateLandingPages,
  AffiliatePrograms,
  AffiliateBrandItemInfo,
} from "./index";
import { AffiliateBrandItemProps } from "../../utils";

const AffiliateBrandItem: React.FC<AffiliateBrandItemProps> = ({ info }) => {
  return (
    <Card className="affiliate-brand-item" id={`brand-${info.id}`}>
      <AffiliateBrandItemInfo data={info} />
      <AffiliatePrograms id={info.id} name={info.name} />
      <AffiliateLandingPages id={info.id} />
    </Card>
  );
};

export default AffiliateBrandItem;
