import { actionTypes } from "./actions";
import { getLocale } from "../../system/helpers/localstorageHelper";

const initialState = {
  locale: getLocale(),
  languages: [],
};

export default function locale(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };

    case actionTypes.UPDATE_LANGUAGES:
      return {
        ...state,
        languages: {
          ...state.languages,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
