import React from "react";
import { Link } from "react-router-dom";
interface InfoLabelProps {
  title?: string;
  value?: string;
  type?: string;
  path?: string;
}
const InfoLabel: React.FC<InfoLabelProps> = ({ title, value, type, path }) => {
  return (
    <div className="chat-info-label">
      {title}:{" "}
      {type === "link" ? (
        <Link to={path} className="chat-info-label__value">
          {value}
        </Link>
      ) : (
        <span className="chat-info-label__value">{value}</span>
      )}
    </div>
  );
};

export default InfoLabel;
