import React from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { usePostTranslationKey } from "../../hooks";
import { Spin } from "antd";
import { TranslationKeyCard } from "../../components";

const TranslationKeyCreate = () => {
  const [
    createTranslation,
    { isLoading: isPostTranslationLoading },
  ] = usePostTranslationKey();

  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isPostTranslationLoading}>
          <TranslationKeyCard handleSubmit={createTranslation} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationKeyCreate;
