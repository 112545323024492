import React from "react";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { generatePath, useHistory } from "react-router-dom";
import { Button, Card, Divider, Spin, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { usePostTestimonialStatus } from "../hooks";

const TestimonialItem = ({ id, name, title, link, content }) => {
  const testimonialPath = generatePath(APP_ROUTES.content.testimonials.edit, {
    id,
  });
  const [
    postTestimonialStatus,
    { isLoading: isLoadingTestimonial },
  ] = usePostTestimonialStatus();
  const history = useHistory();

  return (
    <Card className={"testimonial-list-item"}>
      <Spin spinning={isLoadingTestimonial}>
        <div className="testimonial-list-item__top">
          <div className="testimonial-list-item__title">
            <Typography.Title level={5}>{name}</Typography.Title>
          </div>
          <Button
            size="large"
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => history?.push(testimonialPath)}
            className="testimonial-list-item__edit"
          >
            {_t("edit")}
          </Button>
          <Button
            size="large"
            shape="round"
            icon={<DeleteOutlined />}
            onClick={() => postTestimonialStatus({ id, type: "delete" })}
            disabled={isLoadingTestimonial}
          >
            {_t("delete")}
          </Button>
        </div>
        <Divider />
        <div
          className="testimonial-list-item__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </Spin>
    </Card>
  );
};

export default TestimonialItem;
