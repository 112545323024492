import React from "react";
import { useLoginByHash } from "../apiHooks";
import { useUrlQuery } from "../utils";
import { ActivateUser } from "../components/ActivateUser";

export const LoginByHash = () => {
  const query = useUrlQuery();
  const hash = query.get("hash");

  return <ActivateUser activateHook={useLoginByHash} userData={hash} />;
};
