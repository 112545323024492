import React, { useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { useGetFaqList } from "../../hooks";
import { Button, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import { getLocale } from "../../../../../../system/helpers/localstorageHelper";
import FaqItem from "../../components/FaqItem";

const FaqList = () => {
  const [page, setPage] = useState(1);
  const language = getLocale();
  const { data, isLoading } = useGetFaqList({ page });
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate title={_t("Faq")}>
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => history.push(APP_ROUTES.content.faq.create)}
        >
          {_t("create_new_faq")}
        </Button>
        <Spin spinning={isLoading}>
          {data && (
            <>
              {data.list?.map((item) => (
                <FaqItem
                  id={item.id}
                  key={item.id}
                  visible={item.visible}
                  category={item.category.label}
                  title={item.translations[language]?.question}
                  content={item.translations[language]?.answer}
                />
              ))}
              <Pagination
                current={data.pagination.current_page}
                defaultCurrent={1}
                total={data.pagination.total}
                onChange={setPage}
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={data.pagination.per_page || 20}
                className={"faq-list-pagination"}
              />
            </>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FaqList;
