import React from "react";
import { Button, Card, Form, Input, Select } from "antd";
import { usePostAdminChatOptions } from "../../../hooks";
import { useParams } from "react-router-dom";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const DropMenu = ({ priority }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { Option } = Select;
  const [sendOptions] = usePostAdminChatOptions();

  const sendForm = (values) => {
    sendOptions(values);
  };
  return (
    <div>
      <Card className="chat-ticket-dropdown">
        <Form layout="vertical" form={form} onFinish={sendForm}>
          <Form.Item label="Priority" name="id" hidden initialValue={id}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Priority"
            name="priority"
            initialValue={priority + ""}
          >
            <Select defaultValue={priority + ""}>
              <Option value="1">{_t("low")}</Option>
              <Option value="5">{_t("medium")}</Option>
              <Option value="10">{_t("high")}</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" shape="round" htmlType="submit">
              {_t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default DropMenu;
