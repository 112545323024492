export const useSubtotals = (data, subtotalsColumns, translatedColumns) => {
  const subtotalColumnsArray = translatedColumns
    .filter((x) => subtotalsColumns.includes(x.dataIndex))
    .map((column) => {
      return column.dataIndex;
    });
  let subTotals = [];
  subtotalColumnsArray.forEach((column) => {
    subTotals[column] = 0;
  });
  data?.forEach((row) => {
    subtotalColumnsArray.forEach((columnName) => {
      subTotals[columnName] += row[columnName] ? parseInt(row[columnName]) : 0;
    });
  });
  return subTotals;
};
