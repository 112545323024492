import React, { useMemo, useState } from "react";
import { Card, Form, Spin, List, Typography } from "antd";
import { SelectInput, SimpleInput, Button } from "../../Common/components";
import {
  useCashTypes,
  useUpdatePayments,
  useUserWallet,
  usePaymentsSuggestion,
} from "../apiHooks";
import {_t} from "../../Common/components/InjectIntlContext";

const cardStyle = { height: "100%" };

enum WireTransferFieldsEnum {
  name = "name",
  address = "address",
  bankName = "bank",
  bankAddress = "bank_address",
  account = "account",
  swift = "swift",
}

const WireTransferFields = () => {
  return (
    <>
      <Form.Item name={WireTransferFieldsEnum.name} label={_t("name_surname")}>
        <SimpleInput />
      </Form.Item>
      <Form.Item name={WireTransferFieldsEnum.address} label={_t("legal_address")}>
        <SimpleInput />
      </Form.Item>
      <Form.Item name={WireTransferFieldsEnum.bankName} label={_t("bank_name")}>
        <SimpleInput />
      </Form.Item>
      <Form.Item
        name={WireTransferFieldsEnum.bankAddress}
        label={_t("bank_address")}
      >
        <SimpleInput />
      </Form.Item>
      <Form.Item
        name={WireTransferFieldsEnum.account}
        label={_t("bank_account")}
      >
        <SimpleInput />
      </Form.Item>
      <Form.Item name={WireTransferFieldsEnum.swift} label={_t("swift")}>
        <SimpleInput />
      </Form.Item>
    </>
  );
};

const DefaultFields = ({ label, example }) => {
  return (
    <Form.Item
      name="wallet"
      label={label}
      help={example && `Example: ${example}`}
    >
      <SimpleInput />
    </Form.Item>
  );
};

const wireTransferId = "5";
const convertPaymentDetails = (values) => {
  if (values.cash_type === wireTransferId) {
    const vals = [
      values[WireTransferFieldsEnum.name],
      values[WireTransferFieldsEnum.address],
      values[WireTransferFieldsEnum.bankName],
      values[WireTransferFieldsEnum.bankAddress],
      values[WireTransferFieldsEnum.account],
      values[WireTransferFieldsEnum.swift],
    ];

    return { cash_type: values.cash_type, wallet: vals.join(";") };
  }
  return values;
};

export const PaymentsForm = ({ cashTypes }) => {
  const [cashType, setCashType] = useState(wireTransferId);
  const [updatePayments, { isLoading }] = useUpdatePayments();
  const {
    data: suggestions,
    isLoading: isLoadingSuggestion,
  } = usePaymentsSuggestion({
    cash_type: cashType,
    wallet: "",
  });
  const handleSubmit = async (values) => {
    const data = convertPaymentDetails(values);
    return updatePayments(data);
  };

  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 8 }}
      size="large"
      wrapperCol={{ span: 16 }}
      initialValues={{
        cash_type: wireTransferId,
      }}
      style={cardStyle}
      onFinish={handleSubmit}
      onFieldsChange={(fields) => {
        const cashTypeField = fields.find((f) =>
          (f.name as any)?.includes("cash_type")
        );
        if (cashTypeField) {
          setCashType(cashTypeField.value);
        }
      }}
    >
      <Card
        title={_t("payment_details")}
        style={cardStyle}
        extra={
          <Form.Item name="cash_type" noStyle>
            <SelectInput
              style={{ width: 200 }}
              allowClear={false}
              options={cashTypes}
            />
          </Form.Item>
        }
      >
        <Spin spinning={isLoadingSuggestion}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const type = getFieldValue("cash_type");
              if (type === wireTransferId) {
                return <WireTransferFields></WireTransferFields>;
              }

              return (
                <DefaultFields
                  label={suggestions?.data?.notes}
                  example={suggestions?.data?.sample}
                />
              );
            }}
          </Form.Item>
          <Form.Item wrapperCol={{ style: { textAlign: "center" } }}>
            <Button
              loading={isLoading}
              type="primary"
              title={_t("Submit")}
              htmlType="submit"
            ></Button>
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  );
};

const UserWalletInfo = ({ data }) => {
  return (
    <Card title={_t("payment_details")} style={cardStyle}>
      <List
        footer={
          <Typography.Text type="secondary">
            {_t("payment_contact_admins")}
          </Typography.Text>
        }
      >
        <List.Item>
          <List.Item.Meta
            title={_t("payment_system")}
            description={data.cash_type_label}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={data.wallet_note}
            description={
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wallet.split("\n").join("<br />"),
                }} // todo
              ></div>
            }
          />
        </List.Item>
      </List>
    </Card>
  );
};

export const PaymentFormWidget = () => {
  const { data, isLoading } = useUserWallet();
  const { isLoading: isCashTypeLoading, data: cashTypesData } = useCashTypes();
  const cashTypes = useMemo(() => {
    return cashTypesData
      ? Object.keys(cashTypesData).map((key) => ({
          value: key,
          label: cashTypesData[key],
        }))
      : [];
  }, [cashTypesData]);

  if (isLoading || isCashTypeLoading)
    return <Spin style={{ height: "100%", width: "100%" }} />;

  return (
    <>
      {!data?.cash_type ? (
        <PaymentsForm cashTypes={cashTypes} />
      ) : (
        <UserWalletInfo data={data} />
      )}
    </>
  );
};
