import React, { useState, useEffect } from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form, Table } from "antd";
import { DatePicker } from "../../../Common/components/DatePicker";
import { useGamerReport } from "../../apiHooks";
import moment from "moment";
import { gamerProfileColumns } from "../../constants";
import { _t } from "../../../Common/components/InjectIntlContext";
import { NoData } from "../../../Common/components/NoData";
import { DateRangePicker } from "../../../../common/components";
import { setFormInputValue } from "../../../../common/helpers";
import { useForm } from "antd/es/form/Form";

const useTableData = ({ gamerId, projectId }) => {
  const pageSize = 50;
  const [filters, setFilters] = useState({
    date: { from: moment().subtract(1, "month"), to: moment() },
  });
  const [page, setPage] = useState(1);
  const { isLoading, data } = useGamerReport({
    gamer_id: gamerId,
    project_id: projectId,
    date_from: filters.date.from,
    date_to: filters.date.to,
    page,
    per_page: pageSize,
  } as any);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return { setFilters, filters, isLoading, data, page, setPage, pageSize };
};

export function StatsTable({ gamerId, projectId }) {
  const {
    setFilters,
    filters,
    isLoading,
    data,
    setPage,
    page,
    pageSize,
  } = useTableData({
    gamerId,
    projectId,
  });
  const tableColumns = gamerProfileColumns.map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  const [form] = useForm();

  return (
    <>
      <Filters
        onApply={setFilters}
        initialValues={filters}
        form={form}
        mainFilters={
          <Space size={22} direction="horizontal" align="center">
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Space>
        }
      />
      <Table
        loading={isLoading}
        columns={tableColumns}
        dataSource={data?.stats?.data}
        rowKey={(r, i) => i}
        pagination={{
          total: data?.stats.total,
          hideOnSinglePage: true,
          onChange: setPage,
          current: page,
          pageSize: pageSize,
          showSizeChanger: false,
          position: ["bottomCenter"],
        }}
        locale={{ emptyText: <NoData /> }}
      />
    </>
  );
}
