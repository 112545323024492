import React from "react";
import { Form, Select } from "antd";
import { useGetPriorities } from "./hooks";
import { TestimonialPrioritySelectType } from "./types";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const TestimonialPrioritySelect: React.FC<TestimonialPrioritySelectType> = ({
  initialValue,
}) => {
  const { data, isLoading } = useGetPriorities();
  const getOptions = () => {
    if (!isLoading) {
      return Object.keys(data?.data).map((item) => ({
        label: data?.data[item]["title"],
        value: `${data?.data[item]["priority"]}`,
      }));
    }
  };
  return (
    <Form.Item
      name="priority"
      initialValue={initialValue ? initialValue + "" : "2"}
      label={_t("testimonial_card_style_select")}
    >
      <Select options={getOptions()} loading={isLoading} />
    </Form.Item>
  );
};

export default TestimonialPrioritySelect;
