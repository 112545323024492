import React from "react";
import { Card, Divider } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { SpecialProjectsWidget } from "./specials/SpecialProjectsWidget";
import { SpecialProgramsWidget } from "./specials/SpecialProgramsWidget";
import { SpecialLandingPageWidget } from "./specials/SpecialLandingPageWidget";
import { PersonalProgramOptionsWidget } from "./specials/PersonalProgramOptionsWidget";

type SpecialsProps = {
  user: any;
};

export const SpecialsWidget = ({ user }: SpecialsProps) => {
  return (
    <>
      <Card title={_t("specials")}>
        <SpecialProjectsWidget user={user} />
        <Divider />
        <SpecialProgramsWidget user={user} />
        <Divider />
        <SpecialLandingPageWidget user={user} />
      </Card>
      <Divider />
      <Card title={_t("settings")}>
        <PersonalProgramOptionsWidget user={user} />
      </Card>
    </>
  );
};
