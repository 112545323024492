import { useMutation, useQueryCache } from "react-query";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { postBrandInfo } from "../api";
import { notify } from "../../../../system/utils/notification";
import { API_KEY_GET_BRAND_ITEM, API_KEY_GET_PROJECTS_LIST } from "../utils";

export const usePostBrandItemUpdate = (params) => {
  const queryCache = useQueryCache();
  const successMsg = _t("successfully_updated");

  return useMutation(postBrandInfo, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);
      queryCache.invalidateQueries(API_KEY_GET_BRAND_ITEM, params);
      queryCache.invalidateQueries(API_KEY_GET_PROJECTS_LIST);
    },
  });
};
