import React from "react";
import { useApiLogs } from "../apiHooks";
import { Spin, Row, Col } from "antd";

const ApiLogs: React.FC = () => {
  const { data = [], isLoading } = useApiLogs();

  return (
    <Spin spinning={isLoading}>
      <Row gutter={24} className={"api-log"}>
        {data.map((item) => (
          <Col className={"api-log__item"} span={12} key={item.timestamp}>
            {item.timestamp}
            <span> - {item.ip}</span>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default ApiLogs;
