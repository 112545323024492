import React from "react";
import { Modal, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { _t } from "../../../Common/components/InjectIntlContext";
import UpdatePromoForm from "./UpdatePromoForm";

import { useGetRawCode, useGetPromoTrackers } from "../../apiHooks";
import RawCodeBlock from "./RawCodeBlock";
import CopyToClipboard from "../../../Common/components/CopyToClipboard";

const ArchiveModal = ({ show, promo, setShow }) => {
  const close = () => setShow(false);
  const [
    updateRawCode,
    { isLoading: isUpdatingRawCode, data },
  ] = useGetRawCode();
  const {
    isLoading: isLoadingTrackers,
    data: trackersList,
  } = useGetPromoTrackers();

  return (
    <Modal
      width={650}
      title={_t("view_source")}
      closeIcon={
        <CloseOutlined
          className={"promo-archive__modal__close"}
          onClick={close}
        />
      }
      visible={show}
      footer={""}
      className={"promo-archive__modal"}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CopyToClipboard size={"md"} value={data}>
            <RawCodeBlock value={data} isLoading={isUpdatingRawCode} />
          </CopyToClipboard>
        </Col>
      </Row>
      <UpdatePromoForm
        trackersList={trackersList}
        isLoadingTrackers={isLoadingTrackers}
        promoItem={promo}
        updateRawCode={updateRawCode}
        closeModal={close}
      />
    </Modal>
  );
};

export default ArchiveModal;
