import React from "react";
import {Card, Row, Col, List} from "antd";
import {CurrencyLabel} from "../../Common/components";
import {_t} from "../../Common/components/InjectIntlContext";

const FullWidthRow = ({children}) => {
  return (
    <Row gutter={[10, 0]} style={{width: "100%"}}>
      {children}
    </Row>
  );
};

type FinancialPerformanceProps = {
  user: any;
}

export const FinancialPerformanceWidget = ({user}: FinancialPerformanceProps) => {
  return (
    <Card title={_t("financial_performance")}>
      <List
        size="small"
      >
        <List.Item>
          <FullWidthRow>
            <Col span={18}>{_t("balance_funds_to_pay")}</Col>
            <Col span={6}><CurrencyLabel value={100 * user.balance}/></Col>
          </FullWidthRow>
        </List.Item>
        <List.Item>
          <FullWidthRow>
            <Col span={18}>{_t("current_period")}</Col>
            <Col span={6}><CurrencyLabel value={100 * user.balance_current_period}/></Col>
          </FullWidthRow>
        </List.Item>
        <List.Item>
          <FullWidthRow>
            <Col span={18}>{_t("profit")}</Col>
            <Col span={6}><CurrencyLabel value={100 * user.profit}/></Col>
          </FullWidthRow>
        </List.Item>
        <List.Item>
          <FullWidthRow>
            <Col span={18}>{_t("payout")}</Col>
            <Col span={6}><CurrencyLabel value={100 * user.payouts}/></Col>
          </FullWidthRow>
        </List.Item>
      </List>
    </Card>
  );
};
