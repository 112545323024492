import React from "react";
import CasinoMockupLogoImg from "../../../../images/landings/brands_tab/Mockup_casino_slot.png";
import CasinoMockupDesktopLogoImg from "../../../../images/landings/brands_tab/Mockup_casino_slot--desktop.png";
import CasinoLogoImg from "../../../../images/landings/brands_tab/casino-slot_logo.png";
import CasinoLogoDesktopImg from "../../../../images/landings/brands_tab/casino_logo--desktop.png";
import {_t} from "../../../Common/components/InjectIntlContext";
import BrandTemplate, {BrandProps} from "./BrandTemplate";

const project = (_t: any): BrandProps => (
  {
    title: 'CasinoSlot',
    mockup_logo: CasinoMockupLogoImg,
    mockup_desktop_logo: CasinoMockupDesktopLogoImg,
    alt_mockup_logo: "casinoslot mockup",
    logo: CasinoLogoImg,
    logo_desktop: CasinoLogoDesktopImg,
    alt_logo: "casinoslot logo",
    path: "https://casinoslot.com",
    description: [
      _t('casinoslot_desc_1'),
      _t('casinoslot_desc_2'),
    ],
    project_values: {
      affiliate: _t("casinoslot_affiliate"),
      revshare: _t("casinoslot_revshare"),
      cpa: _t("casinoslot_cpa"),
      suppliers: _t("casinoslot_suppliers"),
      providers: _t("casinoslot_providers"),
      audience: _t("casinoslot_audience"),
      currencies: _t("casinoslot_currencies"),
      languages: _t("casinoslot_languages"),
      download_version: _t("casinoslot_download_version"),
      mobile_version: _t("casinoslot_mobile_version"),
    }
  }
);

const Casinoslot = () => {
  const projectTranslated = project(_t);
  return (
    <div>
      <BrandTemplate {...projectTranslated} />
    </div>
  );
};

export default Casinoslot;
