import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { AffiliateProgramForm } from "./components";
import { usePostProgramCreate } from "../hooks";

const AffiliateProgramEdit = () => {
  const { project_id, project_name } = useParams();
  const [createProgram] = usePostProgramCreate();

  return (
    <MainLayout>
      <PageTemplate title={`Create program for ${project_name}`}>
        <AffiliateProgramForm
          create
          project_id={project_id}
          sendProgram={createProgram}
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateProgramEdit;
