import React from "react";
import { Select } from "antd";
import { SelectProps, SelectValue } from "antd/lib/select";

export function RadioSelect<V extends SelectValue>({
  options,
  ...rest
}: SelectProps<V>) {
  return (
    <Select
      style={{ width: "100%" }}
      optionLabelProp="label"
      dropdownClassName="radio-select-dropdown"
      className="radio-select"
      {...rest}
    >
      {options?.map(({ value, key, label }) => (
        <Select.Option key={key} value={value} label={label}>
          <label className="radio-select-option-wrapper">
            <span className="radio-select-option-radio">
              <span className="radio-select-option-inner"></span>
            </span>
            <span className="radio-select-option-label" title={label as string}>
              {label}
            </span>
          </label>
        </Select.Option>
      ))}
    </Select>
  );
}
