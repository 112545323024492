import React, { useEffect, useMemo } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Button from "../../Common/components/Button";
import SelectInput from "../../Common/components/SelectInput";
import { Form, Spin } from "antd";
import { useAdminCreateUser, useAdminProjects } from "../apiHooks";
import { layoutUserCreateForm, tailLayoutUserCreateForm } from "../constants";

type UserCreateFormProps = {};

const UserCreateForm: React.FC<UserCreateFormProps> = () => {
  const [createUser, { isLoading }] = useAdminCreateUser();

  const {
    data: projects,
    isSuccess: projectsLoadingSuccess,
    isLoading: isLoadingProjects,
  } = useAdminProjects();
  const projectsOptions = useMemo(() => {
    return projectsLoadingSuccess
      ? projects.data.projects.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [];
  }, [projectsLoadingSuccess]);
  const initialValues = {};

  const [form] = Form.useForm();
  useEffect(() => {
    if (projectsLoadingSuccess) {
      form.setFieldsValue({
        projects:
          projects?.data.projects.length === 1
            ? [projects.data.projects[0].id]
            : [],
      });
    }
  }, [projectsLoadingSuccess]);

  return (
    <Spin spinning={isLoadingProjects}>
      <Form
        name="create_user_form"
        form={form}
        onFinish={createUser}
        {...layoutUserCreateForm}
        initialValues={initialValues}
      >
        <Form.Item
          label={_t("Login")}
          name="login"
          rules={[
            {
              required: true,
              message: _t("enter_login_message"),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label={_t("Password")}
          rules={[
            {
              required: true,
              message: _t("input_password_message"),
            },
            {
              min: 6,
              message: _t("min_password_message"),
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          label={_t("confirm_password")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: _t("confirm_password_message"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(_t("error_confirm_password_message"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="first_name" label={_t("first_name")}>
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="last_name" label={_t("last_name")}>
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="email"
          label={_t("E-mail")}
          rules={[
            {
              type: "email",
              message: _t("not_valid_email_message"),
            },
            {
              required: true,
              message: _t("input_email_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label={_t("Contacts")}
          rules={[
            { required: true, message: _t("Please input your contacts!") },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="projects" label={_t("projects")}>
          <SelectInput mode={"multiple"} options={projectsOptions} />
        </Form.Item>

        <Form.Item className="text-center" {...tailLayoutUserCreateForm}>
          <Button
            type="primary"
            title={_t("create")}
            htmlType="submit"
            disabled={isLoadingProjects || isLoading}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserCreateForm;
