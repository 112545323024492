import moment from "moment";
import { actionTypes } from "./actions";
import { dateKey, lineOptionsValues } from "./constants";
import { Filters } from "./hooks";

const getInitialDate = () => {
  let date;

  try {
    const savedDate = JSON.parse(localStorage.getItem(dateKey));
    if (savedDate !== null) {
      date = {
        from: moment(savedDate.from),
        to: moment(savedDate.to),
      };
    } else {
      date = {
        from: moment().startOf("month"),
        to: moment(),
      };
    }
  } catch (e) {
    console.log(e);
  }

  return date;
};

const initialState: Partial<Filters> = {
  date: getInitialDate(),
  rDate: {
    from: moment().subtract(1, "year"),
    to: moment(),
  } as any,
  columns: ["hits", "hosts"] as any[],
  period: "day",
  page_size: "100",
  totals: lineOptionsValues
    .filter((field) => !field.hidden)
    .map((field) => field.value),
  groupBy: "day",
  non_empty: true,
  page: 1,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        date: {
          ...state.date,
          ...getInitialDate(),
        },
        rDate: {
          ...state.rDate,
          from: moment().subtract(1, "year"),
          to: moment(),
        },
      };

    default:
      return state;
  }
}
