import { useMutation, useQueryCache } from "react-query";
import { postNotification } from "../api";
import {
  API_KEY_GET_MANAGEABLE_NOTIFICATION,
  API_KEY_GET_MANAGEABLE_NOTIFICATIONS,
} from "../constants";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../../common/helpers";

export const usePostNotification = (id?) => {
  const successMessage = _t("successfully_sent");
  const queryCache = useQueryCache();

  return useMutation(postNotification, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_MANAGEABLE_NOTIFICATION, id);
      queryCache.invalidateQueries(API_KEY_GET_MANAGEABLE_NOTIFICATIONS);
      notify("success", successMessage);
    },
  });
};
