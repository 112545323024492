import React, { useState } from "react";
import { Button, Dropdown } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import classNames from "classnames";

interface SettingsDropdownProps {
  dropContent: any;
  className?: string;
}

const SettingsDropdown: React.FC<SettingsDropdownProps> = ({
  dropContent,
  className,
}) => {
  const [settingOpen, setSettingOpen] = useState({ visible: false });
  const handleVisibleChange = (flag) => {
    setSettingOpen({ visible: flag });
  };

  return (
    <div className={classNames("settings-dropdown", className)}>
      <Dropdown
        overlay={dropContent}
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
        visible={settingOpen.visible}
      >
        <Button
          icon={<SettingOutlined />}
          size="large"
          shape="circle"
          className="settings-dropdown__btn"
        />
      </Dropdown>
    </div>
  );
};

export default SettingsDropdown;
