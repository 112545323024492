import React from "react";
import enFlag from "../../images/flags/locales/gb.svg";
import trFlag from "../../images/flags/locales/tr.svg";
import ptFlag from "../../images/flags/locales/pt.svg";
import jaFlag from "../../images/flags/locales/ja.svg";

export const DEFAULT_LOCALE = "en";
export const LOCALE_KEY = "locale";

export type translationData = {
  [key: string]: {
    [key: string]: string;
  };
};

export type translationResponse = {
  translations: translationData;
};

export type Language = {
  id: number;
  code: string;
  name: string;
};

export type LanguagesResponse = {
  languages: Language[];
  status: string;
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const flags = {
  en: enFlag,
  tr: trFlag,
  pt: ptFlag,
  ja: jaFlag,
};
