import React from "react";
import BettiltMockupLogoImg from "../../../../images/landings/brands_tab/Mockup_bettilt.png";
import BettiltMockupDesktopLogoImg from "../../../../images/landings/brands_tab/Mockup_bettilt--desktop.png";
import BettiltLogoImg from "../../../../images/landings/brands_tab/bettilt_logo.png";
import BettiltLogoDesktopImg from "../../../../images/landings/brands_tab/bettilt_logo--desktop.png";
import {_t} from "../../../Common/components/InjectIntlContext";
import BrandTemplate, {BrandProps} from "./BrandTemplate";

const project = (_t: any): BrandProps => (
  {
    title: 'Bettilt',
    mockup_logo: BettiltMockupLogoImg,
    mockup_desktop_logo: BettiltMockupDesktopLogoImg,
    alt_mockup_logo: "bettilt mockup",
    logo: BettiltLogoImg,
    logo_desktop: BettiltLogoDesktopImg,
    alt_logo: "bettilt logo",
    path: "https://bettilt.com",
    description: [
      _t('bettilt_desc_1'),
      _t('bettilt_desc_2'),
      _t('bettilt_desc_3'),
      _t('bettilt_desc_4'),
      _t('bettilt_desc_5'),
    ],
    project_values: {
      affiliate: _t("bettilt_affiliate"),
      revshare: _t("bettilt_revshare"),
      cpa: _t("bettilt_cpa"),
      suppliers: _t("bettilt_suppliers"),
      providers: _t("bettilt_providers"),
      audience: _t("bettilt_audience"),
      currencies: _t("bettilt_currencies"),
      languages: _t("bettilt_languages"),
      download_version: _t("bettilt_download_version"),
      mobile_version: _t("bettilt_mobile_version"),
    }
  }
);
const Bettilt = () => {
  const projectTranslated = project(_t);
  return (
    <div>
      <BrandTemplate {...projectTranslated} />
    </div>
  );
};

export default Bettilt;
