import { useQuery } from "react-query";
import { getCountriesList } from "./api";
import { API_ROUTES } from "../../../../../../system/constants/apiRoutes";

export const useGetCountriesList = () => {
  return useQuery(
    [API_ROUTES.reports.filters.affiliates_geo_countries.key],
    () =>
      getCountriesList(API_ROUTES.reports.filters.affiliates_geo_countries.path)
  );
};
