import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import PromoSteps from "../components/create/PromoSteps";

const Create = () => {
  return (
    <PageTemplate title={_t("create_new_promo")} additionalClass="promo-create">
      <PromoSteps />
    </PageTemplate>
  );
};

export default Create;
