import { useMutation } from "react-query";
import { removeEmpty } from "../../../../../modules/Dashboard/apiHooks";
import { getReportFile } from "../../api";

export const useGetReportFile = (api, fileName, params) => {
  params = removeEmpty(params);
  return useMutation(() =>
    getReportFile(
      api,
      {
        export: "xls",
        ...params,
      },
      fileName
    )
  );
};
