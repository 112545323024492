import React from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { NotificationContentCard } from "../../components";
import { useGetNotification, usePostNotification } from "../../hooks";
import { addTranslationValue } from "../../../../../../common/helpers/addTranslationValue";
import { Spin } from "antd";

const NotificationEdit = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetNotification(id);
  const { languages } = useSelector((state) => state.locale);
  const [
    editNotification,
    { isLoading: isPostNotificationLoading },
  ] = usePostNotification(id);
  const handleSubmit = (values) => {
    editNotification({
      ...values,
      titles: addTranslationValue(languages, values, "titles"),
      texts: addTranslationValue(languages, values, "texts"),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading || isPostNotificationLoading}>
          {data && (
            <NotificationContentCard
              initialValues={data.data}
              handleSubmit={handleSubmit}
              isEdit
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default NotificationEdit;
