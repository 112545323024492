import React, { useEffect, useState } from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { Route } from "react-router-dom";
import { General } from "../pages/General";
import { Commissions } from "../pages/Commissions";
import { Promos } from "../pages/Promos";
import { Trackers } from "../pages/Trackers";
import { Landings } from "../pages/Landings";
import { SubId } from "../pages/SubID";

import MainLayout from "../../Layouts/MainLayouts";
import { statAction } from "../../Auth/actions";
import { useDispatch } from "react-redux";
import { Gamers } from "../pages/Gamers";
import { ClickId } from "../pages/ClickID";
import { Button, Spin, Typography } from "antd";
import { GamerProfile } from "../pages/GamerProfile";
import { _t } from "../../Common/components/InjectIntlContext";
import { API_GLOBAL_NOTIFICATIONS } from "../constants";
import { GlobalNotification } from "../../../pages/inner/components";
import {
  getItem,
  removeItem,
} from "../../../system/helpers/localstorageHelper";
import { DeleteOutlined } from "@ant-design/icons";
import { useGetDataWithoutParams } from "../../../common/hooks";
import { AdminDashboard } from "../../../pages/inner";

const links = (_t: any) => [
  {
    title: _t("admin_dashboard"),
    path: APP_ROUTES.dashboard.admin,
    exact: true,
    isHidden: getItem("role") !== "admin",
  },
  {
    title: _t("General"),
    path: APP_ROUTES.dashboard.general,
    exact: true,
  },
  {
    title: _t("Commissions"),
    path: APP_ROUTES.dashboard.commissions,
  },
  {
    title: _t("Promo"),
    path: APP_ROUTES.dashboard.promo,
  },
  // {
  //   title: _t("Trackers"),
  //   path: APP_ROUTES.dashboard.trackers,
  // },
  {
    title: _t("Sub Id"),
    path: APP_ROUTES.dashboard.sub_id,
  },
  {
    title: _t("Click Id"),
    path: APP_ROUTES.dashboard.click_id,
  },
  {
    title: _t("Landings"),
    path: APP_ROUTES.dashboard.landings,
  },
  {
    title: _t("Gamers"),
    path: APP_ROUTES.dashboard.gamers,
  },
];

export default function Dashboard() {
  const [isShowNotifications, setIsShowNotifications] = useState(
    !!getItem("isShowNotifications")
  );
  const {
    data: notificationsList,
    isLoading: notificationsListLoading,
  } = useGetDataWithoutParams(
    API_GLOBAL_NOTIFICATIONS.key,
    API_GLOBAL_NOTIFICATIONS.path,
    isShowNotifications
  );
  const translatedLinks = links(_t).filter((item) => !item.isHidden);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(statAction);
  }, []);

  const handleHideNotifications = () => {
    removeItem("isShowNotifications");
    setIsShowNotifications(false);
  };
  return (
    <MainLayout headerLinks={translatedLinks}>
      {isShowNotifications && notificationsList?.notifications.length > 0 && (
        <>
          <div style={{ textAlign: "center", marginBottom: "25px" }}>
            <Button
              onClick={handleHideNotifications}
              shape="round"
              size="large"
              icon={<DeleteOutlined />}
            >
              {_t("clear_all_notifications")}
            </Button>
          </div>
          {notificationsList.notifications.map((item) => (
            <GlobalNotification
              titles={item.titles}
              texts={item.texts}
              styleType={item.style_name}
              key={item.id}
            />
          ))}
        </>
      )}
      <Route
        render={(props) => {
          const item = translatedLinks.find(
            (l) => l.path === props.location.pathname
          );
          return item ? (
            <Typography.Title style={{ marginBottom: 20 }} level={4}>
              {item.title}
            </Typography.Title>
          ) : null;
        }}
      ></Route>
      {getItem("role") === "admin" && (
        <Route
          exact
          path={APP_ROUTES.dashboard.admin}
          component={AdminDashboard}
        />
      )}
      <Route exact path={APP_ROUTES.dashboard.general} component={General} />
      <Route path={APP_ROUTES.dashboard.commissions} component={Commissions} />
      {/* </RolesWrapper> */}
      <Route path={APP_ROUTES.dashboard.promo} component={Promos} />
      <Route path={APP_ROUTES.dashboard.trackers} component={Trackers} />
      <Route path={APP_ROUTES.dashboard.sub_id} component={SubId} />
      <Route path={APP_ROUTES.dashboard.click_id} component={ClickId} />
      <Route path={APP_ROUTES.dashboard.landings} component={Landings} />
      <Route path={APP_ROUTES.dashboard.gamers} component={Gamers} />
      <Route
        path={APP_ROUTES.dashboard.gamer_profile}
        component={GamerProfile}
      />
    </MainLayout>
  );
}
