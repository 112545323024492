import * as actionTypes from "./constants";
import { generateCSVKeyResponce } from "./api";

const USER_BASIC_URL = "/api/users/information";
const GENERATE_CSV_URL = "/api/users/generate-csv-key";

export const getUserBasic = (data) => (dispatch) =>
  dispatch({
    types: [
      actionTypes.USER_BASIC_START,
      actionTypes.USER_BASIC_SUCCESS,
      actionTypes.USER_BASIC_FAIL,
    ],
    request: {
      url: USER_BASIC_URL,
      data: data,
      method: "POST",
    },
  });

export const generateCSVKey = () => (dispatch: any) =>
  dispatch({
    types: [
      actionTypes.GENERATE_CSV_KEY_START,
      actionTypes.GENERATE_CSV_KEY_SUCCESS,
      actionTypes.GENERATE_CSV_KEY_FAIL,
    ],
    request: {
      url: GENERATE_CSV_URL,
      method: "GET",
      notifyMessage: {
        error: "errorMessage",
      },
    },
  }).then((data: generateCSVKeyResponce) => data.payload);
