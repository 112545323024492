import React from "react";
import { Typography, Space } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";

const { Title } = Typography;

export function GamerProfileHeader({ id }) {
  return (
    <Space size={10}>
      <Title style={{ marginBottom: 0 }} level={3}>
        {_t("Gamer Profile")}
      </Title>
      <Title style={{ marginBottom: 0 }} level={4} type="secondary">
        ID:{id}
      </Title>
    </Space>
  );
}
