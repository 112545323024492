import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Form, Input, Row, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { TranslationContentCardType } from "./types";

const TranslationCard: React.FC<TranslationContentCardType> = ({
  initialValues,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { languages } = useSelector((state) => state.locale);

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>
          {_t("translation_card_title")}
        </Typography.Title>
      </div>
      <Form onFinish={handleSubmit} layout="vertical" form={form}>
        <Form.Item name="key_id" initialValue={initialValues?.key_id} />
        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {_t("translation_card_key")}: {initialValues?.key}
        </Typography.Title>
        <Form.Item
          name="default_message"
          label={_t("translation_placeholder_default_message")}
          initialValue={initialValues?.key_default_message}
          rules={[
            {
              required: true,
              message: _t("default_message_field_required"),
            },
          ]}
        >
          <Input placeholder={_t("translation_placeholder_default_message")} />
        </Form.Item>
        <Divider />
        <Row>
          <Col>
            <Typography.Title level={5} style={{ marginBottom: "15px" }}>
              {_t("translation_card_languages_title")}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={14}>
          {languages &&
            Object.values(languages).map((item: any) => (
              <Col lg={12} span={24} key={item.id}>
                <Form.Item
                  name={`translations[${item.code}][locale]`}
                  initialValue={item.code}
                />
                <Form.Item
                  name={`translations[${item.code}][translation]`}
                  label={item.name}
                  initialValue={
                    initialValues?.translations[item.code]?.translation
                  }
                  rules={[
                    {
                      required: true,
                      message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input
                    placeholder={_t("translation_placeholder_translation")}
                  />
                </Form.Item>
              </Col>
            ))}
        </Row>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TranslationCard;
