import {
  getGeneralReport,
  GeneralReportRequest,
  getChartData,
  getPartnerBalance,
  getReportForDay,
  GetReportForDayRequest,
  getGamerProfile,
  getGamerReport,
  GamerReportRequest,
  GetGamerProfileRequest,
  getGamersCharts,
  getGamersReport,
  getGamersTotals,
  GamersRequest,
  GamersChartRequest,
  getPromoForSelect,
  getGeneralReportExport,
  getStatsUpdateTime,
} from "./api";
import { useMutation, useQuery } from "react-query";
import { getUserId } from "../../system/helpers/localstorageHelper";
import { isMoment } from "moment";

const GeneralReportList = "GeneralReportList";
const ChartData = "ChartData";
const PartnerBalanceData = "PartnerBalanceData";
const ReportByDay = "ReportByDay";
const GamerProfile = "GamerProfile";
const GamerReport = "GamerReport";
const GamersReport = "GamersReport";
const GamersCharts = "GamersCharts";
const GamersTotals = "GamersCharts";
const PromoKey = "PromoKey";
export const StatsUpdateTime = "StatsUpdateTime";

export const removeEmpty = (params) => {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }

    if (isMoment(params[key])) {
      params[key] = params[key]?.format("YYYY-MM-DD");
    }
  });

  return params;
};

export const useGeneralReportList = (
  params: Omit<GeneralReportRequest, "user_id">
) => {
  params = removeEmpty(params);

  return useQuery([GeneralReportList, params, getUserId()], (k, params) =>
    getGeneralReport({
      user_id: getUserId(),
      scope: "by-days",
      from: "2019-09-01",
      to: "2020-08-24",
      ...params,
    })
  );
};

export const useGeneralReportListFile = (params: any) => {
  params = removeEmpty(params);
  return useMutation(() =>
    getGeneralReportExport({
      export: "xls",
      user_id: getUserId(),
      scope: "by-days",
      from: "2019-09-01",
      to: "2020-08-24",
      ...params,
    })
  );
};

export const useChartData = (params: Omit<GeneralReportRequest, "user_id">) => {
  params = removeEmpty(params);
  return useQuery([ChartData, params], (k, params) =>
    getChartData({
      user_id: getUserId(),
      from: "2019-09-01",
      to: "2020-08-24",
      ...params,
    })
  );
};
export const usePartnerBalanceData = () => {
  return useQuery([PartnerBalanceData], (k) =>
    getPartnerBalance({
      user_id: getUserId(),
    })
  );
};
export const useReportForDay = (params: Partial<GetReportForDayRequest>) => {
  return useQuery([ReportByDay, params], (k, params) =>
    getReportForDay({
      user_id: getUserId(),
      ...params,
    } as any)
  );
};

export const useGamerProfile = (params: GetGamerProfileRequest) => {
  return useQuery([GamerProfile, params], (k, params) =>
    getGamerProfile({
      ...params,
    } as any)
  );
};

export const useGamerReport = (params: GamerReportRequest) => {
  return useQuery([GamerReport, params], (k, params) => getGamerReport(params));
};

export const useGamersReport = (params: GamersRequest) => {
  params = removeEmpty(params);
  return useQuery([GamersReport, params], (k, params) =>
    getGamersReport({
      from: "2019-09-01",
      to: "2020-08-24",
      ...params,
    })
  );
};
export const useGamersTotals = (params: GamersRequest) => {
  params = removeEmpty(params);
  return useQuery([GamersTotals, params], (k, params) =>
    getGamersTotals({
      from: "2019-09-01",
      to: "2020-08-24",
      ...params,
    })
  );
};
export const useGamersChartData = (params: GamersChartRequest) => {
  params = removeEmpty(params);
  return useQuery([GamersCharts, params], (k, params) =>
    getGamersCharts({
      ...params,
    })
  );
};

export const usePromoForSelect = (params: { code?: string }) => {
  return useQuery([PromoKey, params], (k, params) => getPromoForSelect(params));
};

export const useStatsUpdateTime = () => {
  return useQuery([StatsUpdateTime], () => getStatsUpdateTime(), {
    refetchInterval: 60000,
  });
};
