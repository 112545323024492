import { useQuery } from "react-query";
import { getAffiliateGeoDetails, getCountriesList } from "./api";
import { API_ROUTES } from "../../../../../../system/constants/apiRoutes";

export const useGetCountriesList = () => {
  return useQuery(
    [API_ROUTES.reports.filters.affiliates_geo_countries.key],
    () =>
      getCountriesList(API_ROUTES.reports.filters.affiliates_geo_countries.path)
  );
};
export const useGetAffiliateGeoDetail = (params) => {
  return useQuery([API_ROUTES.reports.affiliates_geo_details.key, params], () =>
    getAffiliateGeoDetails(
      API_ROUTES.reports.affiliates_geo_details.path,
      params
    )
  );
};
