import React from "react";
import { Button, Form } from "antd";
import CheckboxInput from "../../../../../modules/Common/components/CheckBoxInput";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { SimpleInput } from "../../../../../modules/Common/components";

export const FiltersWidget = ({ onSubmit, initialValues }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onSubmit}
      className="translation-list-filters"
    >
      <Form.Item name="search">
        <SimpleInput
          placeholder={_t("search")}
          className="translation-list-filters__input"
        />
      </Form.Item>
      <Form.Item name="empty" valuePropName="checked">
        <CheckboxInput
          title={_t("empty")}
          className="translation-list-filters__input"
        />
      </Form.Item>
      <Button size="large" shape="round" type="primary" htmlType="submit">
        {_t("search")}
      </Button>
    </Form>
  );
};
