import React from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row, Select } from "antd";
import { DatePicker } from "../../../../../../../modules/Common/components/DatePicker";
import {
  DateRangePicker,
  ProgramsSelect,
} from "../../../../../../../common/components";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import {
  createOptions,
  setFormInputValue,
} from "../../../../../../../common/helpers";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const [form] = useForm();
  const sortSelectOptions = (data) => {
    return data.map((item) => ({
      value: item,
      label: _t(item),
    }));
  };
  return (
    <Filters
      form={form}
      onApply={setFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <ProgramsSelect />
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name="field"
              initialValue="deposits"
              label={_t("geo_data")}
            >
              <Select
                options={sortSelectOptions([
                  "first_deposits",
                  "partner_income",
                  "deposits",
                  "withdraws",
                  "ng",
                  "comppoints",
                  "regs",
                ])}
              />
            </Form.Item>
          </Col>
          <Col>{excelButton}</Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
