import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { FaqCard } from "../../components";
import { useGetFaq, usePostFaq } from "../../hooks";
import { Spin } from "antd";
import { fillTranslations } from "../../helpers";

const FaqEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetFaq(id);
  const [editFaq, { isLoading: isPostFaqLoading }] = usePostFaq(id);
  const { languages } = useSelector((state) => state.locale);
  const handleSubmit = (values) => {
    editFaq({
      ...values,
      translations: fillTranslations(languages, values),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading || isPostFaqLoading}>
          {data && (
            <FaqCard
              initialValues={data.data}
              handleSubmit={handleSubmit}
              isEdit
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FaqEdit;
