import React from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { useGetManageableList } from "../../hooks";
import { ManageableListItem } from "./components";
import { Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";

const ManageableList = () => {
  const { data, isLoading } = useGetManageableList();
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate title={_t("manageable_list")}>
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => history.push(APP_ROUTES.manage.manageable_list_create)}
        >
          {_t("create_new_manageable_list")}
        </Button>
        <Spin spinning={isLoading}>
          {data &&
            data?.list.map((item) => (
              <ManageableListItem listInfo={item} key={item.id} />
            ))}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableList;
