import React from "react";
import { PageTemplate } from "./PageTemplate";
import { GamerProfileHeader } from "../components/GamerProfile/Header";
import { StatsTable } from "../components/GamerProfile/StatsTable";
import { SummaryInfo } from "../components/GamerProfile/SummaryInfo";
import { useParams } from "react-router-dom";

export function GamerProfile() {
  const { gamerId, projectId } = useParams();
  return (
    <PageTemplate>
      <GamerProfileHeader id={gamerId} />
      <SummaryInfo gamerId={gamerId} projectId={projectId} />
      <StatsTable gamerId={gamerId} projectId={projectId} />
    </PageTemplate>
  );
}
