export const getColPerRow = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const xxxl = width >= 2560 && width < 10000;
  const xxl = width >= 1920 && width < 2560;
  const xl = width >= 1440 && width < 1920;
  const lg = width >= 1024 && width < 1440;
  const md = width >= 768 && width < 1024;
  const xs = width >= 568 && width < 768;

  if (xxxl) return 12;
  if (xxl) return 7;
  if (xl) return 5;
  if (lg) return 4;
  if (md) return 3;
  if (xs) return 1;

  return 5;
};
