import { useSelector, useDispatch } from "react-redux";
import { updateDashboardFilters } from "./actions";
import { ColumnOption } from "./constants";

export type Filters = {
  date: {
    from: string;
    to: string;
  };
  rDate: {
    from: string;
    to: string;
  };
  non_empty: boolean;
  columns: ColumnOption[];
  period: string;
  page_size: string;
  program_id: string;
  promo_id: string;
  tracker_id: string;
  page: number;
  totals: string[];
  groupBy: "day" | "total";
  export: "xls";
  sub_id: string;
  sub_id2: string;
};

export const useFilters = () => {
  const filters = useSelector((state: { filters }) => state.filters) as Filters;
  const dispatch = useDispatch();
  const updateFilters = (v: Partial<Filters>) =>
    dispatch(updateDashboardFilters(v));

  return [filters, updateFilters] as const;
};
