import axiosClient from "../../../../../system/helpers/axiosConfig";
import { messages } from "../../../../../system/constants/constants";

export const postMergeTranslations = () => {
  return axiosClient
    .post("/api/translations/merge", { translations: messages })
    .then((res) => {
      return res.data;
    });
};
