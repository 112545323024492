import React from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { Route } from "react-router-dom";
import Archive from "../pages/Archive";
import Create from "../pages/CreatePromo";
import Order from "../pages/Order";
import MainLayout from "../../Layouts/MainLayouts";
import { _t } from "../../Common/components/InjectIntlContext";

export default function Promo() {
  const links = [
    {
      title: _t("create"),
      path: APP_ROUTES.promo.create,
    },
    {
      title: _t("promo_archive"),
      path: APP_ROUTES.promo.archive,
    },
    {
      title: _t("promo_order"),
      path: APP_ROUTES.promo.order,
    },
  ];
  return (
    <MainLayout headerLinks={links}>
      <Route exact path={APP_ROUTES.promo.create} component={Create} />
      <Route path={APP_ROUTES.promo.archive} component={Archive} />
      <Route path={APP_ROUTES.promo.order} component={Order} />
    </MainLayout>
  );
}
