export const addAllOption = (options: any[] | null) =>
  Array.isArray(options) ? [{ label: "All", value: null }].concat(options) : [];

export const createPromoOptions = (data) => {
  let options = [];
  if (data?.promos) {
    options = Object.keys(data?.promos).map((key) => {
      const program = data?.promos[key][0].program;
      return {
        label: `${program?.name} (${program?.project?.name})`,
        children: data?.promos[key].map((promo) => ({
          label: `${promo.name} ${promo.name ? "-" : ""} ${promo.code}`,
          value: promo.id,
          search: promo.name + "-" + promo.code + '-' + program?.name + '-' + program?.project?.name,
        })),
      };
    });
  }

  return addAllOption(options);
};
