import { getAvailableLangAction, getTranslationsAction } from "./apiActions";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";

const LanguagesList = "languages-list";

export const useGetTranslations = () => {
  const dispatch = useDispatch();
  return useMutation((locale: string = "en") =>
    getTranslationsAction(locale)(dispatch)
  );
};

export const useGetLanguages = () => {
  const dispatch = useDispatch();
  return useQuery(LanguagesList, () => getAvailableLangAction()(dispatch));
};
