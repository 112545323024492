import React from "react";
import { ReportPageWrapper } from "../../components";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { ReportPage } from "../../components/ReportPage";
import { config } from "./config";
import { ReportFilters } from "./components";
import useGetAdditionalFilterOptions from "../../hooks/table/useGetAdditionalFilterOptions";

const AffiliatesGeoReport: React.FC = () => {
  const [additionalFilterOptions, isSuccess] = useGetAdditionalFilterOptions();
  return (
    <ReportPageWrapper title={_t("affiliates_geo_report")}>
      {isSuccess && (
        <ReportPage
          config={{ ...config, additionalFilterOptions }}
          ReportFilters={ReportFilters}
        />
      )}
    </ReportPageWrapper>
  );
};

export default AffiliatesGeoReport;
