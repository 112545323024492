import { createColumnFilter } from "./createColumnFilter";
import { filterOptionsTypes } from "../../constants";

export const createColumnsFilters = (
  columns?: string[],
  additionalFilterOptions?: any | undefined
) => {
  const specialOptions = [
    "status_label",
    "role_label",
    "traffic_type_label",
    "cash_type_label",
  ];

  return Object.assign(
    {},
    ...columns.map((key) => {
      const placeholder = filterOptionsTypes.numbers.includes(key)
        ? ">="
        : "Search...";
      const getSearchFiled = (key) => {
        switch (key) {
          case "status_label":
            return "statuses";
          case "role_label":
            return "role";
          case "traffic_type_label":
            return "traffic_type";
          case "cash_type_label":
            return "cash_type";
        }
      };
      if (specialOptions.includes(key)) {
        return {
          [key]: createColumnFilter(
            key,
            placeholder,
            true,
            getSearchFiled(key),
            additionalFilterOptions[key]
          ),
        };
      } else {
        return {
          [key]: createColumnFilter(key, placeholder),
        };
      }
    })
  );
};
