import { useMutation, useQueryCache } from "react-query";
import { postTranslationKey } from "../api";
import { API_KEY_GET_TRANSLATION_LIST } from "../constants";

export const usePostTranslationKey = () => {
  const queryCache = useQueryCache();
  return useMutation(postTranslationKey, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_TRANSLATION_LIST);
    },
  });
};
