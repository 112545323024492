export const API_KEY_GET_PROJECTS_LIST = "get-affiliate-projects-list";
export const API_KEY_GET_BRAND_ITEM = "get-affiliate-brand-item";
export const API_KEY_GET_AFFILIATE_PROGRAMS = "get-affiliate-programs";
export const API_KEY_GET_PROGRAM_STATUSES = "get-affiliate-program-statuses";
export const API_KEY_GET_PROGRAM_TYPES = "get-affiliate-program-types";
export const API_KEY_GET_PROGRAM = "get-affiliate-program-info";
export const API_KEY_GET_LANDING_PAGES_LIST =
  "get-affiliate-landing-pages-list";
export const API_KEY_GET_LANDING_PAGE_INFO = "get-affiliate-landing-page-info";
export const API_KEY_GET_LANDING_PAGE_LANGUAGES =
  "get-affiliate-landing-page-languages";
export const API_KEY_GET_LANDING_PAGE_STATUSES =
  "get-affiliate-landing-page-statuses";
export const API_KEY_GET_LANDING_PAGE_TITLE_LANGUAGES =
  "get-affiliate-landing-page-title-languages";
