import React from "react";
interface ErrorTemplateProps {
  children?: React.ReactNode;
}
const ErrorTemplate: React.FC<ErrorTemplateProps> = ({ children }) => {
  return (
    <div className="error-page">
      <div className="error-page__content">{children}</div>
    </div>
  );
};

export default ErrorTemplate;
