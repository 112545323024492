import React from "react";
import { Select } from "antd";
import { usePostBrandProgramStatusUpdate } from "../../hooks";

const AffiliateProgramStatusSelect = ({
  defaultValue,
  id,
  projectId,
  projectStatus,
}) => {
  const { Option } = Select;
  const [updateStatus] = usePostBrandProgramStatusUpdate({
    project_id: projectId,
    status: projectStatus,
  });

  const handleStatusUpdate = (value) => {
    updateStatus({
      program_id: id,
      status: value,
    });
  };

  return (
    <Select defaultValue={"" + defaultValue} onChange={handleStatusUpdate}>
      <Option value="1">Active</Option>
      <Option value="2">Special</Option>
      <Option value="3">Public special</Option>
      <Option value="0">Disabled</Option>
    </Select>
  );
};

export default AffiliateProgramStatusSelect;
