import { useSelector, useDispatch } from "react-redux";
import { updateCostsFilters } from "./actions";

type Filters = {
  project_id: "";
  promo_id: "";
  type: "";
  page: 0;
};

export const useFilters = () => {
  const filters = useSelector(
    (state: { promo }) => state.promo.filters
  ) as Filters;
  const dispatch = useDispatch();
  const updateFilters = (v: Partial<Filters>) =>
    dispatch(updateCostsFilters(v));

  return [filters, updateFilters] as const;
};
