import React, { useRef } from "react";
import { ReactComponent as Copy } from "../../../images/icons/copy.svg";
import classNames from "classnames";
import Input from "antd/lib/input";

type CopyToClipboardProps = {
  size?: string;
  value: string | number;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  children,
  size,
  value,
}) => {
  const inputEl = useRef(null);
  const copy = () => {
    inputEl.current.select();
    document.execCommand("copy");
  };
  const handleCopy = (text) => navigator.clipboard.writeText(text);

  return (
    <div className={"clipboard-copy"}>
      {children}
      <Input className={"clipboard-copy__input"} ref={inputEl} value={value} />
      <Copy
        onClick={() => handleCopy(value)}
        className={classNames(
          "clipboard-copy__icon",
          size && `clipboard-copy__icon--${size}`
        )}
      />
    </div>
  );
};

export default CopyToClipboard;
