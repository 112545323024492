import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Input, Select, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { TranslationImportExcelCardType } from "./types";
import { getLocale } from "../../../../../../system/helpers/localstorageHelper";
import {
  createOptions,
  setFormInputValue,
} from "../../../../../../common/helpers";
import { UploadOutlined } from "@ant-design/icons";

const TranslationImportExcelCard: React.FC<TranslationImportExcelCardType> = ({
  handleSubmit,
}) => {
  const { languages } = useSelector((state) => state.locale);
  const [fileName, setFileName] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();
  const initialValues = {
    lang: getLocale(),
  };

  const uploadFile = (files) => {
    const file = files[0];
    if (!file) return false;
    setFileName(file.name);
    setFormInputValue("file", file, form);
  };

  const sendForm = (values) => {
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }
    handleSubmit(formData);
  };

  const pageTitle = _t("import_translations");

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{pageTitle}</Typography.Title>
      </div>
      <Form onFinish={sendForm} layout="vertical" form={form}>
        <Form.Item name="lang" initialValue={initialValues?.lang}>
          <Select options={createOptions(languages, "name", "code")} />
        </Form.Item>

        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {fileName}
        </Typography.Title>
        <Form.Item name="file">
          <Input.Group>
            <label className="upload-button" style={{ marginTop: "0" }}>
              <input
                type="file"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  uploadFile(e.target.files)
                }
              />
              <span className="ant-btn ant-btn-round">
                <UploadOutlined />
                {_t("excel_file")}
              </span>
            </label>
          </Input.Group>
        </Form.Item>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TranslationImportExcelCard;
