import React, { useEffect } from "react";
import { Checkbox, Spin } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { ReactComponent as Refresh } from "../../../images/icons/refresh.svg";
import Button from "../../Common/components/Button";
import classNames from "classnames";
import { _t } from "../../Common/components/InjectIntlContext";

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 24 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 7 },
  },
};

type CSVSettingsProps = {
  onGenerateKey: any;
  getCSVAccess: any;
  updateCSVSettings: any;
  isUpdateKey: boolean;
  isUpdateAccess: boolean;
  isUpdateCSVSettings: boolean;
  csv_key: string;
  csv_allowed_ips: string | [];
  csv_settings: number;
};

const CSVSettings: React.FC<CSVSettingsProps> = ({
  isUpdateKey,
  onGenerateKey,
  csv_allowed_ips = [],
  csv_key,
  getCSVAccess,
  isUpdateAccess,
  csv_settings,
  updateCSVSettings,
  isUpdateCSVSettings,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [csv_key, csv_settings, csv_allowed_ips]);

  const onFinish = (values) => {
    const data = {
      csv_settings: values.csv_settings ? 2 : 1,
      csv_allowed_ips: values.csv_allowed_ips,
    };
    updateCSVSettings(data);
  };

  if (csv_settings === 0) {
    return (
      <div className={"fx-center"}>
        <Button
          type="primary"
          title={_t("Request CSV Key")}
          htmlType="submit"
          onClick={getCSVAccess}
          disabled={isUpdateAccess}
        />
      </div>
    );
  }

  const translate = {
    your_key: _t("your_key"),
    allowed_IPs: _t("allowed_IPs"),
    fieldsErrors: _t("Please input IP or delete this field"),
  };

  return (
    <Spin spinning={false}>
      <Form
        {...layout}
        initialValues={{
          csv_allowed_ips,
          csv_key,
          csv_settings: csv_settings === 2,
        }}
        form={form}
        labelAlign={"left"}
        name="user_csv_form"
        onFinish={onFinish}
        className={"csv-settings"}
      >
        <Form.Item valuePropName="checked" name={"csv_settings"}>
          <Checkbox>{_t("enable_CSV_key")}</Checkbox>
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => {
            const csv_settings_value = form.getFieldsValue().csv_settings;
            return (
              <>
                <Form.Item
                  {...layout}
                  label={translate.your_key}
                  name={"csv_key"}
                >
                  <Input
                    disabled
                    addonAfter={
                      <div
                        className={classNames(
                          "csv-settings__refresh",
                          isUpdateKey && "disabled",
                          csv_settings !== 2 && "disabled",
                          !csv_settings_value && "disabled"
                        )}
                        onClick={onGenerateKey}
                      >
                        <Refresh style={{ lineHeight: "normal" }} />
                      </div>
                    }
                  />
                </Form.Item>
                <Form.List name="csv_allowed_ips">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Form.Item
                            label={index === 0 ? translate.allowed_IPs : ""}
                            required={false}
                            key={field.key}
                            {...(index === 0
                              ? layout
                              : formItemLayoutWithOutLabel)}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: translate.fieldsErrors,
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                disabled={!csv_settings_value}
                                addonAfter={
                                  index === 0 ? (
                                    <PlusOutlined
                                      className={classNames(
                                        !csv_settings_value && "disabled"
                                      )}
                                      onClick={() => {
                                        add("");
                                      }}
                                    />
                                  ) : (
                                    <MinusOutlined
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    />
                                  )
                                }
                              />
                            </Form.Item>
                          </Form.Item>
                        ))}
                      </div>
                    );
                  }}
                </Form.List>
              </>
            );
          }}
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            type="primary"
            title={_t("Apply")}
            disabled={isUpdateCSVSettings}
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

CSVSettings.defaultProps = {
  csv_allowed_ips: [],
};

export default CSVSettings;
