import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Spin, Typography } from "antd";
import {
  useDenySpecialProgramOption,
  useSpecialProgramOptions,
} from "../../apiHooks";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Tag } from "antd";
import AddSpecialOptionForm from "../forms/AddSpecialOptionForm";

const { Text } = Typography;

type SpecialsProps = {
  user: any;
};

export const PersonalProgramOptionsWidget = ({ user }: SpecialsProps) => {
  const {
    data: options,
    isLoading: isLoadingOptions,
  } = useSpecialProgramOptions({
    user_id: user.id,
  });

  const amountText = _t("amount");
  const [denySpecial] = useDenySpecialProgramOption();
  const denyProgram = (program_id) => {
    denySpecial({
      user_id: user.id,
      id: program_id,
    });
  };

  return (
    <Spin spinning={isLoadingOptions}>
      <AddSpecialOptionForm user_id={user.id} />
      {options?.options.map((program) => {
        return (
          <div key={program.id}>
            <Text style={{ display: "block" }}>
              <Tag>{program.full_name}</Tag>{" "}
              <Text strong>
                {amountText}: {program.amount}
              </Text>
            </Text>
            <Text style={{ display: "block" }}>
              {program.dates_status}
              {program.terminable && (
                <CloseCircleTwoTone
                  twoToneColor="red"
                  onClick={() => denyProgram(program.id)}
                />
              )}
            </Text>
          </div>
        );
      })}
    </Spin>
  );
};
