import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Card, Col, Row } from "antd";
import UserData from "../components/UserData";
import UserInfoForm from "../components/UserInfoForm";
import ChangePassword from "../components/ChangePassword";
import { useChangeUserName, useChangeUserPassword } from "../apiHooks";

const Main: React.FC = () => {
  const basicInfo = useSelector((state) => state.user.basicInfo, shallowEqual);
  const [updateUserName, { isLoading: isUpdateUserName }] = useChangeUserName();
  const [
    updateUserPassword,
    { isLoading: isUpdateUserPassword },
  ] = useChangeUserPassword();
  return (
    <PageTemplate
      title={_t("Personal information")}
      additionalClass="personal-info"
    >
      <Row gutter={[24, 32]}>
        <Col span={24}>
          <Card>
            <UserData
              login={basicInfo.login}
              email={basicInfo.email}
              first_name={basicInfo.first_name}
              last_name={basicInfo.last_name}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col md={24} xl={12}>
          <Card title={_t("User Information")}>
            <UserInfoForm
              first_name={basicInfo.first_name}
              last_name={basicInfo.last_name}
              phone={basicInfo.phone}
              onSubmit={updateUserName}
              isLoading={isUpdateUserName}
            />
          </Card>
        </Col>
        <Col md={24} xl={12}>
          <Card title={_t("Change Password")}>
            <ChangePassword
              onSubmit={updateUserPassword}
              isLoading={isUpdateUserPassword}
            />
          </Card>
        </Col>
      </Row>
    </PageTemplate>
  );
};

export default Main;
