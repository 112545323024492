import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { _t } from "../../Common/components/InjectIntlContext";
import { NoData } from "../../Common/components/NoData";
import { Result } from "../constants";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("position"),
    dataIndex: "position",
    key: "position",
  },
  {
    title: _t("affiliate_id"),
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: _t("value"),
    dataIndex: "value",
    key: "value",
  },
];

type ResultsWidgetProps = {
  results: Result[];
};

export function ResultsWidget({ results }: ResultsWidgetProps) {
  const translatedColumns = columns(_t);
  return (
    <Table
      columns={translatedColumns}
      rowKey="position"
      dataSource={results}
      pagination={false}
      locale={{ emptyText: <NoData /> }}
      size={"small"}
      title={() => _t("results")}
    />
  );
}
