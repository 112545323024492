import React, { useCallback } from "react";
import { Card, Checkbox, Col, Form, Row, Space } from "antd";
import { Button, SimpleInput } from "../../Common/components";
import { _t } from "../../Common/components/InjectIntlContext";
import { SettingsDropdown } from "../../../common/components";

export const FiltersWidget = ({ onSubmit, initialValues, isSubmitting }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    onSubmit({ ...initialValues, ...values });
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Row align="middle" className="users-form" gutter={5}>
        <Col flex="auto">
          <Space>
            <Form.Item name="search" noStyle>
              <SimpleInput />
            </Form.Item>
            <Button
              loading={isSubmitting}
              type="primary"
              title={_t("search")}
              htmlType="submit"
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
