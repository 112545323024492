import { REPORT_DATE_KEY, REPORT_DATE_KEY_2 } from "../utils";

export const actionTypes = {
  UPDATE_FILTERS: "reports/affiliates-difference-report/update-filters",
};

export const updateReportFilters = (filters) => {
  if (filters.date) {
    localStorage.setItem(REPORT_DATE_KEY, JSON.stringify(filters.date));
  }
  if (filters.date2) {
    localStorage.setItem(REPORT_DATE_KEY_2, JSON.stringify(filters.date2));
  }
  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      ...filters,
    },
  };
};
