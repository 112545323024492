import { CurrencyLabel } from "../Common/components";
import React from "react";
import { Tag } from "antd";
import { ColumnType } from "antd/lib/table";

export const dateKey = "filters.date.costs";
const statusColors = {
  success: "#27ae60",
  error: "#e42320",
};

const renderCode = (value) => {
  return (
    <pre style={{ overflowX: "auto", maxWidth: 200, marginBottom: 0 }}>
      {value}
    </pre>
  );
};

const availableColumns = {
  postback_date: {
    title: "created",
    dataIndex: "postback_date",
  },
  postback_status: {
    title: "status",
    dataIndex: "postback_status",
    key: "postback_status",
    render: (v: number) => {
      const status = !!v ? "success" : "error";
      return <Tag color={statusColors[status]}>{status}</Tag>;
    },
  },
  amount: {
    title: "amount",
    dataIndex: "amount",
    render: (v: number) => <CurrencyLabel value={v} />,
  },
  refcode: {
    title: "ref_code",
    dataIndex: "refcode",
  },
  gamer_id: {
    title: "gamer",
    dataIndex: "gamer_id",
  },
  url: {
    title: "url",
    dataIndex: "url",
    render: renderCode,
  },
  info: {
    title: "info",
    dataIndex: "info",
    render: renderCode,
  },
  project_name: {
    title: "project",
    dataIndex: "project_name",
    key: "project_name",
  },
  attempts: {
    title: "attempts",
    dataIndex: "attempts",
  },
  reg_date: {
    title: "reg_date",
    dataIndex: "reg_date",
  },
};
const {
  postback_date,
  postback_status,
  amount,
  refcode,
  gamer_id,
  url,
  info,
  attempts,
  reg_date,
  project_name,
} = availableColumns;
const depositColumns = [
  postback_date,
  postback_status,
  amount,
  refcode,
  gamer_id,
];
const firstDepositColumns = [
  postback_date,
  postback_status,
  attempts,
  refcode,
  gamer_id,
  reg_date,
  info,
  project_name,
  url,
];
const registerColumns = [
  postback_date,
  postback_status,
  attempts,
  refcode,
  gamer_id,
  reg_date,
  info,
  project_name,
  url,
];
const qualificationColumns = [
  postback_date,
  postback_status,
  attempts,
  refcode,
  gamer_id,
  reg_date,
  info,
  project_name,
  url,
];
const installColumns = [
  postback_date,
  postback_status,
  attempts,
  refcode,
  info,
  project_name,
  url,
];
export const pagesColumn = {
  deposit: [...depositColumns],
  "first-deposit": [...firstDepositColumns],
  register: [...registerColumns],
  qualification: [...qualificationColumns],
  install: [...installColumns],
};
