import React from 'react';
import componentsList from './helpers/componentsList';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

const AllComponents = () => (
  <Row gutter={[16, 16]} className="all-components">
    {
      componentsList.map(item => {
        const col = item.col || 2;
        return (
            <Col span={col} key={item.title} className="all-components__item">
              <div className="all-components__item__title">{item.title}</div>
              <div className="all-components__item__component">{item.element}</div>
            </Col>
        )
      })
    }
  </Row>
);

export default AllComponents;
