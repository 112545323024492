import React, { useState } from "react";
import { Steps } from "antd";
import StepIcon from "../../../Common/components/StepIcon";
import Btn from "../../../Common/components/Button";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useCreatePromoMaterial, usePromoPrograms } from "../../apiHooks";
import PromoTemplates from "./PromoTemplates";
import { Spin } from "antd";
const { Step } = Steps;

const PromoSteps = () => {
  const { data: programsData, isLoading: programsLoading } = usePromoPrograms();
  const [current, setStep] = useState(0);
  const [selectedProject, selectProjectId] = useState(null);

  const [selectedPromoType, selectPromoType] = useState(null);
  const [selectedTemplate, selectTemplate] = useState(null);
  const [selectedLandingPage, selectLandingPage] = useState(null);
  const [createPromo, { isLoading: isCreating }] = useCreatePromoMaterial();
  const checkRestriction = () => {
    if (current === 0 && !!selectedProject) {
      return false;
    } else if (current === 1 && !!selectedPromoType) {
      return false;
    }

    return true;
  };

  const requestData = {
    project_id: selectedProject?.project?.id,
    program_id: selectedProject?.id,
    type: selectedPromoType?.id,
    template_id: selectedTemplate?.template?.id || "",
    landing_page_id: selectedLandingPage?.landing_page?.id,
  };

  const checkSubmitRestriction = () => {
    Object.values(requestData).forEach((item) => {
      if (!item) {
        return false;
      }
    });

    return false;
  };

  const submitCreate = () => {
    createPromo(requestData);
  };

  const next = () => {
    setStep(current + 1);
  };
  const prev = () => {
    current == 1 && selectPromoType(null);
    current == 2 && selectTemplate(null);
    current == 2 && selectLandingPage(null);
    setStep(current - 1);
  };
  const steps = [
    {
      title: _t("Step 1"),
      description: !!selectedProject ? (
        <>
          {_t("Program")}:{" "}
          <span className={"selected-description"}>{selectedProject.name}</span>
        </>
      ) : (
        _t("select_program")
      ),
      content: (
        <FirstStep
          onItemSelect={selectProjectId}
          selectedProject={selectedProject}
          data={programsData}
          isLoading={programsLoading}
          nextStep={next}
        />
      ),
      icon: <StepIcon>1</StepIcon>,
    },
    {
      title: _t("Step 2"),
      description: !!selectedProject ? (
        <>
          {_t("promo_type")}:{" "}
          <span className={"selected-description"}>
            {selectedPromoType?.name}
          </span>
        </>
      ) : (
        _t("promo_type")
      ),
      content: (
        <>
          <SecondStep
            onItemSelect={selectPromoType}
            selectedPromoType={selectedPromoType}
            selectedProject={selectedProject}
          />
          {selectedPromoType?.id && (
            <PromoTemplates
              selectedPromoType={selectedPromoType}
              selectedProject={selectedProject}
              selectTemplate={selectTemplate}
            />
          )}
        </>
      ),
      icon: <StepIcon>2</StepIcon>,
    },
    {
      title: _t("Step 3"),
      description: !!selectedLandingPage ? (
        <>
          {_t("link_to")}:{" "}
          <span className={"selected-description"}>
            {selectedLandingPage?.landing_page?.name}
          </span>
        </>
      ) : (
        _t("link_to")
      ),
      content: (
        <ThirdStep
          selectedProject={selectedProject}
          selectedPromoType={selectedPromoType}
          selectedTemplate={selectedTemplate}
          selectLandingPage={selectLandingPage}
        />
      ),
      icon: <StepIcon>3</StepIcon>,
    },
  ];

  return (
    <Spin spinning={isCreating}>
      <div className={"promo-steps"}>
        <Steps current={current} type="navigation">
          {steps.map((item) => (
            <Step
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current > 0 && (
            <Btn
              type="primary"
              title={_t("back")}
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
            />
          )}
          {current < steps.length - 1 && (
            <Btn
              type="primary"
              title={_t("next")}
              style={{ margin: "0 8px" }}
              onClick={() => next()}
              disabled={checkRestriction()}
            />
          )}

          {current == 2 && (
            <Btn
              type="primary"
              title={_t("get_code")}
              style={{ margin: "0 8px" }}
              onClick={submitCreate}
              disabled={checkSubmitRestriction() || isCreating}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default PromoSteps;
