import React from "react";
import {Radio} from "antd";
import {ProfileSubmitForm} from "./ProfileSubmitForm";


export const CollapsableRadio = ({
                                   user,
                                   name,
                                   value,
                                   textValue,
                                   onSubmit,
                                   selected,
                                   isLoading,
                                   setEditable,
                                   radioItems
                                 }) => {

  const initialValues = {
    id: user.id,
    field_name: name,
    value: value?.toString(),
  };
  return (
    <ProfileSubmitForm textValue={textValue} initialValues={initialValues} isLoading={isLoading} name={name}
                       onSubmit={onSubmit} selected={selected} setEditable={setEditable}>
      <Radio.Group value={value?.toString()}>
        {Object.keys(radioItems).map((item) => {
          return (
            <Radio key={item} value={item.toString()}>
              {radioItems[item]}
            </Radio>
          );
        })}
      </Radio.Group>
    </ProfileSubmitForm>
  );
};
