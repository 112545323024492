import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayouts";
import PostBackDebug from "../pages/PostBackDebug";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../Common/components/InjectIntlContext";

const User: React.FC = () => {
  const links = [
    {
      title: _t("postback_deposit"),
      path: APP_ROUTES.costs.post_back_debug.deposit,
      exact: true,
    },
    {
      title: _t("postback_first_deposit"),
      path: APP_ROUTES.costs.post_back_debug.first_deposit,
    },
    {
      title: _t("postback_register"),
      path: APP_ROUTES.costs.post_back_debug.register,
    },
    {
      title: _t("postback_qualification"),
      path: APP_ROUTES.costs.post_back_debug.qualification,
    },
    {
      title: _t("postback_install"),
      path: APP_ROUTES.costs.post_back_debug.install,
    },
  ];

  return (
    <MainLayout headerLinks={links}>
      <Route
        exact
        path={`/costs/post_back_debug/:page`}
        component={PostBackDebug}
      />
    </MainLayout>
  );
};

export default User;
