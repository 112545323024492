import React from "react";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { generatePath, useHistory } from "react-router-dom";
import { Button, Card, Divider, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { EditOutlined } from "@ant-design/icons";

const TranslationItem = ({ id, defaultMessage, translation }) => {
  const translationPath = generatePath(
    APP_ROUTES.content.translations.translation,
    {
      id,
    }
  );
  const history = useHistory();
  return (
    <Card className="translation-list-item">
      <div className="translation-list-item__top">
        <div className="translation-list-item__title">
          <Typography.Title level={5}>{defaultMessage}</Typography.Title>
        </div>
        <Button
          size="large"
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={() => history?.push(translationPath)}
          className="translation-list-item__edit"
        >
          {_t("edit")}
        </Button>
      </div>
      <Divider />
      <div
        className="translation-list-item__content"
        dangerouslySetInnerHTML={{ __html: translation }}
      ></div>
    </Card>
  );
};

export default TranslationItem;
