import { useMutation, useQueryCache } from "react-query";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../system/utils/notification";
import { API_KEY_GET_AFFILIATE_PROGRAMS } from "../utils";
import { postProgram } from "../api";

export const usePostProgramCreate = () => {
  const queryCache = useQueryCache();
  const successMsg = _t("successfully_updated");

  return useMutation(postProgram, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);

      queryCache.invalidateQueries([API_KEY_GET_AFFILIATE_PROGRAMS]);
    },
  });
};
