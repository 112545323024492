import React, { useEffect } from "react";
import { Spin, Checkbox } from "antd";
import {
  useGetUserSubscriptions,
  useUpdateUserSubscriptions,
} from "../apiHooks";
import Form from "antd/lib/form";
import Button from "../../Common/components/Button";
import SelectInput from "../../Common/components/SelectInput";
import { updateSubscriptionsRequest } from "../api";
import { _t } from "../../Common/components/InjectIntlContext";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 24 },
};

const Subscriptions: React.FC = () => {
  const {
    data = { mailings: {}, languages: {} },
    isLoading,
  } = useGetUserSubscriptions();

  const [
    changeSubscriptions,
    { isLoading: isUpdating },
  ] = useUpdateUserSubscriptions();
  let activeLang = "";

  const selectValues = Object.values(data.languages).map((item, idx) => {
    if (!!item.active) {
      activeLang = item.title;
    }
    return {
      label: item.title,
      value: Object.keys(data.languages)[idx],
    };
  });

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [isLoading]);

  const normalizeData = (data): updateSubscriptionsRequest => {
    const normalized = {} as updateSubscriptionsRequest;
    for (let key in data) {
      if (key.includes("mailing_type")) {
        normalized[key] = !!data[key] ? 1 : 0;
      } else {
        normalized[key] = data[key];
      }
    }

    return normalized;
  };

  const onSubmit = (values) => changeSubscriptions(normalizeData(values));

  return (
    <Spin spinning={isLoading || isUpdating}>
      <Form
        form={form}
        {...layout}
        name="user_subscriptions_form"
        onFinish={onSubmit}
      >
        {Object.values(data.mailings).map((item, idx) => {
          return (
            <Form.Item
              key={idx}
              valuePropName="checked"
              initialValue={!!item.unsubscribed}
              name={`mailing_type[${Object.keys(data.mailings)[idx]}]`}
            >
              <Checkbox>{item.title}</Checkbox>
            </Form.Item>
          );
        })}
        <Form.Item name="subscription_language" initialValue={activeLang}>
          <SelectInput options={selectValues} placeholder={_t("language")} />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" title={_t("Apply")} htmlType="submit" />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default Subscriptions;
