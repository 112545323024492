import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { useGetBrandItem } from "../hooks";
import { AffiliateBrandFormEdit } from "./components";
import { Spin } from "antd";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const AffiliateBrandItemEdit = () => {
  const { project_id } = useParams();
  const { data, isLoading } = useGetBrandItem({
    project_id: project_id,
  });

  return (
    <MainLayout>
      <PageTemplate title={_t("edit_project")}>
        <Spin spinning={isLoading}>
          {data && <AffiliateBrandFormEdit brandInfo={data?.project} />}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateBrandItemEdit;
