import React, { useState } from "react";
import { useGetPromoLandings } from "../../apiHooks";
import { Spin, Result } from "antd";
import Form from "antd/lib/form";
import ButtonGroup from "../../../Common/components/ButtonGroup";
import SelectBanner from "../../../Common/components/SelectBanner";
import { _t } from "../../../Common/components/InjectIntlContext";
import RadioBtn from "../../../Common/components/RadioBtn";
import SmallImg from "../../../../images/icons/tiles/small.svg";
import MiddleImg from "../../../../images/icons/tiles/middle.svg";
import BigImg from "../../../../images/icons/tiles/big.svg";

type requestDataType = {
  project_id: string | number;
  promo_type: string | number;
  template_id?: string | number;
};

type allValuesType = {
  [key: string]: { id: string };
};

const tileSizes = [
  {
    id: 1,
    value: "200px",
    name: <img src={SmallImg} alt="" />,
  },
  {
    id: 2,
    value: "250px",
    name: <img src={MiddleImg} alt="" />,
  },
  {
    id: 3,
    value: "300px",
    name: <img src={BigImg} alt="" />,
  },
];

const ThirdStep = ({
  selectedTemplate,
  selectedProject,
  selectedPromoType,
  selectLandingPage,
}) => {
  const requestData: requestDataType = {
    project_id: selectedProject.project.id,
    promo_type: selectedPromoType.id,
  };
  if (!!selectedTemplate) {
    requestData.template_id = selectedTemplate?.template?.id;
  }
  const { isLoading, data } = useGetPromoLandings(requestData);
  const onValuesChange = (changed, all) => {
    selectLandingPage(all);
  };

  const filters = data?.data?.filters;
  const dataItems = data?.data?.items;

  const [lang, setSelectedLanguage] = useState(null);
  const [size, setTileSize] = useState("250px");
  const onFilterChange = (changedVal, allValues: allValuesType) => {
    setSelectedLanguage(allValues["language"]?.id);
    if (allValues["size"]) {
      setTileSize(allValues["size"]?.id);
    }
  };

  const renderItems = () => {
    if (dataItems && dataItems.length) {
      return (
        <Form.Item name={"landing_page"}>
          <ButtonGroup prefixClassName={"promo-templates__banners"}>
            {dataItems
              .filter((item) => !lang || item.language[0] === lang)
              .map((item) => {
                return (
                  <SelectBanner
                    key={item.id}
                    id={item.id}
                    value={{ id: item.id, name: item.name }}
                    name={item.name}
                    width={size}
                    height={size}
                    innerHtml={`<img src=${item.image}>`}
                    simpleImage={true}
                    link={item.url}
                  />
                );
              })}
          </ButtonGroup>
        </Form.Item>
      );
    }
  };

  const renderTileSizes = () => (
    <Form.Item name={"size"} initialValue={{ id: size }}>
      <ButtonGroup value={{ id: size }}>
        {tileSizes.map((item) => (
          <RadioBtn
            value={{ id: item.value }}
            key={item.id}
            id={item.id}
            name={item.name}
          />
        ))}
      </ButtonGroup>
    </Form.Item>
  );

  const renderLanguageFilters = () => {
    return filters?.language.length > 1 ? (
      <Form.Item name={"language"} label={_t("language")}>
        <ButtonGroup>
          {filters?.language.map((languageItem) => {
            return (
              <RadioBtn
                value={{ id: languageItem.key }}
                key={languageItem.key}
                id={languageItem.key}
                name={languageItem.name}
              />
            );
          })}
        </ButtonGroup>
      </Form.Item>
    ) : (
      <div></div>
    );
  };

  if (data?.data?.status === "error") {
    return <Result status="error" title={data?.data?.messages} />;
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        name="landing_filters"
        layout="vertical"
        onValuesChange={onFilterChange}
      >
        {renderTileSizes()}
        {renderLanguageFilters()}
      </Form>
      <Form
        onValuesChange={onValuesChange}
        name="landing_templates"
        onFinish={() => {}}
        layout="vertical"
      >
        {renderItems()}
      </Form>
    </Spin>
  );
};

export default ThirdStep;
