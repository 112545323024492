import React from "react";

interface StatusLabelProps {
  status: number;
  text: string;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, text }) => {
  return <div className={`status-label status-label--${status}`}>{text}</div>;
};

export default StatusLabel;
