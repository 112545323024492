import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { GeoDetails, ReportFilters } from "./components";
import { useTableData } from "../../hooks/table";
import { config } from "./config";
import { generateGeoChart } from "./utils/generateGeoChart";
import { Card, Modal, Spin } from "antd";
import { useGetReportFile } from "../../hooks/api";
import { ExcelButton } from "../../../../../modules/Dashboard/components/Filters/ExcelButton";

const GeoReport = () => {
  const [currentCountry, setCurrentCountry] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const chartID = "geo-report-chart";
  const { setFilters, filters, data, isLoading, reqFilters } = useTableData(
    config.stateSelector,
    config.dispatchCallback,
    config.api,
    config.apiKey
  );

  const [getFile, { isLoading: isFileLoading }] = useGetReportFile(
    config.api,
    "geo_report",
    reqFilters
  );

  const getChartValues = (value) => {
    const currencyValues = [
      "partner_income",
      "deposits",
      "withdraws",
      "ng",
      "comppoints",
    ];
    if (currencyValues.includes(reqFilters.field)) return value / 100;
    return value;
  };

  const clickHandler = () => {
    getFile();
  };
  const labelTitle = _t(reqFilters.field);
  useEffect(() => {
    if (!isLoading && data) {
      generateGeoChart(
        Object.values(data?.models)
          .map((item: any) => ({
            id: item.country,
            name: item.country_full,
            value: getChartValues(+item[reqFilters.field]),
          }))
          .filter((item) => item.name.length),
        chartID,
        setCurrentCountry,
        setIsModalOpen,
        labelTitle
      );
    }
  }, [data, chartID]);
  return (
    <MainLayout>
      <PageTemplate title={_t("geo_report")}>
        <ReportFilters
          setFilters={setFilters}
          initialValues={filters}
          excelButton={
            <ExcelButton
              clickHandler={clickHandler}
              isLoading={isFileLoading}
            />
          }
        />
        {!isLoading && data ? (
          <Card className="geo-chart-map-wrap">
            <div id={chartID} className="geo-chart-map"></div>
          </Card>
        ) : (
          <Spin spinning={isLoading} />
        )}
        {isModalOpen && (
          <Modal
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={"auto"}
          >
            <GeoDetails
              currentCountry={currentCountry}
              reqFilters={reqFilters}
            />
          </Modal>
        )}
      </PageTemplate>
    </MainLayout>
  );
};

export default GeoReport;
