import React from "react";
import {PromoProgramsResponce, PromoProgramsData} from "../../constants";
import PromoCard from "../../../Common/components/PromoCard";
import {Row, Col, Spin} from "antd";
import ProgramImg from "../../../../images/promo/program.svg";
import ProjectInfo from "./ProjectInfo";

type FirstStepProps = {
  data: PromoProgramsResponce;
  isLoading: boolean;
  onItemSelect: any;
  selectedProject: PromoProgramsData | null;
  nextStep: any;
};

const FirstStep: React.FC<FirstStepProps> = ({
                                               onItemSelect,
                                               data,
                                               isLoading,
                                               selectedProject,
                                               nextStep,
                                             }) => {
  const click = (item) => {
    onItemSelect(item);
  };
  let promo_id = new URL(window.location.href).searchParams.get("promo_id");

  const isSpecial = data?.data?.some(item => item.special);
  const specialPrograms =  data?.data.filter(program=>program.special);
  const programs: any = isSpecial ? specialPrograms : data?.data;
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[20, 20]}>
        {programs?.map((item) => {
          if (promo_id === item.id) {
            click(item);
            nextStep();
          }
          return (
            <Col key={item.id} xs={24} sm={12} onClick={() => click(item)}>
              <PromoCard
                img={ProgramImg}
                checked={!!selectedProject && selectedProject.id === item.id}
                info={
                  <ProjectInfo
                    img={item.project.image}
                    type={item.name}
                    href={item.project.url}
                  />
                }
              />
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};

export default FirstStep;
