import React from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row } from "antd";
import {
  ProgramsSelect,
  ProgramTypesSelect,
} from "../../../../../../../common/components";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import DateRangePicker from "../../../../../../../common/components/DateRangePicker/DateRangePicker";
import { setFormInputValue } from "../../../../../../../common/helpers";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const [form] = useForm();
  return (
    <Filters
      form={form}
      onApply={setFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item name="rDate" label={_t("reg_date")}>
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "rDate",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={10}>
            <ProgramsSelect />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <ProgramTypesSelect />
          </Col>
          <Col xs={24} md={4} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
