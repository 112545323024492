import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Casinoslot from "../../components/brand/Casinoslot";

const CasinoslotPage = (props) => {

    return (
        <LandingTemplate wrapClass={"brands__wrap--more"}>
            <Casinoslot />
        </LandingTemplate>
    );
};

export default CasinoslotPage;
