import { LanguagesResponse, translationResponse } from "./constants";
import axios from "axios";
import texts from "../../system/constants/Translations";
import axiosClient from "../../system/helpers/axiosConfig";

const TRANSLATION_URL = (locale) => `/api/translations/list/${locale}`;
const AVAILABLE_LANGUAGES_URL = `/api/translations/languages`;
const API_URL = "/api/translations/merge";

export const getTranslationsAction = (locale) => (
  dispatch: any
): Promise<translationResponse> => {
  // TODO: move this to project build-deploy
  // axiosClient
  //   .post(API_URL, {"translations": texts}).then((res) => {
  //   console.log(res);
  // }).catch((err) => {
  //   console.log(err);
  // });
  //

  return dispatch({
    type: "TRANSLATION",
    request: {
      url: TRANSLATION_URL(locale),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getAvailableLangAction = () => (
  dispatch: any
): Promise<LanguagesResponse> => {
  return dispatch({
    type: "GET_AVAILABLE_LANGUAGES",
    request: {
      url: AVAILABLE_LANGUAGES_URL,
      method: "GET",
    },
  }).then((data: any) => data.payload);
};
