import React from "react";
import { Button, Table } from "antd";
import { _t } from "../../../../../../../../modules/Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import { usePostRemoveUserFromList } from "../../../../hooks";
import { deleteUserFromList, getUserProfilePath } from "./helpers";

const ManageableUsersTable = ({ data, listId, listType, setShowUsers }) => {
  const [deleteUser] = usePostRemoveUserFromList();
  const columns = [
    {
      title: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: _t("id"),
      dataIndex: "id",
    },
    {
      title: _t("Login"),
      dataIndex: "login",
      render: (text, record) => (
        <Link to={getUserProfilePath(record.id)}>{text}</Link>
      ),
    },
    {
      render: (text, record) => (
        <Button
          shape="round"
          onClick={() => {
            if (data.length < 1) setShowUsers(false);
            deleteUserFromList(record.id, listId, listType, deleteUser);
          }}
        >
          {_t("delete")}
        </Button>
      ),
    },
  ];
  return <Table dataSource={data} columns={columns} pagination={false} />;
};

export default ManageableUsersTable;
