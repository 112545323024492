import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import { generatePath } from "react-router-dom";
import {
  ChatControls,
  EditMsg,
  InfoLabel,
  Msg,
  SendMsgForm,
} from "./components";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { editMsgInterface } from "../../helpers";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

interface ChatContainerProps {
  chatInfo: any;
  isAdmin?: boolean;
}
const ChatContainer: React.FC<ChatContainerProps> = ({ chatInfo, isAdmin }) => {
  const history = useHistory();
  const { conversation, messages } = chatInfo;
  const {
    theme,
    created_at,
    partner_id,
    priority,
    partner,
    status,
  } = conversation;
  const partnerProfile = generatePath(APP_ROUTES.manage.user_profile, {
    partnerId: partner_id,
  });
  useEffect(() => {
    setTimeout(() => {
      const chatContainer = document.querySelector(".chat__content");
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }, 0);
  }, [chatInfo]);

  const [editMsg, setEditMsg] = useState<editMsgInterface>();

  const msgItem = (item) => (
    <Msg
      title={item.is_admin ? _t("administrator") : partner?.login}
      text={item.text}
      id={item.id}
      date={item.created_at}
      isAuthorAdmin={item.is_admin}
      setEditMsg={setEditMsg}
      editMsg={editMsg}
    />
  );

  return (
    <Card className="chat">
      <div className="chat__top">
        <p className="chat__type">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
          />
          {theme}
        </p>
        <ul className="chat__info-labels">
          <li className="chat__info-label">
            <InfoLabel title={_t("Created")} value={created_at} />
          </li>
          {isAdmin && partner && (
            <li className="chat__info-label">
              <InfoLabel
                title={_t("partner")}
                value={`${partner?.login} [${partner?.id}]`}
                type="link"
                path={partnerProfile}
              />
            </li>
          )}
        </ul>
        {isAdmin && <ChatControls priority={priority} status={status} />}
      </div>
      <div className="chat__content">{messages.map(msgItem)}</div>
      <div className="chat__footer">
        {editMsg?.editable && (
          <EditMsg editMsg={editMsg} setEditMsg={setEditMsg} />
        )}
        <SendMsgForm
          isAdmin={isAdmin}
          editMsg={editMsg}
          setEditMsg={setEditMsg}
        />
      </div>
    </Card>
  );
};

export default ChatContainer;
