import { getTestimonials } from "./api";
import { useQuery } from "react-query";

const Testimonials = "testimonials";

export const useTestimonials = (params) => {
  return useQuery([Testimonials, params], (k, params) =>
    getTestimonials(params)
  );
};
