import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { TestimonialCard } from "../../components";
import { usePostTestimonial } from "../../hooks";
import { Spin } from "antd";

const TestimonialCreate = () => {
  const [
    createTestimonial,
    { isLoading: isPostTestimonialLoading },
  ] = usePostTestimonial();
  const { languages } = useSelector((state) => state.locale);
  const fillTranslations = (languages, values) => {
    const translations = {};
    Object.values(languages).map((item: any) => {
      translations[item.code] = {
        title: values[`translations[${item.code}][title]`],
        lang: values[`translations[${item.code}][lang]`],
        url: values[`translations[${item.code}][url]`],
        content: values[`translations[${item.code}][content]`],
      };
    });
    return translations;
  };
  const handleSubmit = (values) => {
    createTestimonial({
      ...values,
      translations: fillTranslations(languages, values),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isPostTestimonialLoading}>
          <TestimonialCard handleSubmit={handleSubmit} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TestimonialCreate;
