import { useMutation, useQueryCache } from "react-query";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { postLandingPage } from "../api";
import { notify } from "../../../../system/utils/notification";
import { API_KEY_GET_LANDING_PAGE_INFO } from "../utils";

export const usePostLandingPageUpdate = (params) => {
  const queryCache = useQueryCache();
  const successMsg = _t("successfully_updated");

  return useMutation(postLandingPage, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);
      queryCache.invalidateQueries([API_KEY_GET_LANDING_PAGE_INFO, params]);
    },
  });
};
