import React, { useCallback } from "react";
import Form from "antd/lib/form";
import { userInfoRules } from "./constants";
import Input from "antd/lib/input";
import Button from "../../Common/components/Button";
import { Spin } from "antd";
import { getUserId } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 24 },
};

type UserInfoFormProps = {
  first_name: string;
  last_name: string;
  phone: string;
  onSubmit: any;
  isLoading: boolean;
};

const UserInfoForm: React.FC<UserInfoFormProps> = ({
  first_name,
  last_name,
  phone,
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const onFinish = useCallback(
    async (values) => {
      const data = {
        user_id: getUserId(),
        username: values.phone,
      };
      const res = await onSubmit(data);
      form.resetFields();
      return res;
    },
    [form, onSubmit]
  );

  return (
    <Spin spinning={false}>
      <Form
        form={form}
        initialValues={{ first_name, last_name, phone }}
        {...layout}
        labelAlign={"left"}
        name="user_info_form"
        onFinish={onFinish}
      >
        <Form.Item label={_t("phone")} name="phone" rules={userInfoRules.phone}>
          <Input placeholder={_t("phone")} />
        </Form.Item>
        <Form.Item
          label={_t("first_name")}
          name="first_name"
          rules={userInfoRules.first_name}
        >
          <Input placeholder={_t("first_name")} disabled />
        </Form.Item>
        <Form.Item
          label={_t("last_name")}
          name="last_name"
          rules={userInfoRules.last_name}
        >
          <Input placeholder={_t("last_name")} disabled />
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            type="primary"
            title={_t("Apply")}
            htmlType="submit"
            disabled={isLoading}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserInfoForm;
