import React from "react";
import classNames from "classnames";
import { EyeOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

type SelectBannerProps = {
  width: string;
  height: string;
  innerHtml: string;
  name?: string;
  selected?: string;
  id?: string | number;
  value: {
    id: string | number;
    name?: string;
  };
  onClick?: (id) => any;
  simpleImage?: boolean;
  link?: string;
};

const SelectBanner: React.FC<SelectBannerProps> = ({
  onClick,
  selected,
  id,
  width,
  height,
  innerHtml,
  name,
  value,
  simpleImage,
  link,
}) => {
  const affiliateApi = '<script src="/j/affiliates.api.min.js?v2.0"></script>';
  const click = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(item);
  };
  return (
    <div className={"select-banner-wrapper"}>
      {link && (
        <NavLink
          to={link}
          className={"item-preview-wrapper"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <EyeOutlined />
        </NavLink>
      )}
      <div
        className={classNames(
          "select-banner",
          selected && "select-banner__selected"
        )}
        onClick={(e) => click(e, value)}
      >
        {simpleImage ? (
          <div
            style={{
              width: width,
              height: height,
            }}
            className={"select-banner__inner"}
            dangerouslySetInnerHTML={{
              __html: innerHtml,
            }}
          />
        ) : (
          <iframe
            srcDoc={affiliateApi + innerHtml}
            className={"select-banner__inner"}
            width={width}
            height={height}
            scrolling="no"
            style={{ pointerEvents: "none" }}
            frameBorder={0}
            marginWidth={0}
            marginHeight={0}
          />
        )}
        {name && (
          <div
            className={"select-banner__name"}
            style={{
              width: width,
            }}
          >
            {name}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBanner;
