import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Card,
  Checkbox,
  DatePicker,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import {
  useGetAvailableLanguages,
  useGetLandingPageLanguages,
} from "../../hooks/useGetLandingPageLanguages";
import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import {
  createOptions,
  setFormInputValue,
} from "../../../../../common/helpers";
import { useGetLandingPageStatuses } from "../../hooks/useGetLandingPageStatuses";
import { AffiliateLandingProperties } from "../../utils";
import moment from "moment";
import AffiliateLandingPageFormItemTitle from "./AffiliateLandingPageFormItemTitle";

interface AffiliateLandingPageFormProps {
  info?: AffiliateLandingProperties;
  updateLanding: any;
  project_id?: number;
  create?: boolean;
}

const AffiliateLandingPageForm: React.FC<AffiliateLandingPageFormProps> = ({
  info,
  updateLanding,
  create,
  project_id,
}) => {
  const [form] = Form.useForm();
  const {
    data: titleLanguages,
    isLoading: titleLanguagesIsLoading,
    isSuccess: titleLanguagesIsSuccess,
  } = useGetAvailableLanguages();
  const {
    data: languages,
    isLoading: languagesIsLoading,
    isSuccess: languagesIsSuccess,
  } = useGetLandingPageLanguages();
  const {
    data: statuses,
    isLoading: statusesIsLoading,
    isSuccess: statusesIsSuccess,
  } = useGetLandingPageStatuses();
  const [landingPreview, setLandingPreview] = useState(info?.image);
  const [deprecatedDate, setDeprecatedDate] = useState(info?.deprecated_date);
  const dateFormat = "YYYY-MM-DD";

  const handleIsDefaultCheck = (e) => {
    setFormInputValue("is_default", e.target.checked ? 1 : 0, form);
  };
  const handlePromoCheck = (e) => {
    setFormInputValue("is_promo", e.target.checked ? 1 : 0, form);
  };
  const handleUrlValue = (e) => {
    setFormInputValue("url", e.target.value, form);
  };
  const handleDateValue = (e) => {
    const dateValue = e ? e.format(dateFormat) : "";
    setDeprecatedDate(dateValue);
    setFormInputValue("deprecated_date", dateValue, form);
  };
  const uploadImage = (files) => {
    const image = files[0];
    if (!image) return false;
    setFormInputValue("image", image, form);
    setLandingPreview(URL.createObjectURL(image));
  };

  const sendForm = (values) => {
    for (let i in values) {
      if (values[i] === null || values[i] === undefined) values[i] = "";
    }
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }
    updateLanding(formData);
  };

  const initialValues = {
    is_default: 0,
    is_promo: 0,
    language: "en",
    status: "1",
    type: 2,
    url: "",
    code: "",
    deprecated_date: "",
    subid_param: "",
  };

  return (
    <Spin
      spinning={
        !titleLanguagesIsSuccess && !languagesIsSuccess && !statusesIsSuccess
      }
    >
      <Card>
        <Form
          form={form}
          className="affiliate-landing-page-form"
          onFinish={sendForm}
          layout="vertical"
          initialValues={
            create
              ? initialValues
              : {
                  ...info,
                  status: info?.status.toString(),
                  language: info?.language || "en",
                }
          }
        >
          {!create && (
            <Form.Item name="id" initialValue={info?.id} hidden>
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="project_id"
            initialValue={create ? project_id : info?.project_id}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item name="type" hidden initialValue="2">
            <Input />
          </Form.Item>
          <Row gutter={40}>
            <Col span={24} lg={12}>
              <AffiliateLandingPageFormItemTitle
                info={info}
                titleLanguages={titleLanguages}
              />
              <Row gutter={30}>
                <Col span={24} lg={12}>
                  <Form.Item name="language" label="Language">
                    <Select options={createOptions(languages?.languages)} />
                  </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                  <Form.Item name="subid_param" label="Subid param">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24} lg={12}>
                  <Form.Item name="status" label="Status">
                    <Select options={createOptions(statuses?.statuses)} />
                  </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                  <Form.Item name="deprecated_date" label="Make Deprecated On">
                    <div className="datepicker datepicker--left">
                      <DatePicker
                        dropdownClassName="datepicker-dropdown"
                        defaultValue={
                          deprecatedDate
                            ? moment(deprecatedDate, dateFormat)
                            : null
                        }
                        format={dateFormat}
                        onChange={handleDateValue}
                        suffixIcon={null}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <div className="landing-page-url-description">
                <Space>
                  <Form.Item
                    name="url"
                    label="Path"
                    rules={[
                      { required: true, message: "Please input Landing url!" },
                    ]}
                  >
                    <Input onChange={handleUrlValue} />
                  </Form.Item>
                  <Button
                    icon={<CopyOutlined />}
                    shape="round"
                    onClick={() =>
                      navigator.clipboard.writeText(form.getFieldsValue().url)
                    }
                  >
                    Copy
                  </Button>
                </Space>
                <p style={{ margin: "10px 0" }}>
                  Example: landing page: /landing/page.html?/%%LANG%%?%%CODE%%
                </p>
              </div>

              <Form.Item name="code" label="DMP Code">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <div className="landing-page-preview">
                <p>Image:</p>
                <img src={landingPreview} alt="" />
              </div>
              <Form.Item name="image">
                <Input.Group>
                  <label className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        uploadImage(e.target.files)
                      }
                    />
                    <span className="ant-btn ant-btn-round">
                      <UploadOutlined />
                      Upload logo
                    </span>
                  </label>
                </Input.Group>
              </Form.Item>
              <Form.Item name="is_default">
                <Checkbox
                  value={info?.is_default}
                  defaultChecked={info?.is_default === 1}
                  onChange={handleIsDefaultCheck}
                >
                  {_t("default_landing_checkbox")}
                </Checkbox>
              </Form.Item>
              <Form.Item name="is_promo">
                <Checkbox
                  value={info?.is_promo}
                  defaultChecked={info?.is_promo === 1}
                  onChange={handlePromoCheck}
                >
                  {_t("default_landing_checkbox")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Space style={{ marginTop: "40px" }}>
            <Button
              htmlType="submit"
              title={_t("submit")}
              type="primary"
              size="large"
              shape="round"
            >
              {_t("submit")}
            </Button>
            <Link
              to={APP_ROUTES.affiliates_programs.brands}
              className="ant-btn ant-btn-lg ant-btn-round"
            >
              {_t("back")}
            </Link>
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default AffiliateLandingPageForm;
