import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";

const CommissionCard = ({ info }) => {
  const { title, text, image, linkTo } = info;
  return (
    <div className="commission-card">
      <div className="commission-card__icon">
        <img src={image} alt="" />
      </div>
      <p className="commission-card__title">{title}</p>
      <p className="commission-card__text">{text}</p>
      <Link to={linkTo} className="btn btn--second">
        {_t("read_more")}
      </Link>
    </div>
  );
};

export default CommissionCard;
