import React, { useState } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Row, Col } from "antd";
import { FiltersWidget } from "../components/FiltersWidget";
import { UsersListWidget } from "../components/UsersListWidget";
import { useUsersList } from "../apiHooks";

const UsersAdminTemplate = ({ filtersWidget, userList }) => {
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col md={24} xl={12}>
          {filtersWidget}
        </Col>
        <Col md={24} xl={24}>
          {userList}
        </Col>
      </Row>
    </>
  );
};

const UsersAdmin = () => {
  const [filters, setFilters] = useState({
    search: "",
    "filters[activated]": "",
  });
  const [page, setPage] = useState(1);
  const pageSize = 100;

  const { data, isLoading } = useUsersList({ ...filters, page });
  const { data: dataNotActivatedUsers } = useUsersList({
    "filters[activated]": 0,
    page,
  });
  return (
    <MainLayout>
      <PageTemplate title={_t("Users")}>
        <UsersAdminTemplate
          filtersWidget={
            <FiltersWidget
              onSubmit={setFilters}
              initialValues={filters}
              isSubmitting={isLoading}
            />
          }
          userList={
            <UsersListWidget
              isLoading={isLoading}
              setFilters={setFilters}
              filters={filters}
              data={data}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              dataNotActivatedUsers={dataNotActivatedUsers}
              isActivateFilter
            />
          }
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default UsersAdmin;
