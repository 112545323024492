import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Commissions from "../components/Commissions";

const Commission = () => {
  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <Commissions />
    </LandingTemplate>
  );
};

export default Commission;
