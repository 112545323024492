import React from "react";
import config from "../../../system/config";

export const currencyFormator = new Intl.NumberFormat("ru-RU", {
  useGrouping: true,
});

type CurrencyLabelInterface = {
  value: any;
  pure?: boolean;
  symbol?: any;
};
export const CurrencyLabel: React.FC<CurrencyLabelInterface> = ({
  value,
  pure = false,
  symbol,
}) => {
  let numVal = parseFloat(value || 0);

  if (!pure) {
    numVal = numVal / 100;
  }
  const currencySymbol = symbol || config.projectCurrency;
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <span className={value >= 0 ? "currency-green" : "currency-red"}>
        <>{currencySymbol}</>
      </span>{" "}
      {currencyFormator.format(numVal)}
    </span>
  );
};
