import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Bettilt from "../../components/brand/Bettilt";

const BettiltPage = (props) => {

    return (
        <LandingTemplate wrapClass={"brands__wrap--more"}>
            <Bettilt />
        </LandingTemplate>
    );
};

export default BettiltPage;
