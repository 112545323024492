import { actionTypes } from "./actions";
import { REPORT_DATE_KEY } from "../utils";
import moment from "moment";

const getInitialDate = () => {
  let date;

  try {
    const savedDate = JSON.parse(localStorage.getItem(REPORT_DATE_KEY));
    if (savedDate !== null) {
      date = {
        from: moment(savedDate.from),
        to: moment(savedDate.to),
      };
    } else {
      date = {
        from: moment().startOf("day"),
        to: moment(),
      };
    }
  } catch (e) {
    console.log(e);
  }

  return date;
};

const initialState = {
  date: getInitialDate(),
  rDate: {
    from: null,
    to: null,
  } as any,
  page_size: "1000",
  page: 1,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case "LOGOUT_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...state,
        date: {
          ...state.date,
          ...getInitialDate(),
        },
      };

    default:
      return state;
  }
}
