import React, { useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useGetCategories } from "../../hooks";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { createOptions } from "../../../../../common/helpers";
import { PartnersSearch } from "../../../../../common/components";
import { selectDataProps } from "../../helpers";
import { convertToEmptyString } from "../../../../../common/helpers/convertToEmptyString";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

interface NewChatAdminProps {
  sendMail: (values) => void;
}
const NewChatAdmin: React.FC<NewChatAdminProps> = ({ sendMail }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    data: categoriesOptions,
    isLoading: isLoadingCategoriesOptions,
  } = useGetCategories();
  const { Option } = Select;
  const [selectData, setSelectData] = useState<selectDataProps>();
  const [searchValue, setSearchValue] = useState<any[]>();

  const clearSearch = () => {
    setSearchValue([]);
    setSelectData({ data: [] });
  };

  const sendForm = (values) => {
    convertToEmptyString(values);
    if (searchValue) values.receiver = searchValue.join();
    sendMail(values);
    clearSearch();
    form.resetFields();
    history.push(APP_ROUTES.chat.admin.list);
  };
  const errorMsg = {
    text: _t("input_msg_error_1"),
    text_lenght: _t("input_msg_error_2"),
  };

  return (
    <Card className="chat-new-form">
      <Form
        layout="vertical"
        onFinish={sendForm}
        form={form}
        initialValues={{
          category_id: "3",
          receiver: "",
        }}
      >
        <Row gutter={20}>
          <Col lg={12} span={24}>
            <Form.Item label={_t("theme")} name="theme">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Form.Item label={_t("category")} name="category_id">
              <Select
                options={createOptions(categoriesOptions?.data)}
                loading={isLoadingCategoriesOptions}
              />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Form.Item label={_t("receiver")} name="receiver">
              <PartnersSearch
                selectData={selectData}
                setSelectData={setSelectData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                Option={Option}
                clearSearch={clearSearch}
                isMultiple
              />
            </Form.Item>
            <Form.Item
              name="send_email"
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox>{_t("send_mail")}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={_t("message")}
              name="text"
              rules={[
                { required: true, message: errorMsg.text },
                {
                  min: 3,
                  message: errorMsg.text_lenght,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button size="large" shape="round" type="primary" htmlType="submit">
              {_t("Send")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NewChatAdmin;
