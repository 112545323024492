import React, { useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useGetCategories } from "../../hooks";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { createOptions } from "../../../../../common/helpers";
import { convertToEmptyString } from "../../../../../common/helpers/convertToEmptyString";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

interface NewChatProps {
  sendMail: (values) => void;
}
const NewChat: React.FC<NewChatProps> = ({ sendMail }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    data: categoriesOptions,
    isLoading: isLoadingCategoriesOptions,
  } = useGetCategories();

  const sendForm = (values) => {
    convertToEmptyString(values);
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }
    sendMail(values);
    form.resetFields();
    history.push(APP_ROUTES.chat.list);
  };

  return (
    <Card className="chat-new-form">
      <Form
        layout="vertical"
        onFinish={sendForm}
        form={form}
        initialValues={{
          category_id: "3",
        }}
      >
        <Row gutter={20}>
          <Col lg={12} span={24}>
            <Form.Item label={_t("theme")} name="theme">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Form.Item label={_t("category")} name="category_id">
              <Select
                options={createOptions(categoriesOptions?.data)}
                loading={isLoadingCategoriesOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={_t("message")}
              name="text"
              rules={[
                { required: true, message: _t("input_msg_error_1") },
                {
                  min: 3,
                  message: _t("input_msg_error_2"),
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button size="large" shape="round" type="primary" htmlType="submit">
              {_t("Send")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NewChat;
