import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayouts";
import Main from "../pages/Main";
import Other from "../pages/Other";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../Common/components/InjectIntlContext";

const User: React.FC = () => {
  const links = [
    {
      title: _t("Personal information"),
      path: APP_ROUTES.user.main,
      exact: true,
    },
    {
      title: _t("Other information"),
      path: APP_ROUTES.user.other,
    },
  ];

  return (
    <MainLayout headerLinks={links}>
      <Route exact path={APP_ROUTES.user.main} component={Main} />
      <Route path={APP_ROUTES.user.other} component={Other} />
    </MainLayout>
  );
};

export default User;
