import React from "react";
import PromoCard from "../../../Common/components/PromoCard";
import { Row, Col, Spin } from "antd";
import { usePromoProjectTypes } from "../../apiHooks";
import { PromoTypes } from "../../constants";
import DefaultImg from "../../../../images/icons/promo/default.svg";

type SecondStepProps = {
  onItemSelect: any;
  selectedProject: any;
  selectedPromoType: any;
};

const SecondStep: React.FC<SecondStepProps> = ({
  onItemSelect,
  selectedProject,
  selectedPromoType,
}) => {
  const { data, isLoading } = usePromoProjectTypes(
    selectedProject?.project?.id
  );
  const click = (e, item) => {
    e.preventDefault();
    onItemSelect(item);
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[20, 20]}>
        {data?.data?.promo_types &&
          Object.values(data?.data?.promo_types).map((item) => {
            return (
                <Col key={item.id} xs={24} sm={12} md={8} onClick={(e) => click(e, item)}>
                  <PromoCard
                    size={"md"}
                    info={<div>{item.name}</div>}
                    img={
                      PromoTypes[item.id] ? PromoTypes[item.id].img : DefaultImg
                    }
                    checked={
                      selectedPromoType && selectedPromoType.id === item.id
                    }
                  />
                </Col>
            );
          })}
      </Row>
    </Spin>
  );
};

export default SecondStep;
