import React from "react";
import Mails from "./Mails";
import { useGetAdminMails } from "../../Chat/hooks";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const AdminMails = () => {
  return (
    <Mails
      mailsPath={APP_ROUTES.chat.admin.list}
      useHook={useGetAdminMails}
      isAdmin
    />
  );
};

export default AdminMails;
