import { getPostback } from "./api";
import { useMutation, useQuery } from "react-query";
import { getUserId } from "../../system/helpers/localstorageHelper";
import { removeEmpty } from "../Dashboard/apiHooks";
import moment from "moment";
import axiosClient from "../../system/helpers/axiosConfig";
import { responseFile } from "../../system/helpers/requestHelper";

const PostBack = "postback";

export const useGetPostback = (params) => {
  return useQuery(
    [`${PostBack}-${params.currentPage}`, params],
    (k, params) => {
      const requestParams = {
        date_from: moment(params.date.from).format("YYYY-MM-DD"),
        date_to: moment(params.date.to).format("YYYY-MM-DD"),
        ...params,
      };
      return getPostback(
        {
          user_id: getUserId(),
          ...removeEmpty(requestParams),
        } as any,
        params.currentPage
      );
    }
  );
};

export const useDownloadPostback = (params) => {
  const requestParams = {
    date_from: moment(params.date.from).format("YYYY-MM-DD"),
    date_to: moment(params.date.to).format("YYYY-MM-DD"),
    ...params,
  };
  return useMutation(() => {
    return axiosClient
      .get(`/api/postback-debug/${params.currentPage}`, {
        params: {
          ...requestParams,
          user_id: getUserId(),
          export: "xls",
        },
        responseType: "blob",
      })
      .then((r) => {
        return responseFile(r, `${params.currentPage}_costs.xlsx`);
      });
  });
};
