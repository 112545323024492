import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { generatePath } from "react-router-dom";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { CurrencyLabel } from "../../../../../modules/Common/components";

interface Props {
  title: string;
  data: {
    partner: {
      login: string;
      partner_id: number;
    };
    value: number;
  }[];
}

const StatsCard: React.FC<Props> = (props) => {
  return (
    <Card className="admin-stats-card">
      <p className="admin-stats-card__title">{_t(props.title)}</p>

      {props.data.map((item, idx) => (
        <div className="admin-stats-item" key={item.partner.partner_id}>
          <p className="admin-stats-item__number">{idx + 1}</p>
          <Link
            to={generatePath(APP_ROUTES.manage.user_profile, {
              partnerId: item.partner.partner_id,
            })}
            className="admin-stats-item__user"
          >
            {item.partner.login}
          </Link>
          <p className="admin-stats-item__value">
            {props.title === "partner_income" ? (
              <CurrencyLabel value={item.value} />
            ) : (
              item.value
            )}
          </p>
        </div>
      ))}
    </Card>
  );
};

export default StatsCard;
