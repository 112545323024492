import React, { useEffect, useMemo } from "react";
import { Spin, Card, Typography } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { useResetPassword } from "../apiHooks";
import { useUrlQuery } from "../utils";
import Button from "../../Common/components/Button";
import { NavLink } from "react-router-dom";

export function ResetPassword() {
  const query = useUrlQuery();
  const key = query.get("key");
  const email = query.get("email");

  const [resetPassword, { isLoading, isSuccess, data }] = useResetPassword({
    onSuccess: (res) => console.log(res.data),
  });

  useEffect(() => {
    if (email && key) {
      resetPassword({ email, key });
    }
  }, [key, email]);

  const error = useMemo(() => {
    return isSuccess && data?.data?.status === "error";
  }, [isSuccess]);

  return (
    <div className="h-100per fx-center fxDirCol text-center">
      {isLoading && key && email && (
        <>
          <Spin spinning={isLoading}></Spin>
          {_t("Reset password is in progress, please wait...")}
        </>
      )}
      {error && <>{_t("Something went wrong")}</>}
      {!key ||
        (!email && <>{_t("Url is not valid, please contact your admin")}</>)}
      {!error && data && (
        <Card>
          <Typography.Title level={4}>
            {_t("Your new password is:")}
            <br />
            <p>{data?.data?.new_password}</p>
            <br />
            <NavLink to={"/login"}>
              <Button type="primary" title={_t("sign_in")} />
            </NavLink>
          </Typography.Title>
        </Card>
      )}
    </div>
  );
}
