import React from "react";
import { Card, Divider, Typography } from "antd";
import StatusLabel from "../../../../../../../../common/components/StatusLabel/StatusLabel";
import { _t } from "../../../../../../../../modules/Common/components/InjectIntlContext";
import { Link, generatePath } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { APP_ROUTES } from "../../../../../../../../system/constants/constantsUrl";
import { ManageableUserList } from "../ManageableUserList";

const ManageableListItem = ({ listInfo }) => {
  const { title, active, id, user_logins, manager_logins } = listInfo;
  const statusLabelText = _t(active ? "active" : "disabled");
  const manageableListPath = generatePath(
    APP_ROUTES.manage.manageable_list_edit,
    {
      id,
    }
  );
  const addUserToList = (userType, id) =>
    generatePath(APP_ROUTES.manage.manageable_list_add_user, {
      id,
      userType,
    });

  return (
    <Card className="manageable-list-card">
      <div className="manageable-list-card__top">
        {/*<StatusLabel status={active} text={statusLabelText} />*/}
        <Typography.Title level={5}>{title}</Typography.Title>
        <Link to={manageableListPath} className="ant-btn ant-btn-primary">
          <EditOutlined />
          {_t("edit")}
        </Link>
      </div>
      <Divider />
      <ManageableUserList
        list={manager_logins}
        listType="manager"
        listId={id}
        title={_t("manager_list")}
        createPath={addUserToList("manager", id)}
      />
      <ManageableUserList
        list={user_logins}
        listType="user"
        listId={id}
        title={_t("user_list")}
        createPath={addUserToList("user", id)}
      />
    </Card>
  );
};

export default ManageableListItem;
