import React, { useEffect, useRef } from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Cascader, Space, Spin, Typography } from "antd";
import {
  useAllowSpecialLandingPage,
  useDenySpecialLandingPage,
  useSpecialLandingPages,
} from "../../apiHooks";
import { SimpleText } from "../inputs/SimpleText";
import { CloseCircleTwoTone } from "@ant-design/icons";

const { Text } = Typography;

type SpecialsProps = {
  user: any;
};

export const SpecialLandingPageWidget = ({ user }: SpecialsProps) => {
  const { data, isLoading, isSuccess } = useSpecialLandingPages({
    user_id: user.id,
  });
  const [
    allowSpecial,
    { isSuccess: isSuccessSpecialLandingPage },
  ] = useAllowSpecialLandingPage();

  const [denySpecial] = useDenySpecialLandingPage();

  useEffect(() => {
    isSuccessSpecialLandingPage && inputEl.current.setValue([]);
  }, [isSuccessSpecialLandingPage]);

  const addLandingPage = (value) => {
    if (value.length === 2) {
      allowSpecial({
        user_id: user.id,
        landing_page_id: value[1],
      });
    }
  };
  const denyProgram = (landing_page_id) => {
    denySpecial({
      user_id: user.id,
      landing_page_id: landing_page_id,
    });
  };

  const landingPagesOptions = isSuccess
    ? Object.keys(data.landings).map((id) => ({
        label: data.landings[id],
        value: id,
      }))
    : undefined;
  const landingPagesAvailableOptions = isSuccess
    ? Object.keys(data.landings_available).map((project) => {
        return {
          label: project,
          value: project,
          children: Object.keys(data.landings_available[project]).map(
            (landing) => ({
              label: data.landings_available[project][landing].name,
              value: data.landings_available[project][landing].landing_page_id,
            })
          ),
        };
      })
    : undefined;

  const inputEl = useRef(null);

  return (
    <Spin spinning={isLoading}>
      <Space style={{ marginBottom: "5px" }}>
        <Text strong>{_t("landing_pages")}</Text>
        <Cascader
          options={landingPagesAvailableOptions}
          onChange={addLandingPage}
          placeholder={_t("select_special_landing")}
          ref={inputEl}
        />
      </Space>
      {landingPagesOptions?.map((landingPage) => {
        return (
          <div key={landingPage.value}>
            <SimpleText>{landingPage.label}</SimpleText>
            <CloseCircleTwoTone
              twoToneColor="red"
              onClick={() => denyProgram(landingPage.value)}
            />
          </div>
        );
      })}
    </Spin>
  );
};
