import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, Select, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { selectDataProps } from "../../../../Chat/helpers";
import { useHistory } from "react-router-dom";
import { useGetSearchUser } from "../../hooks/useGetSearchUser";
import { usePostAddUser } from "../../hooks";

const ManageableListAddUser = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const { id, userType } = useParams();
  const [handleAddUser] = usePostAddUser();
  const [selectData, setSelectData] = useState<selectDataProps>();
  const [searchValue, setSearchValue] = useState("");
  const { data: partnersData, isLoading: partnersIsLoading } = useGetSearchUser(
    {
      search: searchValue,
      userType,
    }
  );
  const clearSearch = () => {
    form.resetFields();
    setSearchValue("");
    setSelectData({ data: [] });
  };
  const handleSubmit = (values) => {
    handleAddUser({
      ...values,
      userType,
    });
    clearSearch();
  };

  const handleChange = (value) => {
    setSearchValue(value);
  };
  const options = selectData?.data.map((option) => {
    return { label: option.login, value: option.id };
  });

  useEffect(() => {
    setSelectData(partnersData);
  }, [partnersData, partnersIsLoading]);

  const selectSettings = {
    showSearch: true,
    value: searchValue,
    placeholder: _t("enter_partner"),
    defaultActiveFirstOption: false,
    showArrow: false,
    filterOption: false,
    onSearch: handleChange,
    onClear: clearSearch,
    notFoundContent: null,
    style: { width: "100%" },
    allowClear: true,
    options,
  };

  const pageTitle =
    userType === "manager" ? _t("add_manager_to_list") : _t("add_user_to_list");
  return (
    <MainLayout>
      <PageTemplate>
        <Card className="manageable-list-info">
          <div className="manageable-list-info__top">
            <Typography.Title level={5}>{pageTitle}</Typography.Title>
          </div>
          <Row>
            <Col span={24} lg={8}>
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item name={"id"} initialValue={id} hidden />
                <Form.Item
                  name={"user_id"}
                  label={_t("Login")}
                  rules={[{ required: true, message: _t("required_field") }]}
                >
                  <Select {...selectSettings} />
                </Form.Item>
                <div className="manageable-list-info__controls">
                  <Button
                    onClick={() => history.goBack()}
                    size="large"
                    shape="round"
                  >
                    {_t("back")}
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    shape="round"
                  >
                    {_t("submit")}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableListAddUser;
