import axiosClient from "../../system/helpers/axiosConfig";

interface RegisterDTO {
  login: string;
  email: string;
  password: string;
  password_confirmation: string;
  phone: string;
  terms: boolean;
}

interface ActivateDTO {
  email: string;
  key: string;
}

interface ResetPasswordDTO {
  email: string;
}

export interface RestorePasswordResponse {
  message: string;
  status: string;
}

export const loginById = (user_id: number) => {
  return axiosClient.get("/api/login-by-id?user_id=" + user_id);
};

export const loginByHash = (hash: string) => {
  return axiosClient.post("/api/login-by-hash", { hash });
};

export const rigister = (data: RegisterDTO) => {
  return axiosClient.post("/api/user/register", data).then((r) => r.data);
};

export const activateUser = (data: ActivateDTO) => {
  return axiosClient.post("/api/user/activate", data);
};

export const restorePassword = (data: ResetPasswordDTO) => {
  return axiosClient.post<RestorePasswordResponse>("/api/user/restore", data);
};
export const resetPassword = (data: ActivateDTO) => {
  return axiosClient.post("/api/user/reset-password", data);
};
