import React from "react";
import { Spin } from "antd";

type RawCodeBlockProps = {
  value: string;
  isLoading?: boolean;
};

const RawCodeBlock: React.FC<RawCodeBlockProps> = ({ value, isLoading }) => {
  return (
    <Spin spinning={isLoading} wrapperClassName={"raw-code"}>
      <div className={"raw-code__value"}>{value}</div>
    </Spin>
  );
};

RawCodeBlock.defaultProps = {
  isLoading: false,
};

export default RawCodeBlock;
