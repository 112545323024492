import React from "react";
import MainLayout from "../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../modules/Layouts/PageTemplate";

const ReportPageWrapper = ({ title, children }) => {
  return (
    <MainLayout>
      <PageTemplate title={title}>{children}</PageTemplate>
    </MainLayout>
  );
};

export default ReportPageWrapper;
