import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { useGetManageableNotifications } from "../../hooks";
import { Button, Checkbox, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import { ManageableNotificationListItem } from "./components";

const ManageableNotificationsList = () => {
  const [withDeleted, setWithDeleted] = useState(false);
  const { data, isLoading, isFetching } = useGetManageableNotifications({
    with_deleted: withDeleted ? 1 : 0,
  });
  const history = useHistory();

  return (
    <MainLayout>
      <PageTemplate title={_t("manage_notifications")}>
        <Button
          htmlType="button"
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(APP_ROUTES.manage.notifications.create)}
        >
          {_t("create_new_notification")}
        </Button>
        <div style={{ marginTop: "30px" }}>
          <Checkbox
            onChange={() => setWithDeleted(!withDeleted)}
            disabled={isLoading}
          >
            {_t("show_deleted_notifications")}
          </Checkbox>
        </div>
        <Spin spinning={isLoading}>
          {data &&
            data?.notifications.map((item) => (
              <ManageableNotificationListItem item={item} key={item.id} />
            ))}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableNotificationsList;
