import React from "react";
import { ChatsListProps } from "../../helpers";
import { ChatPreview } from "../ChatPreview";
import { Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const ChatsList: React.FC<ChatsListProps> = ({
  title,
  chats,
  isAdminList,
  isNewMessagesList,
}) => {
  const chatPreviewItem = (item) => (
    <ChatPreview
      info={item}
      key={item.id}
      isAdminList={isAdminList}
      isNewMessagesList={isNewMessagesList}
    />
  );
  if (chats?.length === 0) {
    return (
      <Typography.Title level={5}>{_t("no_new_msg_title")}</Typography.Title>
    );
  }

  return (
    <div className="chats-list">
      <Typography.Title level={4}>{title}</Typography.Title>
      {chats.map(chatPreviewItem)}
    </div>
  );
};

export default ChatsList;
