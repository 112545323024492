import React, { useCallback } from "react";
import Form from "antd/lib/form";
import { changePasswordRules } from "./constants";
import Input from "antd/lib/input";
import Button from "../../Common/components/Button";
import { Spin } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 24 },
};

type ChangePasswordProps = {
  onSubmit: any;
  isLoading: boolean;
};

const ChangePassword: React.FC<ChangePasswordProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const onFinish = useCallback(
    async (values) => {
      const res = await onSubmit(values);
      return res;
    },
    [form, onSubmit]
  );
  return (
    <Spin spinning={false}>
      <Form
        {...layout}
        form={form}
        labelAlign={"left"}
        name="change_password_form"
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          label={_t("Password")}
          rules={[
            {
              required: true,
              message: _t("input_password_message"),
            },
            {
              min: 6,
              message: _t("min_password_message"),
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          label={_t("confirm_password")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: _t("confirm_password_message"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(_t("error_confirm_password_message"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={_t("current_password")}
          name="old_password"
          rules={changePasswordRules.current_password}
        >
          <Input.Password placeholder={_t("current_password")} />
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            type="primary"
            title={_t("Apply")}
            htmlType="submit"
            disabled={isLoading}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ChangePassword;
