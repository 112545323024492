import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getItem } from '../../../system/helpers/localstorageHelper';
import { ROLES } from '../../../system/constants/roles';
import { defaultRedirect } from '../../../system/constants/roles';

const  RolesWrapper = props => {
  const userRole = getItem('role');
  const { rolesHasAccess, redirect, defaultComponent } = props;
  const hasAcces = rolesHasAccess.indexOf(userRole) !== -1;
  if (!hasAcces && redirect) {
    return (
      <Redirect to={defaultRedirect} />
    );
  }

  if (!hasAcces && !redirect) {
    return defaultComponent;
  }

  return props.children;
}

RolesWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  rolesHasAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirect: PropTypes.bool,
  defaultComponent: PropTypes.element,
}
RolesWrapper.defaultProps = {
  defaultComponent: null,
  redirect: false,
  rolesHasAccess: Object.values(ROLES)
}

export default RolesWrapper;
