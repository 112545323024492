import React from "react";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Layout from "antd/lib/layout";
import ReturnAdmin from "./special/ReturnAdmin";

const MainLayout = (props) => {
  const { headerLinks, withoutHeader } = props;
  return (
    <Layout style={{ maxHeight: "100vh" }}>
      <ReturnAdmin />
      <Sidebar />
      <Layout>
        {!withoutHeader && <Header links={headerLinks} />}
        <div className="main-container">{props.children}</div>
      </Layout>
    </Layout>
  );
};

MainLayout.defaultProps = {
  withoutHeader: false,
  headerLinks: [],
};

export default MainLayout;
