import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createRootReducer from './reducers';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
import authToken from './middleware/authToken';
import axiosMiddleware from './middleware/axiosMiddleware';
// import errorsChecker from './middleware/errorsChecker';
//import axiosMiddleware from 'redux-axios-middleware';

export const history = createHistory();
const routerRedux = routerMiddleware(history);

export default function configureStore(initialState) {

  const middleware = [routerRedux, ReduxThunk, axiosMiddleware, authToken/* , errorsChecker */];
  const devMode = process.env.NODE_ENV === 'development';
  let enhancer;

  if (devMode) {
    const logger = createLogger();
    middleware.push(logger);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = compose(applyMiddleware(...middleware));
  }

  return createStore(createRootReducer(history), initialState, enhancer);
}

export const store = configureStore()
