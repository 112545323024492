import React from "react";
import { useActivateUser } from "../apiHooks";
import { useUrlQuery } from "../utils";
import { ActivateUser } from "../components/ActivateUser";

export const UserActivation = () => {
  const query = useUrlQuery();
  const key = query.get("key");
  const email = query.get("email");

  let userData = null;

  if (key && email) {
    userData = {
      key,
      email,
    };
  }

  return <ActivateUser activateHook={useActivateUser} userData={userData} />;
};
