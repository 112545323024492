import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { useSendOrder } from "../apiHooks";
import OrderForm from "../components/order/OrderForm";
import { Card, Col, Row } from "antd";

const Order = () => {
  const [updateOrder, { isLoading }] = useSendOrder();
  return (
    <PageTemplate title={_t("Order promo")} additionalClass="promo-order">
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Card title={""}>
            <OrderForm isLoading={isLoading} onFinish={updateOrder} />
          </Card>
        </Col>
        <Col span={12}>
          <Card className={"order-card"} title={_t("attention")}>
            {_t("promo_order_attention_message")}
          </Card>
        </Col>
      </Row>
    </PageTemplate>
  );
};

export default Order;
