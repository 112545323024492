import React from "react";
import { Card } from "antd";
import { Tournament } from "../constants";
const { Meta } = Card;

type TournamentPreviewProps = {
  tournament: Tournament;
};

export function TournamentPreview({ tournament }: TournamentPreviewProps) {
  return (
    <Card
      key={tournament.id}
      hoverable
      className={"tournaments-list__post"}
      cover={
        <img
          className={"tournaments-list__post-img"}
          alt="example"
          src={`${tournament.host}${tournament.image}`}
        />
      }
    >
      <Meta
        className={"tournaments-list__post-content"}
        title={
          <>
            <div className={"tournaments-list__post-date"}>
              {tournament.date_from_parsed} - {tournament.date_to_parsed}
            </div>
            <div>{tournament.title}</div>
          </>
        }
        description={
          <div
            dangerouslySetInnerHTML={{ __html: tournament.short_description }}
          ></div>
        }
      />
    </Card>
  );
}
