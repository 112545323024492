import React from "react";
import { Faq } from "../../api";
import classNames from "classnames";

type QAProps = {
  faq: Faq;
  isOpen: boolean;
  setToggle: (id) => void;
};

export function QA({ faq, isOpen, setToggle }: QAProps) {
  return (
    <div
      className={classNames("collapsible-item", isOpen && "expanded")}
      onClick={() => setToggle(isOpen ? 0 : faq.id)}
    >
      <p
        className="collapsible-item__question"
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.question.replace(/<[^>]+>/g, ""),
        }}
      ></p>
      <button
        className="btn expand-collapsible"
        onClick={() => setToggle(isOpen ? 0 : faq.id)}
      />
      <p
        className="collapsible-item__answer"
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.answer.replace(/<[^>]+>/g, ""),
        }}
      ></p>
    </div>
  );
}
