import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { InjectIntlContext } from "../Common/components/InjectIntlContext";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../../system/store";
import MainTemplate from "./MainTemplate";
import { getLocale } from "../../system/helpers/localstorageHelper";
import { useGetLanguages, useGetTranslations } from "../Locales/apiHooks";
import { updateLanguages, updateLocale } from "../Locales/actions";
import { Spin } from "antd";

const SystemTemplate = ({ children }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.locale);

  const {
    data: availableLangs,
    isLoading: isLoadingGetLanguages,
  } = useGetLanguages();
  const [getTranslation, { data, isLoading }] = useGetTranslations();

  const selectedLang = getLocale();
  useEffect(() => {
    const lang = getLocale();
    updateLocale(lang)(dispatch);
    getTranslation(lang).then(() => {
      console.log("translations");
    });
  }, [locale]);

  useEffect(() => {
    if (availableLangs) {
      updateLanguages(availableLangs.languages)(dispatch);
    }
  }, [availableLangs]);

  if (isLoading || isLoadingGetLanguages) {
    return (
      <div className={"h-100per fx-center fxDirCol"}>
        <Spin
          spinning={isLoading}
          style={{ alignItems: "center", justifyContent: "center" }}
        ></Spin>
      </div>
    );
  }
  return (
    <IntlProvider
      locale={selectedLang}
      messages={data?.translations[selectedLang]}
    >
      <InjectIntlContext>
        <ConnectedRouter history={history}>
          <MainTemplate>{children}</MainTemplate>
        </ConnectedRouter>
      </InjectIntlContext>
    </IntlProvider>
  );
};

export default SystemTemplate;
