import React, { useMemo, useCallback, useEffect } from "react";
import { Checkbox, Spin, Row, Col, Collapse, Radio } from "antd";
import Form from "antd/lib/form";
import { _t } from "../../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import { SelectInput } from "../../../Common/components";
import { PromoTrackersResponce, ArchivePromoItemTypes } from "../../constants";
import Button from "../../../Common/components/Button";
import {
  useUpdatePromoMaterial,
  useGetPromoSourceOptions,
} from "../../apiHooks";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const { Panel } = Collapse;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 24 },
};

type UpdatePromoFormProps = {
  isLoadingTrackers: boolean;
  trackersList: PromoTrackersResponce;
  promoItem: ArchivePromoItemTypes;
  updateRawCode: any;
  closeModal: () => any;
};

const UpdatePromoForm: React.FC<UpdatePromoFormProps> = ({
  promoItem,
  isLoadingTrackers,
  trackersList,
  updateRawCode,
  closeModal,
}) => {
  const initialValues = {
    id: promoItem.id,
    source_type: promoItem.source_type,
    use_redirector: promoItem.use_redirector,
    name: promoItem.name,
    tracker_id: promoItem.tracker_id,
    sub_id: promoItem.sub_id,
    sub_id2: promoItem.sub_id2,
    register_url: promoItem.postback_register_url,
    deposit_url: promoItem.postback_deposit_url,
    first_deposit_url: promoItem.postback_first_deposit_url,
    qualification_url: promoItem.postback_qualification_url,
    install_url: promoItem.postback_install_url,
  };

  useEffect(() => {
    updateRawCode(initialValues);
  }, []);

  const [
    updatePromoMaterial,
    { isLoading: isUpdating },
  ] = useUpdatePromoMaterial();
  const { isLoading, data: promoSourceOptions } = useGetPromoSourceOptions({
    id: promoItem.id,
  });
  const modifyData = (data = {}): any => {
    return Object.keys(data).map((item, idx) => ({
      value: item,
      label: Object.values(data)[idx],
    }));
  };

  let timer: number;
  const onValuesChange = (changedVal, allValues) => {
    const data = {
      ...initialValues,
      ...allValues,
      use_redirector: allValues.use_redirector ? 1 : 0,
    };
    clearTimeout(timer);
    timer = window.setTimeout(function () {
      updateRawCode(data);
    }, 1000);
  };
  const DirectLinkType = promoItem.type == 1;
  const VideoType = promoItem.type == 9;
  const hideTrackerSelect = () => !VideoType;
  const hideSubIds = () => DirectLinkType;

  const onSubmit = useCallback(
    async (values) => {
      const data = {
        ...initialValues,
        ...values,
        use_redirector: values.use_redirector ? 1 : 0,
      };
      await updatePromoMaterial(data);
      closeModal();
    },
    [updatePromoMaterial]
  );

  const trackersOptions = useMemo(() => {
    return modifyData(trackersList?.data);
  }, [trackersList]);

  const postbackHelp = () => (
    <NavLink
      to={APP_ROUTES.postback_options}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <QuestionCircleOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </NavLink>
  );

  return (
    <Spin spinning={false}>
      <Form
        {...layout}
        name="update_promo_form"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
        layout="horizontal"
      >
        {promoSourceOptions?.data?.types.length > 1 && (
          <Form.Item name="source_type" label="">
            <Radio.Group>
              {promoSourceOptions?.data?.types.map((item) => {
                return (
                  <Radio key={item.type_id} value={item.type_id}>
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item hidden valuePropName="checked" name={"use_redirector"}>
          <Checkbox>{_t("use_redirector")}</Checkbox>
        </Form.Item>
        <Row gutter={[24, 12]}>
          <Col span={24}>
            <label className={"ant-form__label"}>{_t("Name")}</label>
            <Form.Item name={"name"}>
              <Input placeholder={_t("Name")} />
            </Form.Item>
            {hideTrackerSelect() && (
              <Form.Item label={_t("Tracker")} name="tracker_id" hidden>
                <SelectInput
                  allowClear={true}
                  placeholder={_t("Tracker")}
                  options={trackersOptions}
                  loading={isLoadingTrackers}
                />
              </Form.Item>
            )}
          </Col>
          {hideSubIds() && (
            <>
              <Col span={12}>
                <label className={"ant-form__label"}>{_t("sub_id")}</label>
                <Form.Item label={""} name="sub_id">
                  <Input placeholder={_t("sub_id")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <label className={"ant-form__label"}>{_t("sub_id2")}</label>
                <Form.Item label={""} name="sub_id2">
                  <Input placeholder={_t("sub_id2")} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Collapse
              className={"promo-archive__collapse"}
              defaultActiveKey={[]}
            >
              <Panel
                header={_t("postback_options")}
                key="1"
                extra={postbackHelp()}
              >
                <Form.Item label={_t("register_url")} name="register_url">
                  <Input placeholder={_t("register_url")} />
                </Form.Item>
                <Form.Item label={_t("deposit_url")} name="deposit_url">
                  <Input placeholder={_t("deposit_url")} />
                </Form.Item>
                <Form.Item
                  label={_t("first_deposit_url")}
                  name="first_deposit_url"
                >
                  <Input placeholder={_t("first_deposit_url")} />
                </Form.Item>
                <Form.Item
                  label={_t("qualification_url")}
                  name="qualification_url"
                >
                  <Input placeholder={_t("qualification_url")} />
                </Form.Item>
                <Form.Item label={_t("install_url")} name="install_url">
                  <Input placeholder={_t("install_url")} />
                </Form.Item>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Form.Item className="text-center">
          <Button
            type="primary"
            title={_t("Save")}
            htmlType="submit"
            disabled={isUpdating}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UpdatePromoForm;
