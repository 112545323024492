import axiosClient from "../../../../../system/helpers/axiosConfig";

export const getSearchUser = (params) => {
  const apiPath =
    params.userType === "manager"
      ? "/api/users/admin/managers-list"
      : "/api/users/admin/manageable-users-list";
  return axiosClient
    .get(apiPath, {
      params,
    })
    .then((r) => r.data);
};
