const userInfoRules = {
  first_name: [
    {
      required: false,
      message: "Please input your First Name!",
    },
  ],
  last_name: [
    {
      required: false,
      message: "Please input your Last Name!",
    },
  ],
  phone: [
    {
      required: false,
      message: "Please input your Phone!",
    },
  ],
};

const changePasswordRules = {
  new_password: [
    {
      required: true,
      message: "Please input your New Password!",
    },
  ],
  confirm_password: [
    {
      required: true,
      message: "Please input your Confirm Password!",
    },
  ],
  current_password: [
    {
      required: true,
      message: "Please input your Current Password!",
    },
  ],
};

export { userInfoRules, changePasswordRules };
