import React from "react";
import { Card, Typography } from "antd";
import { AdminChatsSettingForm } from "./index";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const AdminChatsSetting = ({ updateTrigger, setChatFilters }) => {
  return (
    <div>
      <Card className="chats-filter-dropdown">
        <Typography.Title level={5}>{_t("title_filter_msg")}</Typography.Title>
        <AdminChatsSettingForm
          updateTrigger={updateTrigger}
          setChatFilters={setChatFilters}
        />
      </Card>
    </div>
  );
};

export default AdminChatsSetting;
