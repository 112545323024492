export const filterOptionsTypes = {
  numbers: [
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "net_gaming",
    "bonuses",
    "balance",
    "hits",
    "hosts",
    "regs",
    "rounds",
    "qualified_players",
    "first_deposits",
    "deposits",
    "deposits_converted",
    "withdraws",
    "withdraws_converted",
    "ng",
    "ng_converted",
    "cancels",
    "cancels_converted",
    "comppoints",
    "comppoints_converted",
    "casino_income",
    "casino_income_converted",
    "partner_income",
    "partner_income_converted",
    "payouts",
    "regs_all",
    "assured_income",
    "assured_income_converted",
    "hold",
    "hold_converted",
    "installs",
    "multiacc_count",
    "rd",
    "deposits_count",
    "romi",
    "first_deposits1",
    "first_deposits2",
    "first_deposits_diff",
    "first_deposits_rel_diff",
    "hosts1",
    "hosts2",
    "hosts_diff",
    "hosts_rel_diff",
    "in_out2",
    "in_out_diff",
    "in_out_rel_diff",
    "ngcp1",
    "ngcp2",
    "ngcp_diff",
    "ngcp_rel_diff",
    "partner_income1",
    "partner_income2",
    "partner_income_diff",
    "partner_income_rel_diff",
  ],
  strings: [
    "gamer_id",
    "partner_id",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "duplicates",
    "promo_code",
  ],
};
