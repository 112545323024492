import React from "react";
import { isCurrencyField } from "../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../modules/Common/components";

const RenderSubtotalsCell = ({ column, value, convertSymbol }) => {
  if (isCurrencyField(column) && column.includes("_converted"))
    return <CurrencyLabel value={value} symbol={convertSymbol} />;
  if (isCurrencyField(column)) return <CurrencyLabel value={value} />;
  return <>{value}</>;
};
export default RenderSubtotalsCell;
