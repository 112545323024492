import moment from "moment";
import { useFilters } from "./useFilters";
import { useGetReport } from "../api";

export const useTableData = (stateSelector, dispatchCallback, api, key) => {
  const [filters, updateFilters] = useFilters(stateSelector, dispatchCallback);

  const reqFilters = {
    from: moment(filters?.date?.from, "YYYY-MM-DD"),
    to: moment(filters?.date?.to, "YYYY-MM-DD"),
    from2: moment(filters?.date2?.from, "YYYY-MM-DD"),
    to2: moment(filters?.date2?.to, "YYYY-MM-DD"),
    reg_date_from: filters?.rDate?.from
      ? moment(filters?.rDate?.from, "YYYY-MM-DD")
      : undefined,
    reg_date_to: filters?.rDate?.to
      ? moment(filters?.rDate?.to, "YYYY-MM-DD")
      : undefined,
    ...filters,
  };

  const { isLoading, data } = useGetReport(api, key, { ...reqFilters });

  return {
    setFilters: updateFilters,
    filters,
    isLoading,
    data,
    reqFilters,
  };
};
