import { useMutation, useQueryCache } from "react-query";
import { postAdminCloseChat, postAdminOpenChat } from "../../api";
import { API_KEY_GET_CHAT_ADMIN } from "../../helpers";

export const usePostAdminStatusChat = (status, id) => {
  const queryCache = useQueryCache();

  return useMutation(
    () => {
      if (status === 1) return postAdminCloseChat(id);
      return postAdminOpenChat(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.invalidateQueries([API_KEY_GET_CHAT_ADMIN, id]);
      },
    }
  );
};
