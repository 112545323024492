import React, { useEffect } from "react";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { _t } from "../../Common/components/InjectIntlContext";
import { CostsFilters } from "../components/Filters/Filters";
import { useParams } from "react-router-dom";
import { pagesColumn } from "../constants";
import { useGetPostback } from "../apiHooks";
import { useFilters } from "../hooks";
import { Table } from "antd";
import { NoData } from "../../Common/components/NoData";

const PostBackDebug: React.FC = () => {
  const currentPage = useParams().page;
  const [filters, updateFilters] = useFilters();
  useEffect(() => {
    updateFilters({ ...filters, currentPage: currentPage, page: 1 });
  }, [currentPage]);
  const { data, isLoading } = useGetPostback({ ...filters });
  const tableColumns = pagesColumn[currentPage].map((item) => {
    return {
      ...item,
      title: _t(item.title),
    };
  });

  return (
    <PageTemplate
      title={_t("postbacks_debug_info")}
      additionalClass="post-back"
    >
      <CostsFilters />
      <Table
        style={{ overflowX: "auto" }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={data?.data}
        pagination={{
          total: data?.total,
          hideOnSinglePage: true,
          onChange: (v) => {
            updateFilters({ ...filters, page: v });
          },
          current: filters.page,
          pageSize: filters.page_size,
          showSizeChanger: false,
        }}
        locale={{ emptyText: <NoData /> }}
      />
    </PageTemplate>
  );
};

export default PostBackDebug;
