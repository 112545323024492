import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import {
  createOptions,
  setFormInputValue,
} from "../../../../../common/helpers";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { Link } from "react-router-dom";
import { useGetProgramStatuses, useGetProgramTypes } from "../../hooks";
import { ProgramOptions } from "./index";
import { AffiliateProgramType } from "../../utils";
import { useGetAvailableLanguages } from "../../hooks/useGetLandingPageLanguages";
import AffiliateProgramFormItemTitle from "./AffiliateProgramFormItemTitle";

interface AffiliateProgramFormProps {
  info?: any;
  create?: boolean;
  project_id?: number;
  sendProgram: any;
}
const AffiliateProgramForm: React.FC<AffiliateProgramFormProps> = ({
  info,
  create,
  project_id,
  sendProgram,
}) => {
  const [form] = Form.useForm();
  const {
    data: statuses,
    isLoading: isStatusesLoading,
  } = useGetProgramStatuses();
  const { data: types, isLoading: isTypesLoading } = useGetProgramTypes();
  const [programType, setProgramType] = useState<
    AffiliateProgramType | undefined
  >();
  const {
    data: titleLanguages,
    isLoading: titleLanguagesIsLoading,
    isSuccess: titleLanguagesIsSuccess,
  } = useGetAvailableLanguages();

  useEffect(() => {
    setProgramType(types?.types[!create ? info?.type : "ngfc-4"]);
  }, [types]);

  const handleProgramType = (e) => {
    setProgramType(types.types[e]);
    setFormInputValue("type", e, form);
  };
  const handleProgramStatus = (e) => {
    setFormInputValue("status", e, form);
  };
  const sendForm = (values) => {
    for (let i in values) {
      if (values[i] === null || values[i] === undefined) delete values[i];
    }
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }

    sendProgram(formData);
  };

  return (
    <Card>
      <Form
        className="affiliate-program-form"
        form={form}
        layout="vertical"
        onFinish={sendForm}
      >
        {!create && (
          <Form.Item name="id" hidden initialValue={info?.id}>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="project_id"
          hidden
          initialValue={!create ? info?.project_id : project_id}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col lg={24}>
            <AffiliateProgramFormItemTitle
              info={info}
              titleLanguages={titleLanguages}
            />
            <Row gutter={30}>
              <Col span={24} lg={8}>
                <Form.Item
                  name="status"
                  label="Status"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  initialValue={!create ? info?.status + "" : "1"}
                >
                  <Select
                    options={createOptions(statuses?.statuses)}
                    loading={isStatusesLoading}
                    onChange={handleProgramStatus}
                  />
                </Form.Item>
              </Col>
              <Col span={24} lg={8}>
                <Form.Item
                  name="type"
                  label="Type"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  initialValue={!create ? info?.type : "ngfc-4"}
                >
                  <Select
                    options={createOptions(types?.types, "name")}
                    loading={isTypesLoading}
                    onChange={handleProgramType}
                  />
                </Form.Item>
              </Col>
              <Col span={24} lg={8}>
                <Form.Item
                  name="formula"
                  label="Formula"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  initialValue={!create ? info?.formula : ""}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Space style={{ margin: "40px 0" }}>
          <Button
            htmlType="submit"
            title={_t("submit")}
            type="primary"
            size="large"
            shape="round"
          >
            {_t("submit")}
          </Button>

          {/*<Link*/}
          {/*  to={APP_ROUTES.affiliates_programs.brands}*/}
          {/*  className="ant-btn ant-btn-lg ant-btn-round"*/}
          {/*>*/}
          {/*  Create Tier Commission*/}
          {/*</Link>*/}
          <Link
            to={APP_ROUTES.affiliates_programs.brands}
            className="ant-btn ant-btn-lg ant-btn-round"
          >
            {_t("back")}
          </Link>
        </Space>
        <Typography.Title level={4}>Program options</Typography.Title>
        <Row gutter={30} className="affiliate-program-options">
          <Col lg={12} hidden={!programType?.cpa}>
            <ProgramOptions
              options={!create ? JSON.parse(info?.options) : {}}
              program="cpa"
              form={form}
            />
          </Col>
          <Col lg={12} hidden={!programType?.revshare}>
            <ProgramOptions
              options={!create ? JSON.parse(info?.options) : {}}
              program="revshare"
              form={form}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AffiliateProgramForm;
