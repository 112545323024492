export const actionTypes = {
  UPDATE_FILTERS: "promo/update-filters",
};

export const updateCostsFilters = (filters) => {
  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      ...filters,
    },
  };
};
