import { useMutation, useQueryCache } from "react-query";
import { postTestimonialStatus } from "../api";
import { API_KEY_GET_TESTIMONIAL_LIST } from "../constants";

export const usePostTestimonialStatus = () => {
  const queryCache = useQueryCache();
  return useMutation(postTestimonialStatus, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_TESTIMONIAL_LIST);
    },
  });
};
