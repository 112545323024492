import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Card, Col, Row } from "antd";
import ApiLogs from "../components/ApiLogs";
import Subscriptions from "../components/Subscriptions";
import CSVSettings from "../components/CSVSettings";
import {
  useGenerateCSVKey,
  useGetCSVAccess,
  useEnableCSVSettings,
} from "../apiHooks";
import { useSelector, shallowEqual } from "react-redux";

const Other: React.FC = () => {
  const [updateCSVKey, { isLoading: isUpdateKey }] = useGenerateCSVKey();
  const [getCSVAccess, { isLoading: isUpdateAccess }] = useGetCSVAccess();
  const [
    updateCSVSettings,
    { isLoading: isUpdateCSVSettings },
  ] = useEnableCSVSettings();
  const basicInfo = useSelector((state) => state.user.basicInfo, shallowEqual);

  return (
    <PageTemplate title={_t("Other information")}>
      <Row gutter={[16, 16]}>
        <Col md={24} xl={12}>
          <Card title={_t("Email Subscriptions")}>
            <Subscriptions />
          </Card>
        </Col>
        <Col md={24} xl={12}>
          <Card title={_t("Authentication log")}>
            <ApiLogs />
          </Card>
        </Col>
        <Col md={24} xl={12}>
          <Card title={_t("CSV Settings")}>
            <CSVSettings
              onGenerateKey={updateCSVKey}
              isUpdateKey={isUpdateKey}
              csv_key={basicInfo.csv_key}
              csv_allowed_ips={basicInfo.csv_allowed_ips || [""]}
              csv_settings={basicInfo.csv_settings}
              isUpdateAccess={isUpdateAccess}
              getCSVAccess={getCSVAccess}
              updateCSVSettings={updateCSVSettings}
              isUpdateCSVSettings={isUpdateCSVSettings}
            />
          </Card>
        </Col>
      </Row>
    </PageTemplate>
  );
};

export default Other;
