import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { usePostSearchPartner } from "../../hooks";
import { removeEmptyValues } from "../../helpers/removeEmptyValues";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const PartnersSearch = (props) => {
  const {
    selectData,
    setSelectData,
    searchValue,
    setSearchValue,
    clearSearch,
    isMultiple,
  } = props;
  const [
    searchPartner,
    { data: partnersData, isLoading: partnersIsLoading },
  ] = usePostSearchPartner();

  const handleSearch = (value) => {
    if (value) {
      searchPartner({
        q: value,
      });
    } else {
      setSelectData({ data: [] });
    }
  };

  const handleChange = (value) => {
    setSearchValue(value);
  };
  const options = selectData?.data.map((option) => {
    return { label: option.login, value: option.id };
  });

  useEffect(() => {
    setSelectData(partnersData);
  }, [partnersData, partnersIsLoading]);

  const settings = removeEmptyValues({
    showSearch: true,
    value: searchValue,
    placeholder: _t("enter_partner"),
    defaultActiveFirstOption: false,
    showArrow: false,
    filterOption: false,
    onSearch: handleSearch,
    onChange: handleChange,
    onClear: clearSearch,
    notFoundContent: null,
    style: { width: "100%" },
    allowClear: true,
    mode: isMultiple ? "multiple" : "",
    options,
  });
  return <Select {...settings} />;
};

export default PartnersSearch;
