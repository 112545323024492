import { createTracker, getTrackersList, GetTrackersRequest } from "./api";
import { useQuery, useMutation, queryCache } from "react-query";
import { notify } from "../../system/utils/notification";
import { getUserId } from "../../system/helpers/localstorageHelper";

const TrackerList = "trackers";

export const useTrackerList = (
  params: GetTrackersRequest = { user_id: getUserId() }
) => {
  return useQuery([TrackerList, params], (k, params) =>
    getTrackersList(params)
  );
};

export const useTrackerCreate = (userId = getUserId()) => {
  return useMutation(
    ({ name }: { name: string }) => createTracker({ name, user_id: userId }),
    {
      onSuccess: (data) => {
        queryCache.invalidateQueries(TrackerList);
        // notify(`success', 'Tracker created, id: ${data.data.id}`);
      },
      onError: (error) => {
        if ((error as any)?.response?.data?.error?.name) {
          notify(
            "error",
            (error as any)?.response?.data?.error?.name?.join("/n")
          );
        }
      },
      throwOnError: true,
    }
  );
};
