import React from "react";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
const EditMsg = ({ editMsg, setEditMsg }) => {
  const handleEditCLose = () => {
    setEditMsg({
      ...editMsg,
      text: editMsg.prevText,
      editable: false,
    });
  };
  return (
    <div className="chat__edit-msg">
      <button onClick={handleEditCLose} className="chat__edit-close">
        <CloseCircleOutlined />
      </button>
      <p className="chat__edit-text">"{editMsg?.prevText}"</p>
      <EditOutlined className="chat__edit-icon" /> {_t("edit_msg")}
    </div>
  );
};

export default EditMsg;
