import React from "react";
import { Space } from "antd";
import { Button } from "./index";
import { _t } from "./InjectIntlContext";
import { SearchOutlined } from "@ant-design/icons";

const AntFilter = ({
  Component,
  handleSearch,
  handleReset,
  selectedKeys,
  confirm,
  dataIndex,
  searchProp,
  clearFilters,
  params,
  componentStyle,
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Component style={componentStyle} {...params} />
      <Space>
        <Button
          title={_t("search")}
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, searchProp)
          }
          icon={<SearchOutlined />}
          size="small"
        />
        <Button
          title={_t("reset")}
          type="ghost"
          onClick={() => handleReset(clearFilters, searchProp)}
          size="small"
        />
      </Space>
    </div>
  );
};

AntFilter.defaultProps = {
  componentStyle: { marginBottom: 8, display: "block" },
};

export default AntFilter;
