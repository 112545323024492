import React from "react";
import Input, { InputProps } from "antd/lib/input";

const SimpleInput = (props: InputProps) => {
  return <Input {...props} />;
};

const defaultProps: Partial<InputProps> = {
  placeholder: "Enter value",
  size: "large",
  allowClear: true,
};

SimpleInput.defaultProps = defaultProps;

export default SimpleInput;
