import React, { useState } from "react";
import { Button } from "antd/es";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Modal } from "antd";
import { AffiliatesGeoReportDetail } from "../../pages";

const AffiliateGeoDetail = (partner_id) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)}>{_t("details")}</Button>
      {isModalOpen && (
        <Modal
          visible={true}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          width={"auto"}
        >
          <AffiliatesGeoReportDetail partnerId={partner_id} />
        </Modal>
      )}
    </div>
  );
};

export default AffiliateGeoDetail;
