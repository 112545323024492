import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Input, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { TranslationKeyCardType } from "./types";

const TranslationKeyCard: React.FC<TranslationKeyCardType> = ({
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const pageTitle = _t("translation_key_create_name");

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{pageTitle}</Typography.Title>
      </div>
      <Form onFinish={handleSubmit} layout="vertical" form={form}>
        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {_t("translation_key_card_title")}
        </Typography.Title>
        <Form.Item
          name="key"
          label={_t("translation_card_key")}
          rules={[
            {
              required: true,
              message: _t("translation_key_field_required"),
            },
          ]}
        >
          <Input placeholder={_t("translation_key_placeholder_key")} />
        </Form.Item>
        <Form.Item
          name="default_message"
          label={_t("translation_placeholder_default_message")}
          rules={[
            {
              required: true,
              message: _t("default_message_field_required"),
            },
          ]}
        >
          <Input
            placeholder={_t("translation_key_placeholder_default_message")}
          />
        </Form.Item>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TranslationKeyCard;
