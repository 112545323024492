import {
  ByDaysRequest, ByInvitesRequest, BySubaffiliatesRequest,
  getByDays, getByInvites, getBySubAffiliates,
  getTotals,
} from "./api";
import {useQuery} from "react-query";

const Totals = "totals";
const ByDays = "by-days";
const ByInvites = "by-invites";
const BySubAffiliates = "by-subaffiliates";

export const useSubaffiliatesTotals = (params = {}) => {
  return useQuery([Totals, params], (k, params) =>
    getTotals(params)
  );
};


export const useByDays = (params: ByDaysRequest) => {
  return useQuery([ByDays, params], (k, params) =>
    getByDays(params)
  );
};

export const useByInvites = (params: ByInvitesRequest) => {
  return useQuery([ByInvites, params], (k, params) =>
    getByInvites(params)
  );
};

export const useBySubAffiliates = (params: BySubaffiliatesRequest) => {
  return useQuery([BySubAffiliates, params], (k, params) =>
    getBySubAffiliates(params)
  );
};
