import React from "react";
import { useFilters } from "../../hooks";
import { usePromoMaterials } from "../../apiHooks";
import ArchivePromoItem from "../../../Common/components/ArchivePromoItem";
import { Spin, Pagination, Empty } from "antd";

type PromoDataProps = {};

const PromoData: React.FC<PromoDataProps> = () => {
  const [filters, updateFilters] = useFilters();
  const { isLoading, data } = usePromoMaterials(filters);
  const onPageChange = (page) => updateFilters({ page: page });
  const totalData = data?.data?.totals.total;
  if (typeof totalData === "number" && totalData === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Spin spinning={isLoading}>
      {data?.data?.promos &&
        Object.values(data?.data?.promos)
          .reverse()
          .map((item) => {
            return <ArchivePromoItem key={item.id} promo={item} />;
          })}
      <Pagination
        current={filters.page}
        defaultCurrent={filters.page}
        total={data?.data?.totals?.total}
        onChange={onPageChange}
        hideOnSinglePage
        showSizeChanger={false}
        pageSize={data?.data?.totals?.per_page || 20}
      />
    </Spin>
  );
};

export default PromoData;
