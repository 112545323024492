import React from "react";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Spin } from "antd";

interface MailsProps {
  mailsPath: string;
  useHook: any;
  isAdmin?: boolean;
}
const Mails: React.FC<MailsProps> = ({ mailsPath, useHook, isAdmin }) => {
  const params = isAdmin ? ["inbox", { page: 1 }] : [{ page: 1 }];
  const { data, isLoading } = useHook(...params);
  const hasNewMessages = data?.data?.unread_conversations?.length > 0;
  const newMessages =
    data?.data?.unread_conversations?.length < 100
      ? data?.data?.unread_conversations?.length
      : "99+";

  return (
    <Link
      to={mailsPath}
      className={classNames("user-profile-preview__mails", {
        "user-profile-preview__mails--active": hasNewMessages,
      })}
    >
      <Spin spinning={isLoading}>
        <MailOutlined />
      </Spin>
      {hasNewMessages && (
        <span className="user-profile-preview__mails-amount">
          {newMessages}
        </span>
      )}
    </Link>
  );
};

export default Mails;
