import React, { useMemo } from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { DashboardFilters } from "../components/Filters/Filters";
import { Space, Divider, Form } from "antd";
import { TableTotals } from "../components/Totals/TableTotals";
import { CollapsebleChart } from "../components/Charts/LineChart";
import { useFilters } from "../hooks";
import { useGeneralReportList, useGeneralReportListFile } from "../apiHooks";
import { addAllOption } from "../utils";
import { RadioSelect } from "../../Common/components/RadioSelect";
import { allTrackerColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import {ExcelButton} from "../components/Filters/ExcelButton";

const useTrackerData = () => {
  const scopes = { day: "by-days-trackers", total: "by-trackers" };
  const [
    { date, page_size, period, columns, tracker_id, page, groupBy },
  ] = useFilters();
  const showTrackerField = groupBy !== "total";
  const scope = scopes[groupBy];
  const reqFilters = {
    from: date.from,
    to: date.to,
    scope: scope,
    tracker_id: tracker_id,
  };

  const { isLoading, data } = useGeneralReportList({
    ...reqFilters,
    page_size,
    page,
  } as any);

  const chartFilters = {
    from: date.from,
    to: date.to,
    period,
    tracker_id: showTrackerField ? tracker_id : null,
    columns,
  };
  const totals = data?.totals;
  const trackersOptions = useMemo(() => {
    return addAllOption(
      data?.trackers?.map((tracker) => ({
        label: tracker.name,
        value: tracker.id,
      }))
    );
  }, data?.trackers);

  return {
    isLoading,
    data,
    totals,
    chartFilters,
    trackersOptions,
    showTrackerField,
    reqFilters,
  };
};

export const Trackers = () => {
  const {
    data,
    isLoading,
    totals,
    chartFilters,
    trackersOptions,
    showTrackerField,
    reqFilters,
  } = useTrackerData();

  const [getFile, { isLoading: isFileLoading }] = useGeneralReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <Space
        size={0}
        direction="vertical"
        style={{ width: "100%", display: "flex" }}
      >
        <DashboardFilters
          fields={
            <>
              {showTrackerField && (
                <Form.Item name="tracker_id">
                  <RadioSelect
                    defaultValue={null}
                    loading={isLoading}
                    allowClear={false}
                    options={trackersOptions}
                    dropdownStyle={{ minWidth: 150 }}
                  />
                </Form.Item>
              )}
              <ExcelButton
                clickHandler={clickHandler}
                isLoading={isFileLoading}
              />
            </>
          }
        />
        <Divider style={{ margin: 0 }} />
        <CollapsebleChart filters={chartFilters} />
      </Space>

      <TableTotals totals={totals} isLoading={isLoading} />
      <ReportTableWidget
        data={data}
        isLoading={isLoading}
        allColumns={allTrackerColumns}
        showGroupBy
      />
    </PageTemplate>
  );
};
