import React from "react";

const Percent = (value) => {
  const percentStyles = (value) => {
    if (value > 0) {
      return value >= 1000 ? { color: "orange" } : { color: "#27ae60" };
    }
    if (value < 0) {
      return {
        color: "#e42320",
      };
    }
  };
  const percent = Number.parseFloat(value).toFixed(1);
  return (
    <span style={percentStyles(value)}>
      {value > 0 && "+"}
      {value >= 1000 ? "much" : percent.replace(".0", "")}%
    </span>
  );
};

export default Percent;
