import { renderCellValue } from "./renderCellValue";

const columnCellValue = (key) => {
  return {
    [key]: {
      title: key,
      dataIndex: key,
      render: (text, record) => renderCellValue(key, text, record),
    },
  };
};

export const createValueInColumnCell = (tableData) => {
  return Object.assign(
    {},
    ...tableData.map((key) => {
      return columnCellValue(key);
    })
  );
};
