import React from "react";
import { Card, Checkbox } from "antd";
import classNames from "classnames";

type PromoCardProps = {
  img?: string;
  checked?: boolean;
  info?: React.ReactNode;
  size?: string;
};

const PromoCard: React.FC<PromoCardProps> = ({ size, info, img, checked }) => {
  return (
    <Card
      className={classNames(
        "promo-card",
        checked && "promo-card__selected",
        `promo-card__${size}`
      )}
    >
      <div className={"promo-card__img"}>
        <img src={img} alt={"PromoCard Img"} />
      </div>
      <div className={"promo-card__info"}>{info}</div>
      <Checkbox checked={checked} />
    </Card>
  );
};

PromoCard.defaultProps = {
  size: "lg",
};

export default PromoCard;
