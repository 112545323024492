export const fillTranslations = (languages, values) => {
  const translations = {};
  Object.values(languages).map((item: any) => {
    translations[item.code] = {
      lang: values[`translations[${item.code}][lang]`],
      question: values[`translations[${item.code}][question]`],
      answer: values[`translations[${item.code}][answer]`],
    };
  });
  return translations;
};
