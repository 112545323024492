import axiosClient from "../../../../system/helpers/axiosConfig";
import { responseFile } from "../../../../system/helpers/requestHelper";

export const getReportFile = (api, params, fileName) => {
  return axiosClient
    .post(api, params, {
      responseType: "blob",
    })
    .then((r) => {
      return responseFile(r, `${fileName}_${params.from}_${params.to}.xlsx`);
    });
};
