import React, { useEffect, useState } from "react";
import { StarOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { usePostAdminChatAddBookmark } from "../../../hooks";
import { Spin } from "antd";

const BookMarkButton = ({ marked, id }) => {
  const [check, setCheck] = useState(marked);
  const [addToBookmark, { isLoading }] = usePostAdminChatAddBookmark();

  useEffect(() => {
    setCheck(marked);
  }, [marked]);

  const handleAddToBookmark = () => {
    check === 0 ? setCheck(1) : setCheck(0);
    addToBookmark(id);
  };
  return (
    <button
      className={classNames(
        "chat-preview__control-btn chat-preview__bookmark",
        { active: check === 1 }
      )}
      onClick={handleAddToBookmark}
      disabled={isLoading}
    >
      <Spin spinning={isLoading}>
        <StarOutlined />
      </Spin>
    </button>
  );
};

export default BookMarkButton;
