import { useGetDataWithoutParams } from "../../../../../common/hooks/useGetDataWithoutParams";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { createOptions } from "../../../../../common/helpers";

const useGetAdditionalFilterOptions = () => {
  const { data, isSuccess } = useGetDataWithoutParams(
    API_ROUTES.reports.filters.statuses.key,
    API_ROUTES.reports.filters.statuses.path
  );

  const additionalFilterOptions = {
    status_label: createOptions(data?.statuses),
    role_label: createOptions(data?.roles),
    traffic_type_label: createOptions(data?.traffic_types),
    cash_type_label: createOptions(data?.cash_types),
  };

  return [additionalFilterOptions, isSuccess];
};

export default useGetAdditionalFilterOptions;
