import React from "react";
import { useTableData } from "../../hooks/table";
import { useGetReportFile } from "../../hooks/api";
import { ExcelButton } from "../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { ReportTableWrapper } from "../ReportTableWrapper";
import { createColumnOptions } from "../../utils/helpers/tableRendering/createColumnOptions";
import { createColumns } from "../../utils";

const ReportPage = ({ config, ReportFilters }) => {
  const {
    columns,
    hiddenColumns,
    columnsKey,
    stateSelector,
    api,
    fileName,
    apiKey,
    dispatchCallback,
    filteredColumns,
    subtotals,
    additionalFilterOptions,
  } = config;
  const { setFilters, filters, data, isLoading, reqFilters } = useTableData(
    stateSelector,
    dispatchCallback,
    api,
    apiKey
  );
  const [getFile, { isLoading: isFileLoading }] = useGetReportFile(
    api,
    fileName,
    reqFilters
  );

  const clickHandler = () => {
    getFile();
  };
  return (
    <div>
      <ReportFilters
        setFilters={setFilters}
        initialValues={filters}
        excelButton={
          <ExcelButton clickHandler={clickHandler} isLoading={isFileLoading} />
        }
      />
      <ReportTableWrapper
        data={data}
        isLoading={isLoading}
        allColumns={createColumns(columns)}
        hiddenColumns={hiddenColumns}
        visibleColumnsKey={columnsKey}
        columnsOptions={createColumnOptions(columns, hiddenColumns)}
        setFilters={setFilters}
        stateSelector={stateSelector}
        dispatchCallback={dispatchCallback}
        filteredColumns={filteredColumns}
        additionalFilterOptions={additionalFilterOptions}
        subtotalsColumns={subtotals}
      />
    </div>
  );
};

export default ReportPage;
