import React from "react";
import { Typography } from "antd";
import { Link, generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";

const AffiliateProgramExpandInfo = ({
  status,
  options,
  specialUsers,
  id,
  optionsDrop,
  specialUsersDrop,
}) => {
  return (
    <div className="program-expand-content">
      {optionsDrop.includes(id) && (
        <div className="program-expand-content__item">
          <Typography.Title level={4}>Options</Typography.Title>
          <pre>{JSON.stringify(JSON.parse(options), null, 2)}</pre>
        </div>
      )}

      {status !== 1 && specialUsersDrop.includes(id) && (
        <div className="program-expand-content__item">
          <Typography.Title level={4}>Users</Typography.Title>

          {
            <ul className="program-user-list">
              {specialUsers.map((item) => {
                const { id, login } = item;
                const userProfilePath = generatePath(
                  APP_ROUTES.manage.user_profile,
                  {
                    partnerId: id,
                  }
                );

                return (
                  <li key={id}>
                    <Link to={userProfilePath} target="_blank">
                      {login}
                    </Link>
                  </li>
                );
              })}
            </ul>
          }
        </div>
      )}
    </div>
  );
};

export default AffiliateProgramExpandInfo;
