import React from "react";
import Mails from "./Mails";
import { useGetInstantMessagesList } from "../../Chat/hooks";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const UserMails = () => {
  return (
    <Mails
      mailsPath={APP_ROUTES.chat.list}
      useHook={useGetInstantMessagesList}
    />
  );
};

export default UserMails;
