import React from "react";
import ErrorTemplate from "../../../modules/Layouts/ErrorTemplate";
import { Link } from "react-router-dom";
import { _t } from "../../../modules/Common/components/InjectIntlContext";

const Page404 = () => {
  return (
    <ErrorTemplate>
      <div className="error-page-content">
        <div className="error-page-content__code">404</div>
        <h1 className="error-page-content__title">{_t("error_404_title")}</h1>
        <p className="error-page-content__text">{_t("error_404_text")}</p>
        <div className="error-page-content__btn">
          <Link
            to="/"
            className="ant-btn ant-btn-primary"
          >
            {_t("back_to_main")}
          </Link>
        </div>
      </div>
    </ErrorTemplate>
  );
};

export default Page404;
