import React from 'react';
import {Card} from "antd";

const AuthTemplate = ({children, title}) => {
  return (
    <div className="auth-wrapper">
      <Card className="auth-wrapper__content" title={title}>
        {children}
      </Card>
    </div>
  );
};

export default AuthTemplate;