import axiosClient from "../../../../../system/helpers/axiosConfig";

export const postRemoveUserFromList = (params) => {
  return axiosClient
    .post(
      `api/admin/manageable-list/${params.id}/${params.type}/delete`,
      params
    )
    .then((r) => r.data);
};
