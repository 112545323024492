import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
} from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { FaqContentCardType } from "./types";
import { FaqCategorySelect } from "../FaqCategorySelect";

const FaqCard: React.FC<FaqContentCardType> = ({
  initialValues,
  isEdit,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { languages } = useSelector((state) => state.locale);

  const pageTitle = isEdit ? _t("faq_edit_title") : _t("faq_create_title");

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{pageTitle}</Typography.Title>
      </div>
      <Form onFinish={handleSubmit} layout="vertical" form={form}>
        {isEdit && (
          <Form.Item name="id" initialValue={initialValues?.id}>
            <Input type="hidden" />
          </Form.Item>
        )}
        <FaqCategorySelect initialValue={initialValues?.category?.id} />
        <Form.Item
          name="position"
          label={_t("position")}
          initialValue={initialValues?.position}
          rules={[
            {
              required: true,
              message: _t("position_field_required"),
            },
          ]}
        >
          <Input type="number" placeholder={_t("position")} />
        </Form.Item>
        <Form.Item
          name="visible"
          label={_t("visible")}
          initialValue={isEdit ? !!initialValues?.visible : true}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Divider />
        <Row>
          <Col>
            <Typography.Title level={5} style={{ marginBottom: "15px" }}>
              {_t("faq_card_languages_title")}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={14}>
          {languages &&
            Object.values(languages).map((item: any) => (
              <Col lg={12} span={24} key={item.id}>
                <Form.Item
                  name={`translations[${item.code}][lang]`}
                  initialValue={item.code}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  name={`translations[${item.code}][question]`}
                  label={item.name}
                  initialValue={
                    initialValues?.translations[item.code]?.question
                  }
                  rules={[
                    {
                      required: true,
                      message: _t("question_field_required"),
                    },
                  ]}
                >
                  <Input placeholder={_t("faq_placeholder_question")} />
                </Form.Item>
                <Form.Item
                  name={`translations[${item.code}][answer]`}
                  initialValue={initialValues?.translations[item.code]?.answer}
                  rules={[
                    {
                      required: true,
                      message: _t("answer_field_required"),
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    placeholder={_t("faq_placeholder_answer")}
                  />
                </Form.Item>
              </Col>
            ))}
        </Row>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default FaqCard;
