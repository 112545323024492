import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space } from "antd";
import { Button, SimpleInput } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";

export const ProfileSubmitForm = ({
  selected,
  name,
  setEditable,
  textValue,
  initialValues,
  onSubmit,
  isLoading,
  children,
}) => {
  const [form] = Form.useForm();
  const saveText = _t("Save");
  if (selected !== name) {
    return (
      <span onClick={() => setEditable(name)}>
        {textValue}
        <EditOutlined />
      </span>
    );
  }
  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Row align="middle" className="users-form" gutter={5}>
        <Col flex="auto">
          <Space>
            <Form.Item hidden name={"id"}>
              <SimpleInput />
            </Form.Item>
            <Form.Item hidden name={"field_name"}>
              <SimpleInput />
            </Form.Item>
            <Form.Item name={"value"} noStyle>
              {children}
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={isLoading}
                type="primary"
                title={saveText}
                htmlType="submit"
              ></Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
