import React, { useState } from "react";
import { Button, Space, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { AffiliateLandingPagesList } from "./index";
import { getItem } from "../../../../../system/helpers/localstorageHelper";
import { handleStatusType } from "../../../../../common/helpers";

interface AffiliateLandingPagesProps {
  id: number;
}
const AffiliateLandingPages: React.FC<AffiliateLandingPagesProps> = ({
  id,
}) => {
  const [status, setStatus] = useState(
    getItem(`${id}-landings-status`) || "public"
  );

  return (
    <div className="affiliate-landing-pages">
      <Space style={{ margin: "30px 0" }}>
        <Typography.Title level={5} style={{ marginBottom: "0" }}>
          {_t("landing_pages")}
        </Typography.Title>
        <Button
          shape="round"
          onClick={() =>
            handleStatusType(status, setStatus, `${id}-landings-status`)
          }
          icon={status === "all" ? <UpOutlined /> : <DownOutlined />}
        >
          {status === "all" ? _t("show_public") : _t("show_all")}
        </Button>
      </Space>
      <AffiliateLandingPagesList status={status} id={id} />
    </div>
  );
};

export default AffiliateLandingPages;
