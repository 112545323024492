import React from "react";
import { useParams } from "react-router-dom";
import { ManageableItemShape } from "../../components";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { usePostManageableListItemSend } from "../../hooks";

const ManageableItemCreate = () => {
  const { id } = useParams();
  const [updateListItem] = usePostManageableListItemSend(id);

  const handleEditSubmit = (values) => {
    values.active = values.status;
    delete values.status;
    updateListItem(values);
  };

  return (
    <MainLayout>
      <PageTemplate>
        <ManageableItemShape submit={handleEditSubmit} />
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableItemCreate;
