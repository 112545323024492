import axiosClient from "../../system/helpers/axiosConfig";

export interface ApiLogsRequest {
  user_id: number | string;
}

export interface getSubscriptionsRequest {
  user_id: number | string;
}

export interface updateSubscriptionsRequest {
  user_id?: number | string;
  "mailing_type[2]": number | boolean;
  "mailing_type[3]": number | boolean;
  "mailing_type[4]": number | boolean;
  "mailing_type[6]": number | boolean;
  subscription_language?: string;
}

export interface ApiLogsData {
  timestamp: string;
  ip: string;
}

export interface ApiLogsResponse {
  data: ApiLogsData[];
}

type mailingsTypes = {
  title: string;
  unsubscribed: number;
};

type languagesTypes = {
  title: string;
  active: number;
};

export interface Tracker {
  mailings: { "1": mailingsTypes };
  languages: { en: languagesTypes };
}

interface CreateTrackerResponse {
  data: Tracker;
}

export interface changeUserNameRequest {
  user_id: number | string;
  username: string;
}

export interface changeUserPasswordRequest {
  password: string;
  password_confirmation: string;
  old_password: string;
}

type CSVSettings = {
  csv_settings: string;
  csv_key: string;
  csv_allowed_ips: string[];
};

export type enableCSVSettingsRequest = {
  csv_enable: number;
  ips: string[];
};

export type generateCSVKeyResponce = {
  payload: {
    data: {
      settings: CSVSettings;
    };
  };
};

export const getApiLogs = ({ user_id }: ApiLogsRequest) => {
  return axiosClient
    .post(`/api/users/ip-log`, {
      user_id,
    })
    .then((r) => {
      return r.data.data;
    });
};

export const getSubscriptions = ({ user_id }: getSubscriptionsRequest) => {
  return axiosClient
    .post<CreateTrackerResponse>(`/api/users/subscriptions`, {
      user_id,
    })
    .then((r) => {
      return r.data.data;
    });
};

export const updateSubscriptions = (data: updateSubscriptionsRequest) => {
  return axiosClient.post(`/api/users/unsubscribe`, data).then((r) => {
    return r.data;
  });
};

export const changeUserName = (data: changeUserNameRequest) => {
  return axiosClient.post(`/api/users/change-username`, data).then((r) => {
    return r.data;
  });
};

export const changeUserPassword = (data: changeUserPasswordRequest) => {
  return axiosClient.post(`/api/users/change-password`, data).then((r) => {
    return r.data;
  });
};

export const getCSVAccess = () => {
  return axiosClient.get("/api/users/request-csv").then((r) => r.data);
};

export const enableCSVSettings = (data: enableCSVSettingsRequest) => {
  return axiosClient.post(`/api/users/enable-csv`, data).then((r) => {
    return r.data;
  });
};
