import React from "react";

type InlineInfoProps = {
  label: string;
  data: string | number;
};

const InlineInfo: React.FC<InlineInfoProps> = ({ label, data }) => {
  return (
    <div className={"inline-info"}>
      <label className={"inline-info__label"}>{label}:</label>
      <span className={"inline-info__data"}>{data}</span>
    </div>
  );
};

export default InlineInfo;
