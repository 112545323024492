import React from "react";
import {
  LineChart as RLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useChartData } from "../../apiHooks";
import { theme } from "./theme";
import moment from "moment";
import { Spin, Empty, Collapse } from "antd";
import { ChartFilters } from "../Filters/Filters";
import {_t} from "../../../Common/components/InjectIntlContext";

const { Panel } = Collapse;

const useLineChartData = (filters) => {
  const { isLoading, data } = useChartData(filters);

  const keys = data?.data?.info;
  const chartData = data?.data?.data;

  const convertedData = chartData?.map((d) => {
    const item = keys.reduce((acc, item, idx) => {
      acc[item[1]] = d[idx];
      return acc;
    }, {});
    return item;
  });

  return {
    isLoading,
    items: convertedData,
    lines: keys?.map((k) => k[1]),
  };
};

export function LineChart({ filters }) {
  const { isLoading, items, lines } = useLineChartData(filters);

  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          width: "100%",
          height: 300,
          background: "white",
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        {items?.length > 0 ? (
          <ResponsiveContainer>
            <RLineChart
              data={items}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid horizontal strokeDasharray="3 3" />
              <XAxis
                dataKey={lines[0]}
                tickFormatter={(v) => {
                  return moment(v).format("D MMM");
                }}
              />
              <YAxis />
              <Tooltip />
              <Legend
                wrapperStyle={{ marginLeft: 20 }}
                align="left"
                verticalAlign="top"
                height={40}
                iconType="line"
              />

              {lines.slice(1).map((line, i) => (
                <Line
                  key={line}
                  strokeWidth={2}
                  opacity={0.7}
                  type="monotone"
                  dataKey={line}
                  stroke={theme.lineColors[i]}
                />
              ))}
            </RLineChart>
          </ResponsiveContainer>
        ) : (
          !isLoading && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                margin: 0,
              }}
            />
          )
        )}
      </div>
    </Spin>
  );
}

export function CollapsebleChart(props: { filters }) {
  return (
    <Collapse>
      <Panel header={_t("chart")} key="1">
        <ChartFilters />
        <LineChart {...props} />
      </Panel>
    </Collapse>
  );
}
