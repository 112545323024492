import { actionTypes } from "./actions";
import { REPORT_DATE_KEY, REPORT_DATE_KEY_2 } from "../utils";
import moment from "moment";

const getInitialDate = () => {
  let date;
  let date2;
  const savedDate1 = JSON.parse(localStorage.getItem(REPORT_DATE_KEY));
  const savedDate2 = JSON.parse(localStorage.getItem(REPORT_DATE_KEY_2));
  if (savedDate1 !== null) {
    date = {
      from: moment(savedDate1.from),
      to: moment(savedDate1.to),
    };
  } else {
    date = {
      from: moment().startOf("month"),
      to: moment(),
    };
  }
  if (savedDate2 !== null) {
    date2 = {
      from: moment(savedDate2.from),
      to: moment(savedDate2.to),
    };
  } else {
    date2 = {
      from: moment().startOf("month"),
      to: moment(),
    };
  }
  return { date, date2 };
};
const initialState = {
  date: getInitialDate().date,
  date2: getInitialDate().date2,
  page_size: "100",
  page: 1,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case "LOGOUT_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...state,
        date: {
          ...state.date,
          ...getInitialDate().date,
        },
        date2: {
          ...state.date2,
          ...getInitialDate().date2,
        },
      };

    default:
      return state;
  }
}
