import React, { useEffect, useRef } from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Cascader, Space, Spin, Typography } from "antd";
import {
  useAllowSpecialProject,
  useDenySpecialProject,
  useSpecialProjects,
} from "../../apiHooks";
import { SimpleText } from "../inputs/SimpleText";
import { CloseCircleTwoTone } from "@ant-design/icons";

const { Text } = Typography;

type SpecialsProps = {
  user: any;
};

export const SpecialProjectsWidget = ({ user }: SpecialsProps) => {
  const { data, isLoading, isSuccess } = useSpecialProjects({
    user_id: user.id,
  });
  const [
    allowSpecial,
    { isSuccess: isSuccessSpecialProject },
  ] = useAllowSpecialProject();
  const [denySpecial] = useDenySpecialProject();

  useEffect(() => {
    isSuccessSpecialProject && inputEl.current.setValue([]);
  }, [isSuccessSpecialProject]);

  const addProject = (value) => {
    if (value.length) {
      allowSpecial({
        user_id: user.id,
        project_id: value[0],
      });
    }
  };
  const denyProject = (project_id) => {
    denySpecial({
      user_id: user.id,
      project_id: project_id,
    });
  };

  const projectsOptions = isSuccess
    ? Object.keys(data.projects).map((keyName) => ({
        label: data.projects[keyName],
        value: keyName,
      }))
    : undefined;
  const projectsAvailableOptions = isSuccess
    ? Object.keys(data.projects_available).map((keyName) => ({
        label: data.projects_available[keyName],
        value: keyName,
      }))
    : undefined;
  const inputEl = useRef(null);

  return (
    <Spin spinning={isLoading}>
      <Space style={{ marginBottom: "5px" }}>
        <Text strong>{_t("projects")}</Text>
        <Cascader
          options={projectsAvailableOptions}
          onChange={addProject}
          placeholder={_t("select_special_project")}
          ref={inputEl}
        />
      </Space>
      {projectsOptions?.map((project) => {
        return (
          <div key={project.value}>
            <SimpleText>{project.label}</SimpleText>
            <CloseCircleTwoTone
              twoToneColor="red"
              onClick={() => denyProject(project.value)}
            />
          </div>
        );
      })}
    </Spin>
  );
};
