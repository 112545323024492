export const createOptions: any | { label: any; value: string }[] = (
  data,
  label,
  key,
) => {
  return (
    data &&
    Object.keys(data).map((item) => ({
      label: label ? data[item][label] : data[item].toString(),
      value: key ? data[item][key] : item,
    }))
  );
};
