import React, { useEffect, useRef } from "react";
import { Button, Form, Input } from "antd";
import {
  usePostAdminChatMessage,
  usePostAdminEditMessage,
  usePostChatMessage,
} from "../../../hooks";
import { useParams } from "react-router-dom";
import { setFormInputValue } from "../../../../../../common/helpers";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const SendMsgForm = ({ isAdmin, editMsg, setEditMsg }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [sendMsg] = usePostChatMessage(id);
  const [sendAdminMsg] = usePostAdminChatMessage(id);
  const [sendEditMsg] = usePostAdminEditMessage(id);
  const textArea = useRef(null);

  const sendForm = (values) => {
    if (editMsg?.editable) {
      sendEditMsg({
        id: editMsg.id,
        text: values.text,
      });
      setEditMsg({ editable: false });
      form.resetFields();
      return;
    }
    isAdmin ? sendAdminMsg(values) : sendMsg(values);
    form.resetFields();
  };

  useEffect(() => {
    if (editMsg?.editable) {
      setFormInputValue("text", editMsg?.text, form);
      textArea.current.focus();
    } else {
      setFormInputValue("text", "", form);
    }
  }, [editMsg]);

  const handleChange = (changedValues, allValues) => {
    if (editMsg?.editable) {
      setEditMsg({ ...editMsg, text: changedValues.text });
    }
  };
  const errorMsg = {
    text: _t("input_msg_error_1"),
    text_lenght: _t("input_msg_error_2"),
  };

  return (
    <Form
      className="chat-form"
      form={form}
      onFinish={sendForm}
      onValuesChange={handleChange}
    >
      <Form.Item hidden initialValue={id} name="id">
        <Input />
      </Form.Item>
      <Form.Item
        name="text"
        rules={[
          { required: true, message: errorMsg.text },
          {
            min: 3,
            message: errorMsg.text_lenght,
          },
        ]}
      >
        <Input.TextArea
          placeholder={_t("enter_msg")}
          ref={textArea}
          value={textArea.current}
        />
      </Form.Item>
      <button type="submit" className="chat-form__send" />
    </Form>
  );
};

export default SendMsgForm;
