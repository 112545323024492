import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import RolesWrapper from '../../Common/components/RolesWrapper';

const Header = props => {
  const location = useLocation();
  const pathname = location.pathname;
  const { links } = props;

  return (
    <Menu className="header" selectedKeys={[pathname]} mode="horizontal">
      { links.map((item, idx) =>
        <Menu.Item key={item.path}>
          <RolesWrapper rolesHasAccess={item.permission}>
            <NavLink
              key={item.path}
              to={item.path}
              exact={!!item.exact}
              activeClassName="active"
              style={{padding: '0 10px'}}
            >
              {item.title}
            </NavLink>
          </RolesWrapper>
        </Menu.Item>
      )
      }
    </Menu>
  )
}

Header.defaultProps = {
  links: []
}

export default Header;