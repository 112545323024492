import React from "react";
import BettiltMockupLogoImg from "../../../images/landings/brands_tab/Mockup_bettilt.png";
import BettiltMockupDesktopLogoImg from "../../../images/landings/brands_tab/Mockup_bettilt--desktop.png";
import BettiltLogoImg from "../../../images/landings/brands_tab/bettilt_logo.png";
import BettiltLogoDesktopImg from "../../../images/landings/brands_tab/bettilt_logo--desktop.png";
import BahsegelMockupLogoImg from "../../../images/landings/brands_tab/Mockup_bahsegel.png";
import BahsegelMockupDesktopLogoImg from "../../../images/landings/brands_tab/Mockup_bahsegel--desktop.png";
import BahsegelLogoImg from "../../../images/landings/brands_tab/bahsegel_logo.png";
import BahsegelLogoDesktopImg from "../../../images/landings/brands_tab/bahsegel_logo--desktop.png";
import CasinoMockupLogoImg from "../../../images/landings/brands_tab/Mockup_casino_slot.png";
import CasinoMockupDesktopLogoImg from "../../../images/landings/brands_tab/Mockup_casino_slot--desktop.png";
import CasinoLogoImg from "../../../images/landings/brands_tab/casino-slot_logo.png";
import CasinoLogoDesktopImg from "../../../images/landings/brands_tab/casino_logo--desktop.png";
import {_t} from "../../Common/components/InjectIntlContext";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";
import BrandItem from "./BrandItem";

const brands = (_t: any) => [
  {
    mockup_logo: BettiltMockupLogoImg,
    mockup_desktop_logo: BettiltMockupDesktopLogoImg,
    alt_mockup_logo: "Bettilt Mockup",
    logo: BettiltLogoImg,
    logo_desktop: BettiltLogoDesktopImg,
    alt_logo: "Bettilt logo",
    path: APP_ROUTES.brands.bettilt,
    description: _t("bettilt_description"),
  },
  {
    mockup_logo: BahsegelMockupLogoImg,
    mockup_desktop_logo: BahsegelMockupDesktopLogoImg,
    alt_mockup_logo: "BahsegelPage Mockup",
    logo: BahsegelLogoImg,
    logo_desktop: BahsegelLogoDesktopImg,
    alt_logo: "BahsegelPage logo",
    path: APP_ROUTES.brands.bahsegel,
    description: _t("bahsegel_description"),
  },
  {
    mockup_logo: CasinoMockupLogoImg,
    mockup_desktop_logo: CasinoMockupDesktopLogoImg,
    alt_mockup_logo: "Casino-slot Mockup",
    logo: CasinoLogoImg,
    logo_desktop: CasinoLogoDesktopImg,
    alt_logo: "Casino-slot logo",
    path: APP_ROUTES.brands.casinoslot,
    description: _t("casinoslot_description"),
  },
];

const Brands = () => {
  const translatedBrands = brands(_t);

  return (
    <section className="brands">
      <h2>brands</h2>
      <div className="brands__cont">
        {translatedBrands.map((brand, index) => (
          <BrandItem key={index}
            mockup_logo={brand.mockup_logo} mockup_desktop_logo={brand.mockup_desktop_logo}
            alt_mockup_logo={brand.alt_mockup_logo} logo={brand.logo}
            logo_desktop={brand.logo_desktop} alt_logo={brand.alt_logo}
            path={brand.path} description={brand.description}/>
        ))}
      </div>
    </section>
  );
};

export default Brands;
