import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { isExternalUrl } from "../../../system/helpers/urlHelper";

export type customButtonProps = {
  className?: string;
  btnLabel?: string;
  linkTo?: string;
  onClick?: () => any;
  type?: "submit" | "button";
  disabled?: boolean;
};

const CustomButton: React.FC<customButtonProps> = ({
  className,
  btnLabel,
  linkTo,
  onClick,
  type = "button",
  disabled,
}) => {
  const history = useHistory();

  const clickHandler = () => {
    if (linkTo) {
      if (isExternalUrl(linkTo)) {
        window.location.assign(linkTo);
      } else {
        history.push(linkTo);
      }
    }
    onClick && onClick();
  };

  return (
    <button
      className={classNames("btn", `${className}`)}
      onClick={clickHandler}
      type={type}
      disabled={disabled}
    >
      {btnLabel}
    </button>
  );
};

export default CustomButton;
