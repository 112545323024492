import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { EditOutlined } from "@ant-design/icons";

interface MsgProps {
  title: string;
  text: string;
  date: string;
  isAuthorAdmin?: number;
  setEditMsg?: any;
  editMsg?: any;
  id?: number;
}
const Msg: React.FC<MsgProps> = ({
  title,
  text,
  date,
  isAuthorAdmin,
  setEditMsg,
  id,
  editMsg,
}) => {
  const handleEdit = () => {
    setEditMsg({
      id,
      text,
      prevText: text,
      editable: true,
    });
  };
  const [msgText, setMsgText] = useState(text);
  const isCurrentEditable = editMsg?.editable && editMsg?.id === id;
  const closeEditing = !editMsg?.editable && editMsg?.id === id;

  useEffect(() => {
    if (isCurrentEditable) {
      setMsgText(editMsg?.text);
    } else if (closeEditing) {
      setMsgText(editMsg?.prevText);
    }
  }, [editMsg]);
  return (
    <div
      className={classNames("chat-msg", {
        "chat-msg--author": isAuthorAdmin,
        "chat-msg--selected": editMsg?.id === id && editMsg?.editable,
      })}
    >
      <p className="chat-msg__title">{title}</p>
      <p className="chat-msg__text">
        <pre>{isCurrentEditable ? editMsg.text : msgText}</pre>
        <button className="chat-msg__edit" onClick={handleEdit}>
          <EditOutlined />
        </button>
      </p>
      <p className="chat-msg__date">{date}</p>
    </div>
  );
};

export default Msg;
