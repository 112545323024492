import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Brands from "../components/Brands";

const Commission = (props) => {

    return (
        <LandingTemplate wrapClass={"brands__bg-layer brands__wrap"}>
            <Brands />
        </LandingTemplate>
    );
};

export default Commission;
