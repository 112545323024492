import React from "react";
import { useGetPromoTemplates, useUpdatePromoTemplates } from "../../apiHooks";
import {simpleItems, TemplatesFiltersTitle} from "../../constants";
import { Spin, Row, Col } from "antd";
import Form from "antd/lib/form";
import RadioBtn from "../../../Common/components/RadioBtn";
import { _t } from "../../../Common/components/InjectIntlContext";
import ButtonGroup from "../../../Common/components/ButtonGroup";
import SelectBanner from "../../../Common/components/SelectBanner";

type allValuesType = {
  [key: string]: { id: string | number };
};

const PromoTemplates = ({
  selectedPromoType,
  selectedProject,
  selectTemplate,
}) => {
  const [itemsForm] = Form.useForm();
  type requestDataType = {
    project_id: string;
    type: string;
    tags?: string;
  };
  const requestData: requestDataType = {
    project_id: selectedProject?.project?.id,
    type: selectedPromoType.id,
  };
  const { isLoading, data } = useGetPromoTemplates(requestData);
  const [updateTemplate, { isLoading: isUpdated }] = useUpdatePromoTemplates();
  const filters = data?.data?.filters;
  const dataItems = data?.data?.items;

  let timer: number;
  const onValuesChange = (changedVal, allValues: allValuesType) => {
    selectTemplate(null);
    itemsForm.resetFields();
    const values = [];
    for (let key in allValues) {
      if (!!allValues[key] && !!allValues[key].id) {
        values.push(allValues[key].id);
      }
    }
    if (values.length) {
      requestData.tags = JSON.stringify(values);
    }
    clearTimeout(timer);
    timer = window.setTimeout(function () {
      updateTemplate(requestData);
    }, 200);
  };

  const onTemplateChange = (changedVal, allValues) => {
    selectTemplate(allValues);
  };

  const renderItems = () => {
    if (dataItems && dataItems.length) {
      return (
        <Form.Item name={"template"}>
          <ButtonGroup prefixClassName={"promo-templates__banners"}>
            {dataItems.map((item) => {
              return (
                <SelectBanner
                  key={item.id}
                  value={{ id: item.id }}
                  id={item.id}
                  width={`${item.options.width}px`}
                  height={`${item.options.height}px`}
                  innerHtml={item.code}
                  simpleImage={simpleItems.includes(item.options.ext)}
                />
              );
            })}
          </ButtonGroup>
        </Form.Item>
      );
    }
  };

  const renderFilters = () => {
    if (Array.isArray(filters)) {
      return filters.map((item) => {
        if (!item.items.length) {
          return false;
        }
        return (
          <Row key={item.id} gutter={[12, 2]}>
            <Col span={24}>
              <Form.Item
                name={TemplatesFiltersTitle[item.id]}
                label={_t(TemplatesFiltersTitle[item.id])}
              >
                <ButtonGroup name={item.id}>
                  {item.items.map((subItem) => (
                    <RadioBtn
                      value={{ id: subItem.id, name: subItem.name }}
                      key={subItem.id}
                      id={subItem.id}
                      name={subItem.name}
                      number={subItem.count}
                    />
                  ))}
                </ButtonGroup>
              </Form.Item>
            </Col>
          </Row>
        );
      });
    } else {
      return filters?.language.map((languageItem, idx) => {
        return <div key={`${idx}__language`}>{languageItem.name}</div>;
      });
    }
  };

  return (
    <Spin spinning={isLoading || isUpdated}>
      <Form
        onValuesChange={onValuesChange}
        name="promo_templates"
        onFinish={() => {}}
        layout="vertical"
      >
        {renderFilters()}
      </Form>
      <Form
        form={itemsForm}
        name="items_templates"
        onValuesChange={onTemplateChange}
        layout="vertical"
      >
        {renderItems()}
      </Form>
    </Spin>
  );
};

export default PromoTemplates;
