import React from "react";
import Switch from 'antd/lib/switch';

const Switcher = ({defaultChecked, onChange, size, className}) =>
    <Switch
        size={size}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={className}
    />

Switcher.defaultProps = {
    size: 'default',
    defaultChecked: true,
    className: ''
}

export default Switcher;