import React from "react";

const CountryItem = ({ code, name }) => {
  return (
    <div className="country-item">
      <img
        src={`https://income-front-data.s3.fr-par.scw.cloud/common/images/countries-flags/${code.toLowerCase()}.svg`}
        alt=""
      />
      <div className="country-item__text">{name}</div>
    </div>
  );
};

export default CountryItem;
