import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { TrackerForm } from "../components/TrackerForm/TrackerForm";
import { TrackerList } from "../components/TrackerList/TrackerList";
import { useTrackerList, useTrackerCreate } from "../apiHooks";

const Trackers = () => {
  const [createTracker, { isLoading: isTrackerCreating }] = useTrackerCreate();
  const { data, isLoading } = useTrackerList();

  return (
    <MainLayout>
      <PageTemplate title={_t("Trackers")}>
        <TrackerForm
          onSubmit={createTracker}
          initialValues={{ name: "" }}
          isSubmitting={isTrackerCreating}
        />
        <TrackerList loading={isLoading} data={data?.data?.data}></TrackerList>
      </PageTemplate>
    </MainLayout>
  );
};

export default Trackers;
