import React from "react";
import { history } from "../../system/store";
import { isAuthorized } from "../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../system/constants/constantsUrl";

export const INVITATION_CODE = "invitation_code";

export const onGetStarted = () => {
  const getStartedLink = isAuthorized()
    ? APP_ROUTES.dashboard.general
    : APP_ROUTES.registration;
  history.push(getStartedLink);
};
