import axiosClient from "../../system/helpers/axiosConfig";
import {
  AccrualsRequest,
  AccrualsResponse,
  AddOperationRequest,
  AdminProjectsResponse,
  AllowSpecialLandingPageRequest,
  AllowSpecialProgramOptionsRequest,
  AllowSpecialProgramRequest,
  AllowSpecialProjectRequest,
  AuthLogRequest,
  AuthLogResponse,
  BonusesRequest,
  BonusesResponse,
  CreateUser,
  DenySpecialLandingPageRequest,
  DenySpecialProgramOptionRequest,
  DenySpecialProgramRequest,
  DenySpecialProjectRequest,
  GamerMultiplesRequest,
  GamerMultiplesResponse,
  MultiplesRequest,
  MultiplesResponse,
  NullingLogsRequest,
  NullingLogsResponse,
  PayoutsRequest,
  PayoutsResponse,
  ProfileBasicInformation,
  ProjectsResponse,
  RoleLogsRequest,
  RoleLogsResponse,
  SpecialLandingPagesRequest,
  SpecialLandingPagesResponse,
  SpecialProgramOptionsListRequest,
  SpecialProgramOptionsListResponse,
  SpecialProgramOptionsRequest,
  SpecialProgramOptionsResponse,
  SpecialProgramsRequest,
  SpecialProgramsResponse,
  SpecialProjectsRequest,
  SpecialProjectsResponse,
  StatusLogsRequest,
  StatusLogsResponse,
  UpdateProfileTRequest,
  UsersListRequest,
  UsersListResponse,
  UsersProfileRequest,
  WalletLogsRequest,
  WalletLogsResponse,
} from "./constants";

export const getUsersList = (params: UsersListRequest) => {
  return axiosClient
    .get<UsersListResponse>(`/api/users/list`, {
      params,
    })
    .then((r) => r.data);
};

export const getUsersProfile = (params: UsersProfileRequest) => {
  return axiosClient
    .get<ProfileBasicInformation>(`/api/users/profile`, {
      params,
    })
    .then((r) => r.data);
};

export const updateProfile = (request: UpdateProfileTRequest) => {
  return axiosClient
    .post("/api/users/profile/update", request.data)
    .then((r) => r.data);
};

export const getAccruals = (params: AccrualsRequest) => {
  return axiosClient
    .get<AccrualsResponse>(`/api/users/profile/accruals`, {
      params,
    })
    .then((r) => r.data);
};

export const getPayouts = (params: PayoutsRequest) => {
  return axiosClient
    .get<PayoutsResponse>(`/api/users/profile/payouts`, {
      params,
    })
    .then((r) => r.data);
};

export const getAuthLog = (params: AuthLogRequest) => {
  return axiosClient
    .get<AuthLogResponse>(`/api/users/profile/auth-log`, {
      params,
    })
    .then((r) => r.data);
};

export const getMultiples = (params: MultiplesRequest) => {
  return axiosClient
    .get<MultiplesResponse>(`/api/users/profile/multiples`, {
      params,
    })
    .then((r) => r.data);
};

export const getGamerMultiples = (params: GamerMultiplesRequest) => {
  return axiosClient
    .get<GamerMultiplesResponse>(`/api/users/profile/gamer-multiples`, {
      params,
    })
    .then((r) => r.data);
};

export const getBonuses = (params: BonusesRequest) => {
  return axiosClient
    .get<BonusesResponse>(`/api/users/profile/bonuses`, {
      params,
    })
    .then((r) => r.data);
};

export const getWalletLogs = (params: WalletLogsRequest) => {
  return axiosClient
    .get<WalletLogsResponse>(`/api/users/profile/wallet-logs`, {
      params,
    })
    .then((r) => r.data);
};

export const getStatusLogs = (params: StatusLogsRequest) => {
  return axiosClient
    .get<StatusLogsResponse>(`/api/users/profile/status-logs`, {
      params,
    })
    .then((r) => r.data);
};

export const getRoleLogs = (params: RoleLogsRequest) => {
  return axiosClient
    .get<RoleLogsResponse>(`/api/users/profile/role-logs`, {
      params,
    })
    .then((r) => r.data);
};

export const getNullingLogs = (params: NullingLogsRequest) => {
  return axiosClient
    .get<NullingLogsResponse>(`/api/users/profile/nulling-logs`, {
      params,
    })
    .then((r) => r.data);
};

export const getProjects = () => {
  return axiosClient
    .get<ProjectsResponse>(`/api/users/profile/add-operation/projects`)
    .then((r) => r.data.data);
};

export const addOperation = (request: AddOperationRequest) => {
  return axiosClient
    .post("/api/users/profile/add-payment", request)
    .then((res) => res.data);
};

export const getSpecialProjects = (params: SpecialProjectsRequest) => {
  return axiosClient
    .get<SpecialProjectsResponse>("/api/users/profile/special/projects", {
      params,
    })
    .then((r) => r.data);
};

export const allowSpecialProject = (request: AllowSpecialProjectRequest) => {
  return axiosClient
    .post("/api/users/profile/special/allow-special-project", request)
    .then((r) => r.data);
};

export const denySpecialProject = (request: DenySpecialProjectRequest) => {
  return axiosClient
    .post("/api/users/profile/special/deny-special-project", request)
    .then((r) => r.data);
};

export const getSpecialPrograms = (params: SpecialProgramsRequest) => {
  return axiosClient
    .get<SpecialProgramsResponse>("/api/users/profile/special/programs", {
      params,
    })
    .then((r) => r.data);
};

export const allowSpecialProgram = (request: AllowSpecialProgramRequest) => {
  return axiosClient
    .post("/api/users/profile/special/allow-special-program", request)
    .then((r) => r.data);
};

export const denySpecialProgram = (request: DenySpecialProgramRequest) => {
  return axiosClient
    .post("/api/users/profile/special/deny-special-program", request)
    .then((r) => r.data);
};

export const getSpecialLandingPages = (params: SpecialLandingPagesRequest) => {
  return axiosClient
    .get<SpecialLandingPagesResponse>(
      "/api/users/profile/special/landing-pages",
      { params }
    )
    .then((r) => r.data);
};

export const allowSpecialLandingPage = (
  request: AllowSpecialLandingPageRequest
) => {
  return axiosClient
    .post("/api/users/profile/special/allow-special-landing-page", request)
    .then((r) => r.data);
};

export const denySpecialLandingPage = (
  request: DenySpecialLandingPageRequest
) => {
  return axiosClient
    .post("/api/users/profile/special/deny-special-landing-page", request)
    .then((r) => r.data);
};

export const getAdminProjects = () => {
  return axiosClient
    .get<AdminProjectsResponse>(`/api/users/create`)
    .then((r) => r.data);
};

export const adminCreateUser = (data: CreateUser) => {
  return axiosClient.post("/api/users/store", data).then((r) => r.data);
};

export const getSpecialProgramOptions = (
  params: SpecialProgramOptionsRequest
) => {
  return axiosClient
    .get<SpecialProgramOptionsResponse>(
      "/api/users/profile/special/program-special-options",
      {
        params,
      }
    )
    .then((r) => r.data);
};

export const getSpecialProgramOptionsList = (
  params: SpecialProgramOptionsListRequest
) => {
  return axiosClient
    .get<SpecialProgramOptionsListResponse>(
      "/api/users/profile/special/program-special-options-list",
      {
        params,
      }
    )
    .then((r) => r.data);
};

export const denySpecialProgramOption = (
  request: DenySpecialProgramOptionRequest
) => {
  return axiosClient
    .post(
      "/api/users/profile/special/program-special-options-terminate",
      request
    )
    .then((r) => r.data);
};

export const allowSpecialProgramOption = (
  request: AllowSpecialProgramOptionsRequest
) => {
  return axiosClient
    .post("/api/users/profile/special/program-special-options", request)
    .then((r) => r.data);
};

export const getRolesList = () => {
  return axiosClient.get(`/api/users/profile/roles-list`).then((r) => r.data);
};
