import { useMutation } from "react-query";
import { notify } from "../../../../../system/utils/notification";
import { postMergeTranslations } from "../api";

export const usePostMergeTranslations = () => {
  return useMutation(postMergeTranslations, {
    onSuccess: (data, variables) => {
      notify("success", "success");
    },
    onError: () => {
      notify("error", "error");
    },
  });
};
