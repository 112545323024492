import axiosClient from "../../system/helpers/axiosConfig";

export interface Tracker {
  id: number;
  user_id: number | string;
  name: string;
  description?: string;
  created_at: string;
}

interface TrackersListResponse {
  data: {
    current_page: number;
    data: Tracker[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  };
}

interface CreateTrackerResponse {
  data: Tracker;
}

export interface GetTrackersRequest {
  user_id: number | string;
  page?: string;
}

export interface CreateTrackerRequest {
  user_id: number | string;
  name: string;
}

export const getTrackersList = ({ user_id, page }: GetTrackersRequest) => {
  return axiosClient
    .get<TrackersListResponse>(`/api/trackers/${user_id}`, {
      params: { page },
    })
    .then((r) => r.data);
};

export const createTracker = ({ user_id, name }: CreateTrackerRequest) => {
  return axiosClient
    .post<CreateTrackerResponse>(`/api/trackers`, {
      name,
      user_id,
    })
    .then((r) => r.data);
};
