import React from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const AffiliateLandingPageFormItemTitle = ({ info, titleLanguages }) => {
  const cnt = titleLanguages ? Object.keys(titleLanguages.languages).length : 0;
  const colSpan = Math.floor(24 / cnt ?? 1);
  if (!titleLanguages) {
    return null;
  }
  return (
    <Row gutter={10}>
      {Object.keys(titleLanguages?.languages).map((language) => (
        <Col span={24} lg={colSpan} key={language}>
          <Typography.Title level={5} className="small-title">
            {titleLanguages.languages[language]}
          </Typography.Title>
          <Form.Item
            name={"i18n[" + language + "][title]"}
            label={_t("title")}
            initialValue={info?.translations[language] || ""}
            rules={[{ required: true, message: _t("input_title") }]}
          >
            <Input />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default AffiliateLandingPageFormItemTitle;
