import React, { useEffect } from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import OurBenefits from "../../Landing/components/OurBenefits";
import HowItWorks from "../../Landing/components/HowItWorks";
import Commissions from "../../Landing/components/Commissions";
import { setItem } from "../../../system/helpers/localstorageHelper";
import { INVITATION_CODE, onGetStarted } from "../constants";
import NavCards from "../components/NavCards";
import AboutSection from "../components/AboutSection";

const Landing = () => {
  let invitationCode = new URL(window.location.href).searchParams.get(
    INVITATION_CODE
  );
  useEffect(() => {
    if (invitationCode) {
      setItem(INVITATION_CODE, invitationCode);
    }
  }, [invitationCode]);

  return (
    <LandingTemplate mainSlider={true}>
      <NavCards />
      <OurBenefits getStarted={onGetStarted} />
      <HowItWorks getStarted={onGetStarted} />
      <Commissions />
      <AboutSection />
      {/*<Portfolio />*/}
    </LandingTemplate>
  );
};

export default Landing;
