import {
  getBonusesAndFinesList,
  getPayoutsList,
  getAccrualsList,
  getCashTypes,
  updatePaymentDetails,
  getUserWallet,
  getPaymentsSuggestions,
} from "./api";
import { useQuery, useMutation, queryCache } from "react-query";

const BonusesAndFinesList = "bonuses-and-fines";
const PayoutsList = "payouts";
const AccrualsList = "accruals";
const CashTypes = "cash-types";
const UserWallet = "user-wallet";
const Suggestion = "payments-uggestion";

export const useBonusesAndFinesList = (params = {}) => {
  return useQuery([BonusesAndFinesList, params], (k, params) =>
    getBonusesAndFinesList(params)
  );
};

export const usePayoutsList = (params = {}) => {
  return useQuery([PayoutsList, params], (k, params) => getPayoutsList(params));
};

export const useAccrualsList = () => {
  return useQuery([AccrualsList], getAccrualsList);
};

export const useCashTypes = () => {
  return useQuery([CashTypes], getCashTypes);
};
export const useUserWallet = () => {
  return useQuery([UserWallet], getUserWallet);
};
export const usePaymentsSuggestion = (params) => {
  return useQuery([Suggestion, params], (_, params) =>
    getPaymentsSuggestions(params)
  );
};

export const useUpdatePayments = () => {
  return useMutation(updatePaymentDetails, {
    onSuccess: () => {
      queryCache.invalidateQueries(UserWallet);
    },
  });
};
