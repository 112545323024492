import { generatePath } from "react-router-dom";

import { APP_ROUTES } from "../../../../../../../../system/constants/constantsUrl";

export const deleteUserFromList = (userId, listId, listType, handleDelete) => {
  handleDelete({
    user_id: userId,
    id: listId,
    type: listType,
  });
};
export const getUserProfilePath = (id) =>
  generatePath(APP_ROUTES.manage.user_profile, {
    partnerId: id,
  });
