import React from "react";

type InfoItemProps = {
  value: string;
  label?: string;
  align?: string;
};

const InfoItem: React.FC<InfoItemProps> = ({ value, label, align }) => (
  <div className={`info-item info-item--${align}`}>
    <div className={"info-item__value"}>{value}</div>
    {label && <div className={"info-item__label"}>{label}</div>}
  </div>
);

InfoItem.defaultProps = {
  label: "",
  value: "",
  align: "left",
};

export default InfoItem;
