import { useMutation, useQueryCache } from "react-query";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../../common/helpers";
import { API_KEY_GET_MANAGEABLE_LIST } from "../constants";
import { postAddUser } from "../api";

export const usePostAddUser = () => {
  const queryCache = useQueryCache();
  const successMsg = _t("successfully_updated");

  return useMutation(postAddUser, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);
      queryCache.invalidateQueries(API_KEY_GET_MANAGEABLE_LIST);
    },
  });
};
