import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Tracker } from "../../api";
import { _t } from "../../../Common/components/InjectIntlContext";
import { NoData } from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<Tracker> => [
  {
    title: _t("Id"),
    dataIndex: "id",
    key: "id",
  },
  {
    title: _t("Name"),
    dataIndex: "name",
    key: "name",
  },
  {
    title: _t("created_at"),
    dataIndex: "created_at",
    key: "created_at",
  },

  {
    title: _t("Action"),
    key: "description",
    dataIndex: "description",
    render: (text) => text || "none",
  },
];

export function TrackerList({
  data,
  loading,
}: {
  data: Tracker[];
  loading: boolean;
}) {
  return (
    <Table
      loading={loading}
      columns={columns(_t)}
      dataSource={data}
      pagination={false}
      locale={{ emptyText: <NoData /> }}
    />
  );
}
