import { LOCALE_KEY } from "./constants";
import { setItem } from "../../system/helpers/localstorageHelper";

export const actionTypes = {
  UPDATE_LOCALE: "UPDATE_LOCALE",
  UPDATE_LANGUAGES: "UPDATE_LANGUAGES",
};

export const updateLocale = (locale) => (dispatch) => {
  if (locale) {
    setItem(LOCALE_KEY, locale);
  }

  return dispatch({
    type: actionTypes.UPDATE_LOCALE,
    payload: {
      locale,
    },
  });
};

export const updateLanguages = (languages) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_LANGUAGES,
    payload: {
      ...languages,
    },
  });
};
