import React from "react";
import {SimpleInput} from "../../../Common/components";
import {ProfileSubmitForm} from "./ProfileSubmitForm";


export const CollapsableInput = ({user, name, value, onSubmit, selected, isLoading, setEditable}) => {
  const initialValues = {
    id: user.id,
    field_name: name,
    value: value,
  };
  return (
    <ProfileSubmitForm textValue={value} initialValues={initialValues} isLoading={isLoading} name={name}
                       onSubmit={onSubmit} selected={selected} setEditable={setEditable}>
      <SimpleInput/>
    </ProfileSubmitForm>
  );
};
