import { useQuery } from "react-query";
import { getTranslation, GetTranslationRequest } from "./api";

const TranslationQuery = "translation";

export const useTranslation = (params: GetTranslationRequest) => {
  return useQuery([TranslationQuery, params], (k, params) =>
    getTranslation(params)
  );
};
