import React, { useState, useEffect } from "react";
import { DatePicker as AntDatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Moment } from "moment";

type Value = { from: Moment | null; to: Moment | null };

export function DatePicker({
  onChange,
  value,
  clearable = false,
}: {
  value?: Value;
  onChange?: (value: Value) => any;
  clearable?: boolean;
}) {
  const [from, setFrom] = useState(value?.from);
  const [to, setTo] = useState(value?.to);

  useEffect(() => {
    onChange({ from, to });
  }, [from, to]);

  return (
    <div className="datepicker">
      <AntDatePicker
        key={1}
        value={from}
        onChange={setFrom as any}
        showToday={false}
        dropdownClassName="datepicker-dropdown"
        suffixIcon={null}
        allowClear={clearable}
      />
      <CalendarOutlined />
      <AntDatePicker
        key={2}
        value={to}
        onChange={setTo as any}
        showToday={false}
        dropdownClassName="datepicker-dropdown"
        suffixIcon={null}
        allowClear={clearable}
      />
    </div>
  );
}
