import { useQuery } from "react-query";
import {
  API_KEY_GET_LANDING_PAGE_LANGUAGES,
  API_KEY_GET_LANDING_PAGE_TITLE_LANGUAGES,
} from "../utils";
import { getLandingPageLanguages, getLandingPageTitleLanguages } from "../api";

export const useGetLandingPageLanguages = () => {
  return useQuery([API_KEY_GET_LANDING_PAGE_LANGUAGES], () =>
    getLandingPageLanguages()
  );
};

export const useGetAvailableLanguages = () => {
  return useQuery([API_KEY_GET_LANDING_PAGE_TITLE_LANGUAGES], () =>
    getLandingPageTitleLanguages()
  );
};
