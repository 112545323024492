import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "antd/lib/button";

const Btn: React.FC<ButtonProps & { title: string }> = (
  props: PropsWithChildren<any>
) => <Button {...props}>{props.title}</Button>;

Btn.defaultProps = {
  type: "primary",
  shape: "round",
  icon: "",
  size: "large",
  title: "default",
  className: "btn-main",
};

export default Btn;
