import React from "react";
import { FaqResponse } from "../../api";
import InfoCard from "../../../Landing/components/InfoCard";
import classnames from "classnames";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  title: string;
  tileClass: string;
  selected: string;
  requestParam: string;
  setFilter: (v) => void;
  image: any;
};

export function FaqTitle({
  title,
  tileClass,
  selected,
  requestParam,
  setFilter,
  image,
}: FaqTitlesProps) {
  return (
    <div onClick={() => setFilter(requestParam)}>
      <InfoCard
        className={classnames(selected, "faq-card")}
        title={title}
        image={image}
      />
    </div>
  );
}
