import React from "react";
import {useDispatch} from "react-redux";
import {_t} from "../../Common/components/InjectIntlContext";
import {getAdminToken, getUserId, returnAdminHelper} from "../../../system/helpers/localstorageHelper";
import {history} from "../../../system/store";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";
import {getUserBasic} from "../../User/actions";
import {BackwardOutlined} from "@ant-design/icons";

const ReturnAdmin = () => {
  const dispatch = useDispatch();

  const returnAdmin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    returnAdminHelper();
    dispatch(getUserBasic(getUserId()));
    history.push(APP_ROUTES.manage.users);
  };
  const canReturnToAdmin = !!getAdminToken();
  if (!canReturnToAdmin) {
    return (<></>);
  }
  return (
    <div className={"return-admin"} onClick={returnAdmin}>
      <BackwardOutlined/>{_t('Admin panel')}
    </div>
  );
};

export default ReturnAdmin;
