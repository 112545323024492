import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { Spin } from "antd";
import AffiliateProgramForm from "./components/AffiliateProgramForm";
import { useGetProgram } from "../hooks/useGetProgram";
import { usePostProgram } from "../hooks";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const AffiliateProgramEdit = () => {
  const { program_id, project_name } = useParams();
  const { data, isLoading } = useGetProgram({ id: program_id });
  const [updateProgram] = usePostProgram({
    id: data?.id,
  });
  return (
    <MainLayout>
      <PageTemplate title={`${_t("edit_program")} ${project_name}`}>
        <Spin spinning={isLoading}>
          {data && (
            <AffiliateProgramForm
              info={data?.program}
              sendProgram={updateProgram}
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default AffiliateProgramEdit;
