import React, { useState } from "react";
import { Button, Dropdown, Spin } from "antd";
import {
  CheckCircleOutlined,
  SettingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { DropMenu } from "./index";
import { usePostAdminStatusChat } from "../../../hooks";
import { useParams } from "react-router-dom";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const ChatControls = ({ priority, status }) => {
  const [settingIsVisible, setSettingIsVisible] = useState(false);
  const handleVisibleChange = (flag) => {
    setSettingIsVisible(flag);
  };
  const { id } = useParams();
  const isChatOpened = status === 1;
  const [
    sendStatus,
    { data: dataStatus, isLoading: statusIsLoading },
  ] = usePostAdminStatusChat(status, id);

  return (
    <div className="chat-controls">
      <Spin spinning={statusIsLoading}>
        <Button
          icon={isChatOpened ? <CheckCircleOutlined /> : <ReloadOutlined />}
          size="large"
          className="chat__close-ticket"
          onClick={() => sendStatus()}
          disabled={statusIsLoading}
        >
          {isChatOpened ? _t("chat_close") : _t("chat_reopen")}
        </Button>
      </Spin>
      <Dropdown
        overlay={<DropMenu priority={priority} />}
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
        visible={settingIsVisible}
      >
        <Button
          icon={<SettingOutlined />}
          size="large"
          shape="circle"
          className="chat__settings"
        />
      </Dropdown>
    </div>
  );
};

export default ChatControls;
