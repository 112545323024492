import React from "react";
import {_t} from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import {PageTemplate} from "../../Layouts/PageTemplate";
import {Row, Col} from "antd";
import {SubAffiliatesWidget} from "../components/SubAffiliatesWidget";
import {DetailsWidget} from "../components/DetailsWidget";

const SubaffiliatesTemplate = ({detailsWidget, subAffiliatesWidget}) => {
  return (
    <Row gutter={[30, 30]}>
      <Col md={24} xl={12}>
        {detailsWidget}
      </Col>
      <Col md={24} xl={12}>
        {subAffiliatesWidget}
      </Col>
    </Row>
  );
};

const Affiliates = () => {
  return (
    <MainLayout>
      <PageTemplate title={_t("Affiliates")}>
        <SubaffiliatesTemplate
          subAffiliatesWidget={<SubAffiliatesWidget/>}
          detailsWidget={<DetailsWidget/>}
        />
      </PageTemplate>
    </MainLayout>
  );
};

export default Affiliates;
