import React from 'react';
import Checkbox from 'antd/lib/checkbox';

const CheckboxInput = props => <Checkbox {...props}>{props.title}</Checkbox>;

CheckboxInput.defaultProps = {
    title: 'default',
    className: 'checkbox-main'
};

export default CheckboxInput;
