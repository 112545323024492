import React from "react";
import { Space } from "antd";
import { PartnerBalance } from "../components/PartnerBalance/PartnerBalance";

export function PageTemplate({ children }) {
  return (
    <Space size={30} direction="vertical" style={{ width: "100%" }}>
      {/*<PartnerBalance />*/}
      {children}
    </Space>
  );
}
