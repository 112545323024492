import React, { useState } from "react";
import { Card, Row, Col } from "antd";
import classNames from "classnames";
import { ArchivePromoItemTypes } from "../../Promo/constants";
import { ReactComponent as IconEdit } from "../../../images/icons/promo/edit.svg";
import { ReactComponent as IconBin } from "../../../images/icons/promo/garbage_bin.svg";
import { ReactComponent as IconStat } from "../../../images/icons/promo/stat.svg";
import { useHistory } from "react-router-dom";
import { _t } from "./InjectIntlContext";
import InlineInfo from "./InlineInfo";
import ArchiveModal from "../../Promo/components/archive/ArchiveModal";
import { useFilters as useDashboardFilters } from "../../Dashboard/hooks";
import CopyToClipboard from "./CopyToClipboard";

type ArchivePromoItemProps = {
  promo: ArchivePromoItemTypes;
};

const ArchivePromoItem: React.FC<ArchivePromoItemProps> = ({ promo }) => {
  let history = useHistory();
  const [_, setFilters] = useDashboardFilters();
  const onClickLink = () => {
    setFilters({ promo_id: promo.id as any });
    history.push("/dashboard/promo");
  };
  const bannerAndVideoType = promo.type !== 2 && promo.type !== 9;
  const affiliateApi = '<script src="/j/affiliates.api.min.js?v2.0"></script>';
  const [show, setShow] = useState(false);
  return (
    <Card className={classNames("archive-item")}>
      {show && <ArchiveModal show={show} promo={promo} setShow={setShow} />}
      <Row>
        <Col span={bannerAndVideoType ? 0 : 6}>
          <div className={"archive-item__info__source"}>
            <iframe
              srcDoc={affiliateApi + promo.source_raw}
              scrolling="no"
              style={{ pointerEvents: "none" }}
            />
          </div>
        </Col>
        <Col span={bannerAndVideoType ? 24 : 18}>
          <>
            <div className={"archive-item__info"}>
              <InlineInfo label={_t("Name")} data={promo.name} />
            </div>
            <Row className={"archive-item__info"}>
              <Col span={6}>
                <CopyToClipboard value={promo.code}>
                  <InlineInfo label={_t("Id")} data={promo.code} />
                </CopyToClipboard>
              </Col>
              <Col span={4}>
                <InlineInfo label={_t("Program")} data={promo.program_name} />
              </Col>
              <Col span={4}>
                <InlineInfo label={_t("Type")} data={promo.type_label} />
              </Col>
              <Col span={6}>
                <InlineInfo label={_t("Created")} data={promo.created_at} />
              </Col>
            </Row>
            {
              // not Banner and Video type
              bannerAndVideoType && (
                <div className={"archive-item__info archive-item__info__link"}>
                  <CopyToClipboard value={promo.source_raw}>
                    <span>{promo.source_raw}</span>
                  </CopyToClipboard>
                </div>
              )
            }
          </>
        </Col>
      </Row>
      <div className={"archive-item__controls"}>
        <div className={"archive-item__controls__item"}>
          <div
            className={"archive-item__controls__item__link"}
            onClick={onClickLink}
          >
            <IconStat />
          </div>
        </div>
        <div
          className={"archive-item__controls__item"}
          onClick={() => setShow(true)}
        >
          <IconEdit />
        </div>
        <div className={"archive-item__controls__item"}>
          <IconBin />
        </div>
      </div>
    </Card>
  );
};

export default ArchivePromoItem;
