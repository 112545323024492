import { useSelector, useDispatch } from "react-redux";
import { updateCostsFilters } from "./actions";

type Filters = {
  date: {
    from: string;
    to: string;
  };
  url: string;
  games: string;
  refcode: string;
  page_size: number;
  page: number;
  currentPage: string;
};

export const useFilters = () => {
  const filters = useSelector(
    (state: { costs }) => state.costs.filters
  ) as Filters;
  const dispatch = useDispatch();
  const updateFilters = (v: Partial<Filters>) =>
    dispatch(updateCostsFilters(v));

  return [filters, updateFilters] as const;
};
