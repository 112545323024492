import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { _t } from "../../../../../../../../modules/Common/components/InjectIntlContext";
import { PlusOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { ManageableUsersTable } from "../ManageableUsersTable";

const ManageableUserList = ({ title, list, listType, createPath, listId }) => {
  const [showUsers, setShowUsers] = useState(false);
  const handleUsersListDisplay = () => {
    if (list.length < 1) {
      setShowUsers(false);
      return;
    }
    if (listType === "manager") setShowUsers(true);
  };

  useEffect(() => {
    handleUsersListDisplay();
  }, [showUsers, list]);

  return (
    <div className="manageable-user-list">
      <div className="manageable-user-list__top">
        <Typography.Title level={5}>
          {title}: {listType === "user" && list.length}
        </Typography.Title>
        {listType === "user" && list.length > 0 && (
          <Button
            icon={showUsers ? <UpOutlined /> : <DownOutlined />}
            onClick={() => setShowUsers(!showUsers)}
          >
            {showUsers ? _t("hide_users") : _t("show_users")}
          </Button>
        )}
        <Link to={createPath} className="ant-btn">
          <PlusOutlined />
          {_t("add_to_list")}
        </Link>
      </div>
      {showUsers && (
        <div className="manageable-user-list__content">
          <ManageableUsersTable
            data={list}
            listId={listId}
            listType={listType}
            setShowUsers={setShowUsers}
          />
        </div>
      )}
    </div>
  );
};

export default ManageableUserList;
