import React from "react";
import {useLoginById} from "../apiHooks";
import {useUrlQuery} from "../utils";
import {LoginByUserId} from "../components/LoginByUserId";

export const LoginById = () => {
  const query = useUrlQuery();
  const user_id = query.get("user_id");

  return <LoginByUserId activateHook={useLoginById} userData={user_id}/>;
};
