import { queryCache, useMutation, useQuery } from "react-query";
import { getUserId } from "../../system/helpers/localstorageHelper";
import {
  getApiLogs,
  ApiLogsRequest,
  getSubscriptions,
  getSubscriptionsRequest,
  updateSubscriptionsRequest,
  updateSubscriptions,
  changeUserNameRequest,
  changeUserName,
  changeUserPassword,
  changeUserPasswordRequest,
  getCSVAccess,
  enableCSVSettings,
  enableCSVSettingsRequest,
} from "./api";
import { notify, notifyMap } from "../../system/utils/notification";
import { useDispatch } from "react-redux";
import { generateCSVKey, getUserBasic } from "./actions";
import { OK_STATUS_MESSAGE } from "../Users/constants";
import { _t } from "../Common/components/InjectIntlContext";

const ApiLogsName = "api-logs";
const UserSubscriptions = "user-subscriptions";
const CsvKey = "user-csv-key";
const CsvAccess = "user-csv-access";

export const useApiLogs = (
  params: ApiLogsRequest = { user_id: getUserId() }
) => {
  return useQuery([ApiLogsName, params], (k, params) => getApiLogs(params));
};

export const useGetUserSubscriptions = (
  params: getSubscriptionsRequest = { user_id: getUserId() }
) => {
  return useQuery([UserSubscriptions, params], (k, params) =>
    getSubscriptions(params)
  );
};

export const useUpdateUserSubscriptions = () => {
  return useMutation(
    (data: updateSubscriptionsRequest) => updateSubscriptions(data),
    {
      onSuccess: (data) => {
        queryCache.invalidateQueries(UserSubscriptions);
        notify("success", "Success updated");
      },
      onError: (error) => {
        notify("error", error.message);
      },
      throwOnError: true,
    }
  );
};

export const useChangeUserName = () => {
  return useMutation((data: changeUserNameRequest) => changeUserName(data), {
    onSuccess: (data) => {
      notify("success", "Success updated");
    },
    onError: (error) => {
      notify("error", error.message);
    },
    throwOnError: true,
  });
};

export const useChangeUserPassword = () => {
  const successfullyUpdated = _t("successfully_updated");
  return useMutation(
    (data: changeUserPasswordRequest) => changeUserPassword(data),
    {
      onSuccess: (data) => {
        if (data.data.status === OK_STATUS_MESSAGE) {
          notify("success", successfullyUpdated);
        } else {
          notifyMap("error", data.data.messages);
        }
      },
      onError: (error) => {
        notify("error", error.message);
      },
      throwOnError: true,
    }
  );
};

export const useGenerateCSVKey = () => {
  const dispatch = useDispatch();
  return useMutation(() => generateCSVKey()(dispatch), {
    onSuccess: (data) => {
      notify("success", "Success updated");
    },
    onError: (error) => {
      notify("error", error.message);
    },
    throwOnError: true,
  });
};

export const useGetCSVAccess = () => {
  const dispatch = useDispatch();
  const user_id = getUserId();
  return useMutation(() => getCSVAccess(), {
    onSuccess: (data) => {
      notify("success", "Success updated");
      getUserBasic(user_id)(dispatch);
    },
    onError: (error) => {
      notify("error", error.message);
    },
    throwOnError: true,
  });
};
export const useEnableCSVSettings = () => {
  const dispatch = useDispatch();
  const user_id = getUserId();
  return useMutation(
    (data: enableCSVSettingsRequest) => enableCSVSettings(data),
    {
      onSuccess: (data) => {
        notify("success", "Success updated");
        getUserBasic(user_id)(dispatch);
      },
      onError: (error) => {
        notify("error", error.message);
      },
      throwOnError: true,
    }
  );
};
