import React from "react";
import classNames from "classnames";
import CustomButton from "../../Common/components/CustomButton";

export type infoCardProps = {
  className: string;
  title: string;
  description?: any;
  image?: string;
  btnLabel?: string;
  linkTo?: string;
  onClick?: () => any;
  backgroundImg?: string;
};

const InfoCard: React.FC<infoCardProps> = (props) => {
  const {
    className,
    title,
    description,
    image,
    btnLabel,
    linkTo,
    onClick,
    backgroundImg = "default",
  } = props;
  const cardStyle = { backgroundImage: `url(${backgroundImg})` };

  return (
    <div className={classNames("info-card", className)} style={cardStyle}>
      <div className={`${className}__content`}>
        {image && (
          <div className={`${className}__img-cont`}>
            <img src={image} alt="" />
          </div>
        )}
        <div className={`${className}__title`}>{title}</div>
        {description && (
          <div className={`${className}__description`}>{description}</div>
        )}
        {btnLabel && (
          <CustomButton
            className={`${className}__btn`}
            linkTo={linkTo}
            onClick={onClick}
            btnLabel={btnLabel}
          />
        )}
      </div>
    </div>
  );
};

export default InfoCard;
