import React from "react";
import { Card, Divider } from "antd";

export function Testimonial({ testimonial }) {
  return (
    <Card title={testimonial.title} className={"testimonial-card"}>
      <div dangerouslySetInnerHTML={{ __html: testimonial.content }}></div>
      <Divider />
      <a href={testimonial.url}>{testimonial.url}</a>
    </Card>
  );
}
