import React from "react";
import classNames from "classnames";

type ButtonGroupProps = {
  children: React.ReactElement[];
  name?: string | number;
  prefixClassName?: string;
  value?: {
    id: string | number;
    name?: string;
  };
  onChange?: (val) => any;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  onChange,
  prefixClassName,
  value,
}) => {
  const clickHandler = (clickedVal) => {
    const val = clickedVal?.id !== value?.id ? clickedVal : null;
    onChange(val);
  };
  return (
    <div className={classNames("button-group", prefixClassName)}>
      {React.Children.map(children, (child, i) => {
        return React.cloneElement(child, {
          onClick: clickHandler,
          selected: child.props?.value?.id === value?.id,
        });
      })}
    </div>
  );
};

export default ButtonGroup;
