import { useMutation, useQueryCache } from "react-query";
import { postAdminEditMessage } from "../../api";
import { notify } from "../../../../../common/helpers";
import { API_KEY_GET_CHAT_ADMIN } from "../../helpers";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

export const usePostAdminEditMessage = (id) => {
  const queryCache = useQueryCache();
  const successMsg = _t("new_msg_send");

  return useMutation(postAdminEditMessage, {
    onSuccess: (data, variables) => {
      notify("success", successMsg);
      queryCache.invalidateQueries([API_KEY_GET_CHAT_ADMIN, id]);
    },
  });
};
