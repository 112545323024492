import React from "react";
import { ChatPreviewInterface } from "../../helpers";
import { Card } from "antd";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { generatePath } from "react-router-dom";
import { BookMarkButton, ResendMsgButton } from "./components";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const ChatPreview: React.FC<ChatPreviewInterface> = ({
  info,
  isAdminList,
  isNewMessagesList,
}) => {
  const {
    id,
    theme,
    lastMessage_text,
    created_at,
    lastMessage_created_at,
    lastMessage_is_admin,
    lastMessage_read_at,
    marked,
    partner,
  } = info;
  const chatPath = isAdminList
    ? APP_ROUTES.chat.admin.inner
    : APP_ROUTES.chat.inner;
  const chatInnerPath = generatePath(chatPath, { id });
  const isUnreadPossible =
    isAdminList && lastMessage_is_admin === 1 && lastMessage_read_at;

  return (
    <Card
      className={classNames("chat-preview", {
        "chat-preview--unread": isNewMessagesList,
        "chat-preview--admin-msg": isAdminList,
        "chat-preview--resend": isUnreadPossible,
      })}
    >
      {isAdminList && <BookMarkButton marked={marked} id={id} />}
      {isUnreadPossible && <ResendMsgButton id={id} />}
      <Link to={chatInnerPath} className="chat-preview__info">
        <span className="chat-preview__theme">
          {partner?.login && `${partner.login} | `} {theme}
          <span className="chat-preview__created-at msg-date">
            {_t("created_at")}:{" "}
            <span className="msg-date__value">{created_at}</span>
          </span>
        </span>
        <span className="chat-preview__msg">
          <span className="chat-preview__last-msg msg-date">
            <span className="msg-date__value">{lastMessage_created_at}</span>
          </span>
          <span className="chat-preview__msg-content">{lastMessage_text}</span>
        </span>
      </Link>
    </Card>
  );
};

export default ChatPreview;
