import { useQuery } from "react-query";
import { removeEmpty } from "../../../../../modules/Dashboard/apiHooks";
import { getReport } from "../../api";

export const useGetReport = (api, key, params) => {
  params = removeEmpty(params);
  return useQuery([key, params], () =>
    getReport(api, {
      ...params,
    })
  );
};
