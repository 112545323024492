import React from "react";
import { store } from "./system/store";
import { Provider } from "react-redux";
import routes from "./system/routes";
import "antd/dist/antd.css";
import "./styles/main.scss";
import { ReactQueryConfigProvider } from "react-query";
import SystemTemplate from "./modules/Layouts/SystemTemplate";

const queryConfig = { queries: { refetchOnWindowFocus: false } };

const App = () => {
  return (
    <Provider store={store}>
      <ReactQueryConfigProvider config={queryConfig}>
        <SystemTemplate>{routes}</SystemTemplate>
      </ReactQueryConfigProvider>
    </Provider>
  );
};

export default App;
