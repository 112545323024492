import React from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { usePostTranslationImportExcel } from "../../hooks";
import { Spin } from "antd";
import { TranslationImportExcelCard } from "../../components";

const TranslationImportExcel = () => {
  const [importTranslations, { isLoading }] = usePostTranslationImportExcel();

  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading}>
          <TranslationImportExcelCard handleSubmit={importTranslations} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationImportExcel;
