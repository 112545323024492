import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import icon from "../../../../assets/images/outer/commission_1.svg";

const Revshare = () => {
  const tableContent = [
    {
      value_1: "€0 – €10 000",
      value_2: "30%",
    },
    {
      value_1: "€10 001 – €20 000",
      value_2: "35%",
    },
    {
      value_1: "€20 001 – €30 000",
      value_2: "40%",
    },
    {
      value_1: "€30 001 – €50 000",
      value_2: "45%",
    },
    {
      value_1: "€50 001 – unlimited",
      value_2: "50%",
    },
  ];
  const title = _t("revshare_title");
  const CommissionTable = (
    <div className="commission-detail-table">
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th>{_t("commission_detail_table_title_1")}</th>
            <th>{_t("commission_detail_table_title_2")}</th>
          </tr>
        </thead>
        <tbody>
          {tableContent.map((item, idx) => (
            <tr key={idx}>
              <td>{item.value_1}</td>
              <td>{item.value_2}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  return <CommissionPage title={title} icon={icon} table={CommissionTable} />;
};

export default Revshare;
