import { useMutation } from "react-query";
import { postAdminChatOptions, postAdminNewChat } from "../../api";
import { notify } from "../../../../../common/helpers";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

export const usePostAdminChatOptions = () => {
  const msg = _t("new_options_applied");

  return useMutation(postAdminChatOptions, {
    onSuccess: (data, variables) => {
      notify("success", msg);
    },
  });
};
