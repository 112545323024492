import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import UserCreateForm from "../components/UserCreateForm";

const UsersCreate = () => {
  return (
    <MainLayout>
      <PageTemplate title={_t("create_user")}>
        <UserCreateForm />
      </PageTemplate>
    </MainLayout>
  );
};

export default UsersCreate;
