import React from "react";
import { Card, Col, Row } from "antd";
import { Prize, Result, Tournament } from "../constants";
import { ResultsWidget } from "./ResultsWidget";
import { PrizesWidget } from "./PrizesWidget";
const { Meta } = Card;

type TournamentViewProps = {
  tournament: Tournament;
  results: Result[];
  prizes: Prize[];
};

const TournamentsTemplate = ({
  results,
  prizes,
  tournament,
}: TournamentViewProps) => {
  return (
    <Row gutter={[30, 30]}>
      <Col md={24} lg={12}>
        <PrizesWidget prizes={prizes} />
      </Col>
      <Col md={24} lg={12}>
        <ResultsWidget results={results} />
      </Col>
      <Col sm={24}>
        <div
          className={"tournaments-list__post-content-text"}
          dangerouslySetInnerHTML={{ __html: tournament.content }}
        ></div>
      </Col>
    </Row>
  );
};

export function TournamentView({
  tournament,
  results,
  prizes,
}: TournamentViewProps) {
  return (
    <Card
      key={tournament.id}
      className={"tournaments-list__post"}
      cover={
        <img
          className={"tournaments-list__post-img"}
          alt="example"
          src={`${tournament.host}${tournament.image}`}
        />
      }
    >
      <Meta
        className={"tournaments-list__post-content"}
        title={
          <>
            <div className={"tournaments-list__post-date"}>
              {tournament.date_from_parsed} - {tournament.date_to_parsed}
            </div>
            <div>{tournament.title}</div>
          </>
        }
        description={
          <TournamentsTemplate
            results={results}
            prizes={prizes}
            tournament={tournament}
          />
        }
      />
    </Card>
  );
}
