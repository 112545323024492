import React, { useEffect, useMemo } from "react";
import { Table, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import { Report } from "../../api";
import {
  columnOptions,
  isCurrencyField,
  isoCountries,
  pickColumns,
  subtotalColumns,
} from "../../constants";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useFilters } from "../../hooks";
import { useReportForDay } from "../../apiHooks";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { NoData } from "../../../Common/components/NoData";
import { CurrencyLabel } from "../../../Common/components";

export const reportColumns: ColumnType<Report>[] = columnOptions.map(
  (field) => ({
    title: field.label,
    dataIndex: field.value,
  })
);

const labels = (_t) => ({
  reg: {
    label: _t("reg"),
    color: "green",
  },
  new: {
    label: _t("New"),
    color: "blue",
  },
  old: {
    label: _t("Old"),
    color: "geekblue",
  },
  first_dep: {
    label: _t("First deposit"),
    color: "red",
  },
  qualification: {
    label: _t("Qualification"),
    color: "gold",
  },
});

const LabelTag = ({ val }) => {
  if (val === "old" || val === "new") {
    return null;
  }
  const labelsMap = labels(_t);
  return (
    <Tag key={val} color={labelsMap[val]?.color}>
      {labelsMap[val]?.label || val}
    </Tag>
  );
};

const FlagTag = ({ country }) => {
  return (
    <div
      className={classnames("flag", "flag-" + country.toLowerCase())}
      title={isoCountries[country]}
    />
  );
};

const subTableColumns: ColumnType<any>[] = [
  {
    title: "Gamer ID",
    dataIndex: "gamer_id",
    render: (v, r) => {
      const labels = r.labels ? r.labels.split(",") : null;
      return (
        <span>
          <Link to={`/dashboard/profile/${r.project_id}/${v}`}>{v}</Link>{" "}
          {labels ? labels.map((l) => <LabelTag key={l} val={l} />) : null}
          {r.country ? <FlagTag country={r.country} /> : null}
        </span>
      );
    },
  },
  {
    title: "Gamer IP",
    dataIndex: "reg_ip",
  },
  ...pickColumns([
    "rounds",
    "deposits",
    // "withdraws",
    "ng",
    "comppoints",
    "partner_income",
  ]),
];

const RenderExpand = ({ record }) => {
  const { isLoading, data } = useReportForDay({
    date: record.date,
    program_id: record.program_id,
    promo_id: record.promo_id,
    sub_id: record.sub_id,
    sub_id2: record.sub_id2,
    landing_id: record.landing_id,
    tracker_id: record.tracker_id,
  });

  const translatedColumns = useMemo(
    () => subTableColumns.map((c) => ({ ...c, title: _t(c.title) })),
    []
  );

  return (
    <Table
      loading={isLoading}
      columns={translatedColumns}
      dataSource={data?.data}
      pagination={{
        hideOnSinglePage: true,
        position: ["bottomCenter"],
      }}
      size={"small"}
      locale={{ emptyText: <NoData /> }}
    />
  );
};

const usePaginator = () => {
  const [filters, updateFilters] = useFilters();
  const setPage = (page) => updateFilters({ page });

  return [filters.page, setPage] as const;
};

const useSubtotals = (data, translatedColumns) => {
  const subtotalColumnsArray = translatedColumns
    .filter((x) => subtotalColumns.includes(x.dataIndex))
    .map((column) => {
      return column.dataIndex;
    });
  let subTotals = [];
  subtotalColumnsArray.forEach((column) => {
    subTotals[column] = 0;
  });
  data?.forEach((row) => {
    subtotalColumnsArray.forEach((columnName) => {
      subTotals[columnName] += parseInt(row[columnName]);
    });
  });
  return subTotals;
};

const RenderSubtotal = ({ hasExpand, translatedColumns, subTotals }) => {
  return (
    <Table.Summary.Row className={"report-table-subtotals"}>
      {hasExpand && (
        <Table.Summary.Cell index={0}>{_t("total")}</Table.Summary.Cell>
      )}
      {translatedColumns.map((column, key) => {
        return (
          <Table.Summary.Cell index={key + 1} key={key}>
            {key === 0 && !hasExpand ? _t("total") : ""}
            {column.dataIndex in subTotals ? (
              <RenderSubtotalCell
                column={column.dataIndex}
                value={subTotals[column.dataIndex]}
              />
            ) : null}
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  );
};

const RenderSubtotalCell = ({ column, value }) => {
  return isCurrencyField(column) ? (
    <CurrencyLabel value={value} />
  ) : (
    <>{value}</>
  );
};

export function ReportTable({ pageSize, columns, data, isLoading, total }) {
  const [page, setPage] = usePaginator();
  const translatedColumns = useMemo(
    () => columns.map((c) => ({ ...c, title: _t(c.title) })),
    [columns]
  );

  // todo reset paginator on mount
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, []);

  const hasExpand =
    data?.length > 0 && columns.some((c) => c.dataIndex === "date");

  const subTotals = useSubtotals(data, translatedColumns);

  return (
    <Table
      loading={isLoading}
      columns={translatedColumns}
      dataSource={data}
      rowKey={(r, i) => i}
      expandIconColumnIndex={hasExpand ? 0 : undefined}
      expandedRowRender={
        hasExpand ? (record) => <RenderExpand record={record} /> : undefined
      }
      pagination={{
        total: total,
        pageSize: pageSize,
        hideOnSinglePage: true,
        onChange: setPage,
        current: page,
        showSizeChanger: false,
        position: ["bottomCenter"],
      }}
      locale={{ emptyText: <NoData /> }}
      summary={(pageData) => {
        return (
          <RenderSubtotal
            subTotals={subTotals}
            translatedColumns={translatedColumns}
            hasExpand={hasExpand}
          />
        );
      }}
      className={"report-table"}
    />
  );
}
