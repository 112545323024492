import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { useGetTranslation, usePostTranslation } from "../../hooks";
import { Spin } from "antd";
import { TranslationCard } from "../../components";

const TranslationEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetTranslation(id);
  const [
    editTranslation,
    { isLoading: isPostTranslationLoading },
  ] = usePostTranslation(id);
  const { languages } = useSelector((state) => state.locale);
  const fillTranslations = (languages, values) => {
    const translations = {};
    Object.values(languages).map((item: any) => {
      translations[item.code] = {
        locale: values[`translations[${item.code}][locale]`],
        translation: values[`translations[${item.code}][translation]`],
      };
    });
    return translations;
  };
  const handleSubmit = (values) => {
    editTranslation({
      ...values,
      translations: fillTranslations(languages, values),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading || isPostTranslationLoading}>
          {data && (
            <TranslationCard initialValues={data} handleSubmit={handleSubmit} />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationEdit;
