import { Input } from "antd";
import { SelectInput } from "../../../../../../modules/Common/components";

export const createColumnFilter = (
  key,
  placeholder,
  isSpecial?,
  searchField?,
  additionalFilterOptions?: any
): any => {
  if (isSpecial) {
    return {
      searchField,
      component: SelectInput,
      sorter: true,
      options: (optionParams: any) => ({
        value: optionParams.selectedKeys,
        mode: "multiple",
        placeholder: "",
        options: additionalFilterOptions,
        onChange: (value, option) => {
          optionParams.setSelectedKeys(value ?? []);
        },
        onDropdownVisibleChange: (visible) => {
          if (!visible) {
            optionParams.handleSearch(
              optionParams.selectedKeys,
              optionParams.confirm,
              optionParams.dataIndex,
              optionParams.searchProp
            );
          }
        },
      }),
    };
  }
  return {
    searchField: key,
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: placeholder,
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  };
};
