import React from "react";
import { Typography } from "antd";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../Common/components/CustomButton";

interface CommissionPageProps {
  title: string;
  icon: string;
  translatedPoints?: any;
  table?: React.ReactElement;
}
const CommissionPage: React.FC<CommissionPageProps> = ({
  title,
  icon,
  translatedPoints,
  table,
}) => {
  const history = useHistory();
  const listItem = (item, idx) => <li key={idx}>{item}</li>;
  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <div className="commission-page">
        <Typography.Title level={2} className="commission-page__title">
          {title}
        </Typography.Title>
        <div className="commission-page__card">
          <div className="commission-page__icon">
            <img src={icon} alt="" />
          </div>
          {translatedPoints && (
            <ul className="commission-page__list">
              {translatedPoints?.map(listItem)}
            </ul>
          )}
          {table}
          <CustomButton
            onClick={() => history.goBack()}
            btnLabel={_t("Back")}
          />
        </div>
      </div>
    </LandingTemplate>
  );
};

export default CommissionPage;
