import { history } from "../store";
import moment from "moment";
import { dateKey } from "../../modules/Dashboard/constants";
import { DEFAULT_LOCALE, LOCALE_KEY } from "../../modules/Locales/constants";
import { INVITATION_CODE } from "../../modules/Landing/constants";

export const removeItem = (item) => localStorage.removeItem(item);

export const setItem = (item, val) => localStorage.setItem(item, val);

export const getItem = (item) => localStorage.getItem(item);

export const getLocale = () =>
  localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;

export const loginHelper = (data) => {
  const expiresAt = data.expires_in * 1000; //response in minutes
  setItem("token", `${data.token_type} ${data.access_token}`);
  setItem("tokenLifeTime", expiresAt);
  setItem("expiredAt", +moment().format("X") + expiresAt);
  setItem("role", data.roles[0] ?? "user");
  setItem(
    "roles",
    data.roles.length ? JSON.stringify(data.roles) : JSON.stringify(["user"])
  );
  setItem("user_id", data.user_id);
  removeItem(INVITATION_CODE);
};

export const logoutHelper = () => {
  removeItem("role");
  removeItem("roles");
  removeItem("token");
  removeItem("tokenLifeTime");
  removeItem("expiredAt");
  removeItem("user_id");
  logoutAdminHelper();
  removeItem(dateKey);
  history.push("/");
};

export const logoutAdminHelper = () => {
  removeItem("admin_token");
  removeItem("admin_tokenLifeTime");
  removeItem("admin_expiredAt");
  removeItem("admin_role");
  removeItem("admin_roles");
  removeItem("admin_user_id");
};

export const loginByIdHelper = () => {
  setItem("admin_token", getItem("token"));
  setItem("admin_tokenLifeTime", getItem("tokenLifeTime"));
  setItem("admin_expiredAt", getItem("expiredAt"));
  setItem("admin_role", getItem("role"));
  setItem("admin_roles", getItem("roles"));
  setItem("admin_user_id", getItem("user_id"));
};

export const returnAdminHelper = () => {
  setItem("token", getItem("admin_token"));
  setItem("tokenLifeTime", getItem("admin_tokenLifeTime"));
  setItem("expiredAt", getItem("admin_expiredAt"));
  setItem("role", getItem("admin_role"));
  setItem("roles", getItem("admin_roles"));
  setItem("user_id", getItem("admin_user_id"));
  logoutAdminHelper();
};

export const isAuthorized = () => !!getItem("token");
export const getUserId = () => getItem("user_id");
export const getAdminToken = () => getItem("admin_token");
export const getUserRoles = () => JSON.parse(getItem("roles"));
