import { useMutation } from "react-query";
import { getTranslationExportExcel } from "../api";
import { getLocale } from "../../../../../system/helpers/localstorageHelper";

export const useGetTranslationExportExcel = () => {
  const locale = getLocale();
  return useMutation(() =>
    getTranslationExportExcel({
      lang: locale,
    })
  );
};
