import React, { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { Button, Space, Typography } from "antd";
import { EditOutlined, CopyOutlined } from "@ant-design/icons";
import StatusLabel from "../../../../../common/components/StatusLabel/StatusLabel";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const AffiliateLandingPageCard = ({ landingPreviewInfo }) => {
  const { image, id, status, status_label, url, title } = landingPreviewInfo;
  const landingPageProfile = generatePath(
    APP_ROUTES.affiliates_programs.landing_edit,
    {
      id: id,
    }
  );
  return (
    <div className="affiliate-landing-card">
      <div className="affiliate-landing-card__image">
        <img src={image} alt="" />
      </div>
      <div className="affiliate-landing-card__content">
        <div className="affiliate-landing-card__content-wrap">
          <Typography.Title level={5} className="affiliate-landing-card__title">
            {title}
          </Typography.Title>
          <Space className="affiliate-landing-card__labels">
            <StatusLabel status={status} text={status_label} />
          </Space>
          <p className="affiliate-landing-card__url">{url}</p>
        </div>
        <Space className="affiliate-landing-card__controls">
          <Link
            to={landingPageProfile}
            className="ant-btn ant-btn-sm"
            size="small"
          >
            <EditOutlined /> {_t("edit")}
          </Link>
          <Button
            icon={<CopyOutlined />}
            size="small"
            onClick={() => navigator.clipboard.writeText(url)}
          >
            {_t("copy")}
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default AffiliateLandingPageCard;
