import { useMemo, useState } from "react";
import { useFilters } from "./useFilters";
import {
  getItem,
  setItem,
} from "../../../../../system/helpers/localstorageHelper";
import { createColumnOptions } from "../../utils/helpers/tableRendering/createColumnOptions";

export const useSettings = ({
  allColumns,
  hiddenColumns,
  visibleColumnsKey,
  stateSelector,
  dispatchCallback,
}) => {
  const [filters, updateFilters] = useFilters(stateSelector, dispatchCallback);
  const allColumnValues = allColumns.map((column: any) => column.dataIndex);

  const getColumns = (columns) => {
    const columnsSet = new Set(columns);
    const columnsToOmitSet = new Set(
      allColumnValues.filter((name) => !columnsSet.has(name))
    );
    return allColumns.filter(
      (column) => !columnsToOmitSet.has(column.dataIndex)
    );
  };

  const storageVisibleColumns = getItem(visibleColumnsKey);
  const defaultColumns = createColumnOptions(allColumnValues, hiddenColumns)
    .filter((field) => !field.hidden)
    .map((field) => field.value);
  const visibleColumnsArray = storageVisibleColumns
    ? JSON.parse(storageVisibleColumns)
    : defaultColumns;
  const [visibleColumns, setVisibleColumns] = useState(
    getColumns(visibleColumnsArray)
  );

  const settings = useMemo(
    () => ({
      columns: visibleColumnsArray,
      page_size: filters?.page_size,
    }),
    [filters?.page_size]
  );
  const onChange = ({
    columns,
    page_size,
  }: {
    columns: string[];
    page_size: string;
    groupBy;
  }) => {
    setItem(visibleColumnsKey, JSON.stringify(columns));
    setVisibleColumns(getColumns(columns));
    updateFilters({ page_size });
  };

  return {
    onChange,
    visibleColumns,
    settings,
  };
};
