import axiosClient from "../helpers/axiosConfig";
import {
  requestStatuses,
  validateRequest,
  responseNotify,
} from "../../system/helpers/requestHelper";

export default (store) => (next) => (action) => {
  const actReq = action.request;
  if (!actReq) {
    return next(action);
  }
  const statuses = requestStatuses(action.types);
  validateRequest(actReq);

  next({ meta: action.meta, type: statuses.start }); //start request

  return new Promise((resolve, reject) => {
    axiosClient.request(actReq).then(
      (response) => {
        const successAction = {
          meta: action.meta,
          type: statuses.success,
          payload: response.data,
        };
        if (actReq.notifyMessage && actReq.notifyMessage.success) {
          responseNotify("success", actReq.notifyMessage); // responce success message
        }
        resolve(successAction);

        return next(successAction); //success request
      },
      (error) => {
        const errorAction = {
          meta: action.meta,
          type: statuses.error,
          error: error.response,
        };
        if (actReq.notifyMessage && actReq.notifyMessage.error) {
          responseNotify("error", actReq.notifyMessage); // responce error message
        }
        reject(errorAction);

        return next(errorAction); //error request
      }
    );
  });
};
