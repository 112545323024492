import React from "react";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { ReportPageWrapper } from "../../components";
import { ReportPage } from "../../components/ReportPage";
import { ReportFilters } from "./components";
import { config } from "./config";

const GamersFTDReport = () => {
  return (
    <ReportPageWrapper title={_t("gamers_ftd_report")}>
      <ReportPage config={config} ReportFilters={ReportFilters} />
    </ReportPageWrapper>
  );
};

export default GamersFTDReport;
