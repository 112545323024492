import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { FaqCard } from "../../components";
import { usePostFaq } from "../../hooks";
import { Spin } from "antd";
import { fillTranslations } from "../../helpers";

const FaqCreate = () => {
  const [createFaq, { isLoading: isPostFaqLoading }] = usePostFaq();
  const { languages } = useSelector((state) => state.locale);

  const handleSubmit = (values) => {
    createFaq({
      ...values,
      translations: fillTranslations(languages, values),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isPostFaqLoading}>
          <FaqCard handleSubmit={handleSubmit} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FaqCreate;
