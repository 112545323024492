import React, { useEffect, useRef } from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Cascader, Space, Spin, Typography } from "antd";
import {
  useAllowSpecialProgram,
  useDenySpecialProgram,
  useSpecialPrograms,
} from "../../apiHooks";
import { SimpleText } from "../inputs/SimpleText";
import { CloseCircleTwoTone } from "@ant-design/icons";

const { Text } = Typography;

type SpecialsProps = {
  user: any;
};

export const SpecialProgramsWidget = ({ user }: SpecialsProps) => {
  const { data, isLoading, isSuccess } = useSpecialPrograms({
    user_id: user.id,
  });
  const [
    allowSpecial,
    { isSuccess: isSuccessSpecialProject },
  ] = useAllowSpecialProgram();
  const [denySpecial] = useDenySpecialProgram();

  useEffect(() => {
    isSuccessSpecialProject && inputEl.current.setValue([]);
  }, [isSuccessSpecialProject]);

  const addProgram = (value) => {
    if (value.length === 3) {
      allowSpecial({
        user_id: user.id,
        program_id: value[2],
      });
    }
  };
  const denyProgram = (program_id) => {
    denySpecial({
      user_id: user.id,
      program_id: program_id,
    });
  };

  const programsOptions = isSuccess
    ? Object.keys(data.programs).map((id) => ({
        label: data.programs[id],
        value: id,
      }))
    : undefined;
  const programsAvailableOptions = isSuccess
    ? Object.keys(data.programs_available).map((project) => {
        return {
          label: project,
          value: project,
          children: Object.keys(data.programs_available[project]).map(
            (commission) => ({
              label: commission,
              value: commission,
              children: Object.keys(
                data.programs_available[project][commission]
              ).map((program) => ({
                label:
                  data.programs_available[project][commission][program]
                    .full_name,
                value:
                  data.programs_available[project][commission][program]
                    .program_id,
              })),
            })
          ),
        };
      })
    : undefined;

  const inputEl = useRef(null);

  return (
    <Spin spinning={isLoading}>
      <Space style={{ marginBottom: "5px" }}>
        <Text strong>{_t("programs")}</Text>
        <Cascader
          options={programsAvailableOptions}
          onChange={addProgram}
          placeholder={_t("select_special_program")}
          ref={inputEl}
        />
      </Space>
      {programsOptions?.map((program) => {
        return (
          <div key={program.value}>
            <SimpleText>{program.label}</SimpleText>
            <CloseCircleTwoTone
              twoToneColor="red"
              onClick={() => denyProgram(program.value)}
            />
          </div>
        );
      })}
    </Spin>
  );
};
